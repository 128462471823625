import React, { Fragment } from 'react';
import { Error, ErrorContext, ErrorType } from '@mit/hui';
import { Route } from 'react-router-dom';
import AlarmAreaInformation from './AlarmAreaInformation';
import AlarmGatekeepers from './AlarmGatekeepers';
import AlarmAccessManagement from './AlarmAccessManagement';
import AlarmDepartments from './AlarmDepartment';
import AlarmContactInfo from './AlarmContactInfo';

interface AlarmDetailProps
{
    alarmId: string;
}

export default class AlarmDetail extends React.Component<AlarmDetailProps>
{
    render()
    {
        if (!this.props.alarmId)
        return <Error context={ErrorContext.Component} message="Please select an alarm" type={ErrorType.FirstAction} />

        return (<Fragment>           
            <Route path={"/alarms/area-information/:id?"} component={AlarmAreaInformation} />
            <Route path={"/alarms/access-management/:id?"} component={AlarmAccessManagement} />
            <Route path={"/alarms/gatekeepers/:id?"} component={AlarmGatekeepers} />
            <Route path={"/alarms/contact-information/:id?"} component={AlarmContactInfo} />
            <Route path={"/alarms/departments/:id?"} component={AlarmDepartments} />         
        </Fragment>);
    }
}
