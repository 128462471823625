import React from 'react';
import { MITLoggedOut } from '@mit/hui';

export default class Logout extends React.Component
{
    render()
    {
        return <MITLoggedOut />
    }
}
