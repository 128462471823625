import React, { Fragment } from 'react'
import { Error, TemplateTwoColumns, ErrorContext, ErrorType, ContactCard } from '@mit/hui'
import { AdminModel } from '../../../api/models/AdminModel'
import { PersonModel } from '../../../api/models/PersonModel'
import AdminEntityView from './AdminEntityView'
import PeopleController from '../../../api/PeopleController'

interface AdminsViewProps {
  data: AdminModel
}

interface IAdminsViewState {
  admins: AdminPerson[]
  isLoading: boolean
}

export interface AdminPerson {
  person: boolean
  data: PersonModel
}

export default class AdminsView extends React.Component<AdminsViewProps, IAdminsViewState> {
  peopleController: PeopleController

  constructor (props: any) {
    super(props)

    this.peopleController = new PeopleController()

    this.state = {
      admins: [],
      isLoading: true
    }
  }

  componentDidMount () {
    const adminsWithSubLists: AdminPerson[] = []

    this.peopleController.postPeopleProfile(this.props.data.user_ids).then(response => {
      if (response) {
        response.user_profiles.map((itm: PersonModel) => {
          const adminPersonData: AdminPerson = {
            data: itm,
            person: true
          }

          adminsWithSubLists.push(adminPersonData)

          return null
        })

        this.setState({ admins: adminsWithSubLists, isLoading: false })
      } else {
        this.setState({ isLoading: false })
      }
    })
  }

  render () {
    if (this.state.isLoading)
      return (
        <TemplateTwoColumns
          leftAlignment='center'
          leftArea={<ContactCard isLoading={true} name={''} department={''} company='' address={''} telephone={''} email={''} />}
          rightAlignment='center'
          rightArea={<ContactCard isLoading={true} name={''} department={''} company='' address={''} telephone={''} email={''} />}
          padded={false}
          primarySize={6}
          showDivider={false}
          showGutters={false}
        />
      )

    const endingOnOdd = this.state.admins.length % 2

    if (this.state.admins.length === 0) return <Error context={ErrorContext.Component} message={'No Admins data available'} type={ErrorType.NoData} />

    return (
      <Fragment>
        {this.state.admins.map((itm: AdminPerson, index: number) => {
          if (index % 2 === 1) {
            return (
              <TemplateTwoColumns
                leftAlignment='center'
                leftArea={<AdminEntityView person={this.state.admins[index - 1].person} data={this.state.admins[index - 1].data} />}
                rightAlignment='center'
                rightArea={<AdminEntityView person={itm.person} data={itm.data} />}
                padded={false}
                primarySize={6}
                showDivider={false}
                showGutters={false}
              />
            )
          } else if (endingOnOdd && index + 1 === this.state.admins.length) {
            return (
              <TemplateTwoColumns
                leftAlignment='center'
                leftArea={<AdminEntityView person={itm.person} data={itm.data} />}
                rightAlignment='center'
                rightArea={''}
                padded={false}
                primarySize={6}
                showDivider={false}
                showGutters={false}
              />
            )
          }

          return null
        })}
      </Fragment>
    )
  }
}
