import React, { Fragment } from 'react';
import { Alert } from '@mit/hui';

interface IAlertTimerProps
{
    message: string;
}

interface IAlertTimerState
{
    show: boolean;
}

export default class AlertTimer extends React.Component<IAlertTimerProps, IAlertTimerState>
{
    constructor(props: IAlertTimerProps)
    {
        super(props)

        this.state = {
            show: true
        }
    }

    componentDidMount()
    {
        //setTimeout(() =>
        //{
        //    this.setState({ show: false })
        //}, 3000)
    }

    render()
    {
        return <Fragment>
            {
                this.state.show && this.props.message
                &&
                <Alert text={this.props.message} type={"error"} />
            }
        </Fragment>
    }
}