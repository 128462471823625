//App
export const CHANGE_USER = "CHANGE_USER";
export const CHANGE_PICTURE = "CHANGE_PICTURE";
export const CHANGE_ROLES = "CHANGE_ROLES";
export const CHANGE_FEATURES = "CHANGE_FEATURES";

//Clearance
export const SELECT_CLEARANCE = "SELECT_CLEARANCE";
export const SELECT_CARDHOLDER = "SELECT_CARDHOLDER";

//Door
export const SELECT_DOOR = "SELECT_DOOR";

//Notification
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const ADD_NOTIFICATION_COUNT = "ADD_NOTIFICATION_COUNT";
export const ON_CONTACT_SAVE = "ON_CONTACT_SAVE";
export const CLEAR_NOTIFICATION_COUNT = "CLEAR_NOTIFICATION_COUNT";
export const ADD_COMPLETED_NOTIFICATION = "ADD_COMPLETED_NOTIFICATION";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const CLOSE_COMPLETED_NOTIFICATION = "CLOSE_COMPLETED_NOTIFICATION";