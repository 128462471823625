import React, { FC, useState } from 'react'
import { Error, Text, ListItem, Drawer, DrawerType, DrawerLayout, DrawerSize, TextType, ActionList, ErrorContext, ErrorType } from '@mit/hui'
import { DepartmentsModel } from '../../../api/models/DepartmentsModel'
import DepartmentController from '../../../api/DepartmentController'

interface DepartmentViewReadOnlyProps {
  data: DepartmentsModel
}

interface DepartmentClearanceResponse {
  clearances: Clearance[]
}

interface Clearance {
  id: string
  name: string
  can_manage: boolean | null
}

export const DepartmentViewReadOnly: FC<DepartmentViewReadOnlyProps> = props => {
  const [loadingDrawerContents, setLoadingDrawerContents] = useState<boolean>(false)
  const [departmentClearances, setDepartmentClearances] = useState<DepartmentClearanceResponse>()
  const [selectedDepartment, setSelectedDepartment] = useState<string>('')
  const [showDrawer, setShowDrawer] = useState<boolean>(false)

  function buildDepartmentList (data: string[]) {
    if (data.length > 0) {
      let listElement = data.map(item => {
        return <ListItem onClick={() => openDepartmentClearanceList(item)} text={item} />
      })

      return listElement
    }

    return <Error context={ErrorContext.Component} message={'There are no departments assigned to this clearance'} type={ErrorType.NoData} />
  }

  async function openDepartmentClearanceList (departmentId: string) {
    setSelectedDepartment(departmentId)
    setLoadingDrawerContents(true)
    setShowDrawer(true)

    try {
      // Fetch clearances linked with provided department
      const departmentController = new DepartmentController()
      const departmentClearances = await departmentController.getClearances(departmentId)
      setDepartmentClearances(departmentClearances)

      // Disable loading
      setLoadingDrawerContents(false)
    } catch (error) {
      console.error(error)
    }
  }

  function getMappedClearanceItems (clearanceResponse: DepartmentClearanceResponse | undefined) {
    if (clearanceResponse === undefined) {
      return []
    }

    if (!clearanceResponse.clearances) {
      console.log('returning null', !!clearanceResponse.clearances)
      return []
    }

    const mappedResponse = clearanceResponse.clearances.map(item => {
      return {
        text: item.name,
        icon: 'id-card'
      }
    })

    return mappedResponse
  }

  return (
    <>
      {buildDepartmentList(props.data.departments)}
      <Drawer
        contents={
          <div>
            <Text content={selectedDepartment} />
            {loadingDrawerContents ? <ActionList isLoading items={[]} /> : <ActionList selectable={false} items={getMappedClearanceItems(departmentClearances)} />}
          </div>
        }
        onClose={() => setShowDrawer(false)}
        show={showDrawer}
        type={DrawerType.Right}
        layout={DrawerLayout.Clean}
        size={DrawerSize.Small}
        footer={[]}
        header={<Text content='Department Clearances' bold type={TextType.Heading3} />}
      />
    </>
  )
}
