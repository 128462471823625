import StringProvider from '../services/StringProvider'
import BaseController from './BaseController'

class ScheduleController extends BaseController {
  //GET

  getSchedules = async (): Promise<any> => {
    return await this.fetchData(this.baseUrl, '/schedules')
  }

  getScheduleEntities = async (scheduleId: string): Promise<any> => {
    let schedule = new StringProvider().toUrl(scheduleId)
    return await this.fetchData(this.baseUrl, `/schedules/${schedule}/entities`)
  }

  getScheduleDepartments = async (scheduleId: string): Promise<any> => {
    let schedule = new StringProvider().toUrl(scheduleId)
    return await this.fetchData(this.baseUrl, `/schedules/${schedule}/departments`)
  }

  getScheduleRanges = async (scheduleId: string): Promise<any> => {
    let schedule = new StringProvider().toUrl(scheduleId)
    return await this.fetchData(this.baseUrl, `/schedules/${schedule}/schedule`)
  }

  postSchedule = async (scheduleId: string, schedule: any): Promise<any> => {
    let scheduleStringId = new StringProvider().toUrl(scheduleId)
    let body = {
      ...schedule
    }
    return await this.postDataRaw(this.baseUrl, '/schedules/' + scheduleStringId + '/schedule', body)
  }

  postDepartments = async (scheduleId: string, department: string): Promise<any> => {
    let schedule = new StringProvider().toUrl(scheduleId)

    const body = {
      department: department
    }

    return await this.postData(this.baseUrl, '/schedules/' + schedule + '/departments', body)
  }

  deleteDepartment = async (scheduleId: string, department: string): Promise<any> => {
    let schedule = new StringProvider().toUrl(scheduleId)

    const body = {
      department: department
    }

    return await this.deleteData(this.baseUrl, '/schedules/' + schedule + '/departments', body)
  }
}

export default ScheduleController
