import React, { Fragment, FC } from 'react';
import { BuildingModel } from '../../../api/models/BuildingModel';
import { BuildingElevatorEntityView } from './BuildingElevatorEntityView';
import { withComponent } from '../../../common/WithComponent';

interface BuildingsElevatorsViewProps
{
    data: BuildingModel[];
    onClick: any;
    elevatorId: string;
    buildingId: string;
    keyword: string;
    superUser: boolean;
}

export const BuildingsElevatorsView: FC<BuildingsElevatorsViewProps> = (props) =>
{
    const BuildingElevatorEntityViewData = withComponent(BuildingElevatorEntityView);

    return <Fragment>
        {
            props.data.map((buildings: BuildingModel, index: number) =>
            {
                return <Fragment>
                    <BuildingElevatorEntityViewData superUser={props.superUser} key={index} buildingId={props.buildingId} elevatorId={props.elevatorId} data={buildings} onClick={props.onClick} keyword={props.keyword} />
                </Fragment>
            })
        }
    </Fragment>
}