import React, { Fragment } from 'react';
import { ActionList, Text, TextType } from '@mit/hui';
import EventsRecentEvents from './components/EventsRecentEvents';
import { withData } from '../../common/WithData';
import EntitiesController from '../../api/EntitiesController';
import StringProvider from '../../services/StringProvider';

export default class ElevatorsEvents extends React.Component<any>
{
    entitiesController: EntitiesController;
    stringProvider: StringProvider;

    constructor(props: any)
    {
        super(props)

        this.entitiesController = new EntitiesController();
        this.stringProvider = new StringProvider();
    }

    render()
    {
        let elevatorName = this.stringProvider.fromUrl(this.props.match.params.elevator);

        const EventsRecentEventsData = withData(
            EventsRecentEvents,
            "Recent Events",
            async () => await this.entitiesController.getElevatorInfo(elevatorName),
            <ActionList isLoading />
        );

        return <Fragment>
            <Text content="Recent Events" type={TextType.Heading4} icon='' />
            <EventsRecentEventsData />
        </Fragment>

        /**
         * Leaving this in here in case we do need this in future.  Commented 
         * out for now because lint gives unreachable code error as there is 
         * a return above.
         */
        // return <TemplateTwoColumns
        //     padded={true}
        //     showDivider={true}
        //     showGutters={true}
        //     leftArea={
        //         <Fragment>
        //             <Text content="Recent Events" type={TextType.Heading4} icon='' />
        //             <EventsRecentEventsData />
        //         </Fragment>}
        //     rightArea={<EventsSceduledEvents />}
        //     primarySize={6}
        //     leftAlignment={"left"}
        //     rightAlignment={"left"}
        // />
    }
}