import React, { Fragment } from 'react';
import { Text, TextType, Table, TemplateTwoColumns, ContactCard, Spacer } from '@mit/hui';
import PeopleController from '../../api/PeopleController';
import GatekeeperDoorsView from './components/GatekeepersDoorsView';
import { withData } from '../../common/WithData';
import DoorAdminsView from './components/DoorAdminsView';
import StringProvider from '../../services/StringProvider';

export default class DoorGatekeeper extends React.Component<any>
{
    peopleController: PeopleController;
    stringProvider: StringProvider;

    constructor(props: any)
    {
        super(props)

        this.peopleController = new PeopleController();
        this.stringProvider = new StringProvider();
    }

    render()
    {
        let doorId = this.stringProvider.fromUrl(this.props.match.params.door);

        const GatekeeperViewData = withData(
            GatekeeperDoorsView,
            "Gatekeepers",
            async () => await this.peopleController.getGatekeepersByDoorId(doorId),
            <Table type={""} flush={true} columns={4} header={[]} rows={[]} isLoading={true} />
        );

        const AdminsViewData = withData(
            DoorAdminsView,
            "Space Admins",
            async () => await this.peopleController.getAdminsByDoorId(doorId),
            <TemplateTwoColumns
                leftAlignment="center"
                leftArea={<ContactCard isLoading={true} name={""} department={""} company="" address={""} telephone={""} email={""} />}
                rightAlignment="center"
                rightArea={<ContactCard isLoading={true} name={""} department={""} company="" address={""} telephone={""} email={""} />}
                padded={false}
                primarySize={6}
                showDivider={false}
                showGutters={false}
            />
        );

        return (
            <Fragment>
                <Text content="Gatekeepers" type={TextType.Heading4} padded={true} icon='' />
                <GatekeeperViewData />
                <Spacer size={"2"} />
                <Text content="Space Administrators" type={TextType.Heading4} icon='' padded={true} />
                <AdminsViewData />
            </Fragment>)
    }
}