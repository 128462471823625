import React, { Fragment } from 'react';
import { ListItemProps, FilteredList, TextboxType, Error, ErrorContext, ErrorType, Container, ListItemState } from '@mit/hui';
import { PersonModel } from '../../../api/models/PersonModel';

interface FullListMasterViewProps
{
    data: PersonModel[];
    onCardholderChange: any;
}

interface FullListMasterViewState
{
    selectedCardholder: PersonModel | any;
    items: any;
}

class FullListMasterView extends React.Component<FullListMasterViewProps, FullListMasterViewState>
{
    constructor(props: FullListMasterViewProps)
    {
        super(props)

        this.state = {
            selectedCardholder: this.props.data[0],
            items: []
        }
    }

    sortFunction(a: PersonModel, b: PersonModel) {
        //sort by last name
        if(a.last_name && b.last_name) {

            //If last name is the same, sort by name
            if(a.last_name === b.last_name) {
                return (a.first_name > b.first_name? 1: -1)
            }

            return (a.last_name > b.last_name? 1: -1)
        }

        //If user has no lastname sort by MIT id
        if(a.mit_id && b.mit_id) {
            return (a.mit_id > b.mit_id)? 1: -1
        }

        return -1;
    } 

    componentDidMount()
    {
        let items: ListItemProps[] = [];

        let cardholdersFiltered = this.props.data;
        cardholdersFiltered.sort(this.sortFunction);

        this.props.data.map((itm: PersonModel) =>
        {
            if (itm)
            {
                let mappedItem: ListItemProps = {
                    text: this.getText(itm),
                    onClick: () =>
                    {
                        this.setState({ selectedCardholder: itm })
                        this.props.onCardholderChange(itm);
                    },
                    state: this.getText(itm) === this.getText(this.state.selectedCardholder) ? ListItemState.Active : ListItemState.None
                }

                items.push(mappedItem);
            }

            return null;
        })

        this.setState({ items: items })
    }

    emptyData =
        {
            startIcon: 'search',
            startIconAccessibilityText: 'search',
            placeholderText: 'Filter cardholders',
            type: TextboxType.IconStart,
            name: "fullListMaster"
        }

    getText(cardholder: PersonModel)
    {
        if(cardholder.first_name && cardholder.last_name)
            return `${cardholder.last_name}, ${cardholder.first_name}`

        if (cardholder.display_name)
            return cardholder.display_name;

        if (cardholder.kerberos_id)
            return cardholder.kerberos_id;

        if (cardholder.mit_id)
            return cardholder.mit_id;

        return "";
    }

    render()
    {
        return <Fragment>
            {
                this.state.items.length > 0
                    ?
                    <FilteredList name="filteredListMaster" height={30} searchOptions={this.emptyData} compact={true} selectable={true} items={this.state.items} />
                    :
                    <Fragment>
                        <Container
                            alignment={"center"}
                            contents={
                                <Error message={"No Cardholders available"} context={ErrorContext.Component} type={ErrorType.NoData} />
                            }
                        />
                    </Fragment>
            }
        </Fragment>
    }
}

export default FullListMasterView;