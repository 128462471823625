import React, { Fragment } from 'react';
import { ListItem, ActionList } from '@mit/hui';
import { DoorSummaryModel } from '../../../api/models/DoorSummaryModel';

interface EventRecentEventsProps
{
    data: DoorSummaryModel;
}

export default class EventRecentEvents extends React.Component<EventRecentEventsProps>
{
    render()
    {
        if (this.props.data.recent_activities.activities.length === 0)
            return <ActionList isEmpty />

        return <Fragment>
            {
                this.props.data.recent_activities.activities.map((items: any) => <ListItem text={items.text} secondaryText={items.time} />)
            }
        </Fragment>
    }
}