import React, { Fragment } from 'react';
import { Text, TextType, Spacer } from '@mit/hui';
import SpecificRemovals from './SpecificRemovals';
import BulkRemovals from './BulkRemovals';
import PeopleController from '../../../api/PeopleController';
import { withComponent } from '../../../common/WithComponent';
//@ts-ignore
import MediaQuery from 'react-responsive';

interface IAlarmPeopleRemoveProps
{
    alarmId: string;
}

export default class AlarmPeopleRemove extends React.Component<IAlarmPeopleRemoveProps>
{
    peopleController: PeopleController;

    constructor(props: IAlarmPeopleRemoveProps)
    {
        super(props)

        this.peopleController = new PeopleController();
    }

    render()
    {
        const BulkRemovalsData = withComponent(
            BulkRemovals
        );

        const SpecificRemovalsData = withComponent(SpecificRemovals);

        return <Fragment>
            <Text content="Remove people" padded={true} type={TextType.Heading4} icon='user-minus' />
            <Spacer size="2" />
            <SpecificRemovalsData alarmId={this.props.alarmId} />
            <MediaQuery minWidth={577}>
                <Spacer size="3" />
                <BulkRemovalsData alarmId={this.props.alarmId} />
            </MediaQuery>
        </Fragment>
    }
}