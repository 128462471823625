import React, { Fragment } from 'react';
//@ts-ignore
import ReactExport from "react-export-excel";
import { Button, ButtonType } from '@mit/hui';
import PeopleController from '../api/PeopleController';
import { CardHoldersModel } from '../api/models/CardHoldersModel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

interface PersonExcelFileExportProps
{
    fileName: string;
    data: CardHoldersModel;
    sheetName: string;
}

export default class PersonExcelFileExport extends React.Component<PersonExcelFileExportProps>
{
    peopleController: PeopleController;

    constructor(props: any)
    {
        super(props)

        this.peopleController = new PeopleController();
    }

    render()
    {
        let today = new Date();
        let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

        return <Fragment>
            {
                this.props.data && this.props.data.list_members
                &&
                <ExcelFile hideElement={false} filename={this.props.fileName + "_" + date} element={<Button onClick={() => null} type={ButtonType.TextNaked} icon={"download"} text={"Export to Excel"} />}>
                    <ExcelSheet data={this.props.data.list_members} name={this.props.sheetName}>
                        <ExcelColumn label="Kerberos ID" value="kerberos_id" />
                        <ExcelColumn label="MIT ID" value="mit_id" />
                        {/* <ExcelColumn label="Display Name" value="display_name" /> */}
                        <ExcelColumn label="Last Name" value="last_name" />
                        <ExcelColumn label="First Name" value="first_name" />
                        <ExcelColumn label="Email" value="email" />
                        <ExcelColumn label="Affiliation" value="affiliation" />
                        <ExcelColumn label="Department" value="department" />
                        <ExcelColumn label="Phone Number" value="phone_number" />
                        <ExcelColumn label="Office Location" value="office_location" />
                    </ExcelSheet>
                </ExcelFile>
            }
        </Fragment>
    }
}