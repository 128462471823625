import React, { Fragment } from 'react';
import { Error, ErrorContext, ErrorType } from '@mit/hui';
import ClearanceAccessManagement from './ClearanceAccessManagement';
import ClearanceInformation from './ClearanceInformation';
import ClearanceGatekeeper from './ClearanceGatekeeper';
import { Route } from 'react-router-dom';
import ClearanceDepartments from './ClearanceDepartments';
import { connect } from 'react-redux';

interface ClearanceDetailProps
{
    clearanceId: string;
    roles?: string[];
}

class ClearanceDetail extends React.Component<ClearanceDetailProps>
{
    render()
    {
        if (!this.props.clearanceId)
            return <Error context={ErrorContext.Component} message="Please select a clearance" type={ErrorType.FirstAction} />

        return (<Fragment>
            <Route exact path={"/"} component={ClearanceInformation} />
            <Route path={"/clearances/clearance-information/:id?"} component={ClearanceInformation} />
            <Route path={"/clearances/access-management/:id?"} component={ClearanceAccessManagement} />
            <Route path={"/clearances/gatekeepers/:id?"} component={ClearanceGatekeeper} />
            {
                (this.props.roles && (this.props.roles.includes("PSEC SUPER USER") || this.props.roles.includes("PSEC RO SUPER USER")) && <Route path={"/clearances/departments/:id?"} component={ClearanceDepartments} />)
            }
        </Fragment>);
    }
}

const mapStateToProps = (state: any) => ({
    roles: state.app.roles
});

export default connect(mapStateToProps)(ClearanceDetail);