import React, { FC, useState, Fragment, useEffect } from 'react'
import { Text, TextType, ActionList, ListItemProps, ListItem, Spacer, ErrorContext, ErrorType, Error } from '@mit/hui'
import ScheduleController from '../../../api/ScheduleController'
import Alert from '../../clearances/components/AlertComponent'

interface ScheduleRangesProps {
  scheduleId: string
}

interface ListCurrentDaycheduleRangesProps {
  scheduleRanges: any[]
}

interface ListScheduleRangesProps {
  scheduleRanges: any[]
}

interface ScheduleRangesModel {
  currentDay: any[]
  daily: any[]
}

export const ScheduleRanges: FC<ScheduleRangesProps> = props => {
  const scheduleController = new ScheduleController()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [scheduleRanges, setScheduleRanges] = useState<any>([])
  const [apiError, setApiError] = useState<boolean>(false)

  useEffect(() => {
    setApiError(false)
    setIsLoading(true)
    scheduleController
      .getScheduleRanges(props.scheduleId)
      .then(schedules => {
        let scheduleRanges: ScheduleRangesModel = schedules.schedules
        setScheduleRanges(scheduleRanges)
        setApiError(false)
        setIsLoading(false)
      })
      .catch(err => {
        setApiError(true)
        setIsLoading(false)
      })
  }, [props.scheduleId])

  if (apiError) {
    return <Error context={ErrorContext.Component} message='An error occurred while retrieving schedules.' type={ErrorType.Generic} />
  }

  return (
    <Fragment>
      <Text content='Schedule' type={TextType.Heading4} icon='' />
      <Spacer size='2' />
      {isLoading ? (
        <ActionList isLoading items={[]} />
      ) : (
        <Fragment>
          <Alert
            icon='info-circle'
            text={
              <span>
                To confirm if your schedule includes or excludes holidays and special closings, please contact us at{' '}
                <a href='mailto:physicalsecurity@mit.edu'>physicalsecurity@mit.edu</a>.
              </span>
            }
          />
          <ListScheduleRanges scheduleRanges={scheduleRanges} />
        </Fragment>
      )}
      <Spacer size='2' />
      <Text content="Today's Schedule" type={TextType.Heading4} icon='' />
      <Spacer size='2' />
      {isLoading ? <ActionList isLoading items={[]} /> : <ListCurrentDaycheduleRanges scheduleRanges={scheduleRanges.currentDay ? scheduleRanges.currentDay : []} />}
    </Fragment>
  )
}

const ListScheduleRanges: FC<ListScheduleRangesProps> = props => {
  const [formattedRanges, setformattedRanges] = useState<any[]>([])

  function getStringRanges (currentDay: string[], dayBefore: string[], dayAfter: string[]): string {
    let currentDayString = 'current day: '
    let dayBeforeString = ''
    let dayAfterStrning = ''

    if (currentDay.length > 0) {
      let stringArray = currentDay.map((itm: any) => {
        return `${itm.startTime}-${itm.endTime}`
      })

      currentDayString = `${currentDayString} ${stringArray.join(':')}`
    }

    if (dayBefore.length > 0) {
      let stringArray = dayBefore.map((itm: any) => {
        return `${itm.startTime}-${itm.endTime}`
      })

      dayBeforeString = `day before: ${stringArray.join(':')}`
    }

    if (dayAfter.length > 0) {
      let stringArray = dayAfter.map((itm: any) => {
        return `${itm.startTime}-${itm.endTime}`
      })

      dayAfterStrning = `day after: ${stringArray.join(', ')}`
    }

    return `${currentDayString} ${dayBeforeString} ${dayAfterStrning}`
  }

  function getOrdinalByMonthString (ordinal: any): string {
    return `Day ${ordinal.dayofMonth} of ${ordinal.month === 'EveryMonth' ? 'every month' : ordinal.month}`
  }

  function getOrdinalByWeekString (ordinal: any): string {
    let dayOfWeek = ''

    switch (ordinal.dayOfWeek) {
      case 'WeekendDay':
        dayOfWeek = 'weekend day'
        break
      case 'Weekday':
        dayOfWeek = 'weekday'
        break
      case 'Day':
        dayOfWeek = 'day'
        break
      default:
        dayOfWeek = ordinal.dayOfWeek
        break
    }

    return `The ${ordinal.ordinal} ${dayOfWeek} of ${ordinal.month}`
  }

  useEffect(() => {
    let formattedArray = formatListItems(props.scheduleRanges)
    setformattedRanges(formattedArray)
  }, [props.scheduleRanges])

  function formatListItems (schedules: any) {
    let formattedRangesArray: any[] = []
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

    if (schedules.daily !== null && Array.isArray(schedules.daily)) {
      for (const scheduleItem of schedules.daily) {
        let listItemProp: ListItemProps = {
          icon: 'door-open',
          text: `${formatTime(scheduleItem.startTime)} - ${formatTime(scheduleItem.endTime)}`,
          tertiaryText: 'Daily',
          onClick: () => {}
        }

        formattedRangesArray.push(<ListItem {...listItemProp} />)
      }
    }

    if (schedules.weekly !== null) {
      for (const weekDayItem of schedules.weekly) {
        for (const ranges of weekDayItem.ranges) {
          let listItemProp: ListItemProps = {
            icon: 'door-open',
            text: weekDayItem.weekDay,
            secondaryText: `${formatTime(ranges.startTime)} - ${formatTime(ranges.endTime)}`,
            tertiaryText: 'Weekly',
            onClick: () => {}
          }

          formattedRangesArray.push(<ListItem {...listItemProp} />)
        }
      }
    }

    if (schedules.specific !== null) {
      for (const specificItem of schedules.specific) {
        let dateObj = new Date(specificItem.date)
        let dateString = `${monthNames[dateObj.getMonth()]} ${dateObj.getDate()}, ${dateObj.getFullYear()}`

        for (let i = 0; i < specificItem.range.length; i++) {
          const range = specificItem.range[i]

          let listItemProp: ListItemProps = {
            icon: 'door-open',
            text: dateString,
            secondaryText: `${formatTime(range.startTime)} - ${formatTime(range.endTime)}`,
            tertiaryText: 'Specific',
            onClick: () => {}
          }

          formattedRangesArray.push(<ListItem {...listItemProp} />)
        }
      }
    }

    if (schedules.ordinal !== null) {
      for (const ordinalByMonth of schedules.ordinal.byDayOfMonth) {
        let ranges = getStringRanges(ordinalByMonth.currentDayRanges, ordinalByMonth.dayBeforeRanges, ordinalByMonth.dayAfterRanges)
        let ordinalTime = getOrdinalByMonthString(ordinalByMonth)
        let listItemProp: ListItemProps = {
          icon: 'door-open',
          text: ordinalTime,
          secondaryText: 'Ordinal',
          tertiaryText: ranges,
          onClick: () => {}
        }

        formattedRangesArray.push(<ListItem {...listItemProp} />)
      }

      for (const ordinalByWeek of schedules.ordinal.byDayOfWeek) {
        let ranges = getStringRanges(ordinalByWeek.currentDayRanges, ordinalByWeek.dayBeforeRanges, ordinalByWeek.dayAfterRanges)
        let ordinalTime = getOrdinalByWeekString(ordinalByWeek)
        let listItemProp: ListItemProps = {
          icon: 'door-open',
          text: ordinalTime,
          secondaryText: 'Ordinal',
          tertiaryText: ranges,
          onClick: () => {}
        }

        formattedRangesArray.push(<ListItem {...listItemProp} />)
      }
    }

    return formattedRangesArray
  }

  return (
    <Fragment>
      {formattedRanges.length > 0 ? (
        formattedRanges.map(item => {
          return item
        })
      ) : (
        <Error context={ErrorContext.Component} message={'No schedule available'} type={ErrorType.NoData} />
      )}
    </Fragment>
  )
}

const ListCurrentDaycheduleRanges: FC<ListCurrentDaycheduleRangesProps> = props => {
  const [formattedRanges, setformattedRanges] = useState<any[]>([])

  useEffect(() => {
    let formattedRangesArray: any[] = []
    props.scheduleRanges.forEach(item => {
      let listItemProp: ListItemProps = {
        icon: 'door-open',
        text: `${formatTime(item.startTime)} - ${formatTime(item.endTime)}`,
        onClick: () => {}
      }

      formattedRangesArray.push(<ListItem {...listItemProp} />)
    })

    setformattedRanges(formattedRangesArray)
  }, [props.scheduleRanges])

  return (
    <Fragment>
      {formattedRanges.length > 0 ? (
        formattedRanges.map(item => {
          return item
        })
      ) : (
        <Error context={ErrorContext.Component} message={'No schedule available'} type={ErrorType.NoData} />
      )}
    </Fragment>
  )
}

function formatTime (time: string): string {
  let hours = time.split(':')[0]
  let minutes = time.split(':')[1]
  let amPm

  let hoursFormated

  if (hours === '00') {
    amPm = 'am'
    hoursFormated = '12'
    return hoursFormated + ':' + minutes + ' ' + amPm
  }

  if (parseInt(hours) > 12) {
    amPm = 'pm'
    let hourValue = parseInt(hours) - 12
    if (hourValue < 10) {
      hoursFormated = '0' + hourValue.toString()
    } else {
      hoursFormated = hourValue.toString()
    }
  } else {
    amPm = 'am'
    hoursFormated = hours
  }
  return hoursFormated + ':' + minutes + ' ' + amPm
}
