import React, { Fragment } from 'react';
import DoorEvents from './DoorEvents';
import { Route } from 'react-router-dom';
import { Error, ErrorContext, ErrorType } from '@mit/hui';
import DoorGatekeeper from './DoorGatekeeper';

interface ClearanceDetailProps
{
    buildingId: string;
    doorId: string;
    isOnline: boolean;
}

export default class DoorDetail extends React.Component<ClearanceDetailProps>
{
    render()
    {
        if (!this.props.doorId)
            return <Error context={ErrorContext.Component} message="Please select a door" type={ErrorType.FirstAction} />

        return (<Fragment>
            <Route path={"/doors/events/:building?/:door?"}><DoorEvents buildingId={this.props.buildingId} doorId={this.props.doorId} isOnline={this.props.isOnline} /></Route>
            <Route path={"/doors/gatekeepers/:building?/:door?"} component={DoorGatekeeper} />
        </Fragment>);
    }
}