import {
    DropdownItemProps,
    FormField,
    GridContainer,
    LayoutColumn, Text
} from '@mit/hui'
import React, {Fragment} from 'react'
import {connect} from "react-redux";

export enum ScheduleFrequencyEnum {
    Daily = 'Daily',
    Weekly = 'Weekly',
    Specific = 'Specific'
}

type ScheduleFrequencyEnumStrings = keyof typeof ScheduleFrequencyEnum

export interface ScheduleFrequencyProps {
    onScheduleTypeChange: any
    scheduleFrequency: ScheduleFrequencyEnumStrings
    roles?: string
}

interface ScheduleFrequencyState {
    scheduleFrequency: string | null
}

class ScheduleFrequency extends React.Component<ScheduleFrequencyProps, ScheduleFrequencyState> {
    constructor (props: any) {
        super(props)
        this.state = {
            scheduleFrequency: props.scheduleFrequency
        }
    }

    frequencyData: DropdownItemProps[] = [
        { text: ScheduleFrequencyEnum.Daily, icon: '', onClick: () => this.props.onScheduleTypeChange(ScheduleFrequencyEnum.Daily) },
        { text: ScheduleFrequencyEnum.Weekly, icon: '', onClick: () => this.props.onScheduleTypeChange(ScheduleFrequencyEnum.Weekly) },
        { text: ScheduleFrequencyEnum.Specific, icon: '', onClick: () => this.props.onScheduleTypeChange(ScheduleFrequencyEnum.Specific) }
    ]

    render () {
        const isSuperUser = this.props.roles && (this.props.roles.includes('PSEC SUPER USER') || this.props.roles.includes('PSEC RO SUPER USER'))
        let superUserLayout: any
        if (isSuperUser) {
            superUserLayout =
                <LayoutColumn colSize={9}>
                    <FormField label='Frequency' labelDisplay='nextto' id='frequency' value={this.props.scheduleFrequency}
                           editor='dropdown' options={this.frequencyData}/>
                </LayoutColumn>
        } else {
            superUserLayout = <Fragment>
                    <LayoutColumn colSize={3}>
                        <Text content='Frequency' bold />
                    </LayoutColumn>
                    <LayoutColumn colSize={6}>
                        <Text content={this.props.scheduleFrequency} bold />
                    </LayoutColumn>
            </Fragment>
        }

        return <Fragment>
            <GridContainer showGutters>
                {superUserLayout}
            </GridContainer>
        </Fragment>
    }

}

const mapStateToProps = (state: any) => ({
    roles: state.app.roles,
})

export default connect(mapStateToProps)(ScheduleFrequency)