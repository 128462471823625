import React, { Fragment } from 'react';
import { TemplateMasterDetail, Error, ErrorContext, ErrorType, MobileView } from '@mit/hui';
import { CardHoldersModel } from '../../../api/models/CardHoldersModel';
import FullListMasterView from './FullListMasterView';
import FullListDetailView from './FullListDetailView';
import { withComponent } from '../../../common/WithComponent';
import PeopleController from '../../../api/PeopleController';
import { PersonModel } from '../../../api/models/PersonModel';

interface FullListViewProps
{
    data: CardHoldersModel;
    cardholder_id?: string;
}

interface FullListViewState
{
    cardholder: PersonModel;
    view: MobileView;
}

class FullListView extends React.Component<FullListViewProps, FullListViewState>
{
    peopleController: PeopleController;

    constructor(props: any)
    {
        super(props)

        this.peopleController = new PeopleController();

        let cardholder: any = {};

        if (this.props.data && this.props.data.list_members[0])
        {
            cardholder = this.props.data.list_members[0];
        }

        this.state = { cardholder: cardholder, view: MobileView.Master }
    }

    onCardholderChange = (cardholder: PersonModel) => 
    {
        this.setState({ cardholder: cardholder, view: MobileView.Detail });
    }

    render()
    {
        // Rerender component
        const FullListDetailViewData = withComponent(FullListDetailView);

        return <Fragment>
            {
                this.props.data && this.props.data.list_members.length > 0
                    ?
                    <TemplateMasterDetail
                        master={<FullListMasterView onCardholderChange={this.onCardholderChange} data={this.props.data ? this.props.data.list_members : []} />}
                        masterTitle="Cardholder List"
                        detailTitle={""}
                        startState={false}
                        detail={<FullListDetailViewData data={this.state.cardholder} />}
                        navigation={<div></div>}
                        mobileView={this.state.view}
                        onBack={() => this.setState({ view: MobileView.Master })}
                    />
                    :
                    <div className="p-4">
                        <Error context={ErrorContext.Component} message={`There are no cardholders directly assigned to this clearance, but there may be some in a sublist. Go to "View Sub Lists" to see this.`} type={ErrorType.NoData} />
                    </div>
            }
        </Fragment>
    }
}

export default FullListView;