import React, { FC, useState, Fragment } from 'react'
import { TemplateModalSize, Text, TextType } from '@mit/hui'
import { withComponent } from '../../../common/WithComponent'
import Modal from '../../../common/Modal'
import Alert from '../../clearances/components/AlertComponent'
import { MultipleCardholderModal } from '../../clearances/components/MultipleCardholderModal'

interface MultipleGroupsWarningProps {
  cardholderGroups: string[]
  managedCardholder: string
}

export const MultipleGroupsWarning: FC<MultipleGroupsWarningProps> = props => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const PopupData = withComponent(Modal)

  function onLinkClick () {
    setShowModal(true)
  }

  return (
    <Fragment>
      {props.managedCardholder && props.cardholderGroups.length > 1 ? (
        <Fragment>
          <Alert
            text={
              <p>
                This clearance manages the cardholder group: <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>{props.managedCardholder} </span>. There are other cardholder
                groups that is linked to this clearance. To see those groups, click
                <a onClick={onLinkClick} style={{ cursor: 'pointer' }}>
                  {' '}
                  here
                </a>
              </p>
            }
            icon='exclamation-triangle'
          />
          <PopupData
            show={showModal}
            onClose={() => {
              setShowModal(false)
            }}
            containerless={false}
            size={TemplateModalSize.Large}
            body={<MultipleCardholderModal groups={props.cardholderGroups} managedGroup={props.managedCardholder} />}
            footer={''}
            header={<Text content='Cardholder groups' type={TextType.Heading4} icon='' />}
            name={'subListPopup'}
          />
        </Fragment>
      ) : (
        <></>
      )}
    </Fragment>
  )
}
