import * as types from "../constants/ActionTypes";

export interface NotificationsModel {
	notifications: NotificationModel[];
	notificationCount: number;
	completedNotifications: NotificationModel[];
	alarmContact: number;
}

export interface NotificationModel {
	show: boolean;
	id: string;
	message: string;
	title: string;
	subtext: string;
	data: any;
	clearanceId: string;
	operation: string;
}

const initialState: NotificationsModel = {
	notifications: [],
	notificationCount: 0,
	completedNotifications: [],
	alarmContact: 0
};

export default function notification(state = initialState, action: any) {
	switch (action.type) {
		case types.ADD_NOTIFICATION: {
			let update = [];

			for (var i = 0; i < state.notifications.length; i++) {
				update.push(state.notifications[i]);
			}

			update.push(action.payload);

			return { ...state, notifications: update };
		}
		case types.ADD_NOTIFICATION_COUNT: {

			let increaseCount = state.notificationCount + 1;
			return { ...state, notificationCount: increaseCount };
		}
		case types.CLEAR_NOTIFICATION_COUNT: {
			return { ...state, notificationCount: 0 };
		}
		case types.ON_CONTACT_SAVE: {

			let increaseCount = state.alarmContact + 1;
			return { ...state, alarmContact: increaseCount };
		}
		case types.ADD_COMPLETED_NOTIFICATION: {
			let update = [];
			// eslint-disable-next-line
			for (var i = 0; i < state.completedNotifications.length; i++) {
				update.push(state.completedNotifications[i]);
			}

			update.push(action.payload);

			return { ...state, completedNotifications: update };
		}
		case types.CLOSE_NOTIFICATION: {
			let stateItems = [];

			for (var k = 0; k < state.notifications.length; k++) {
				stateItems.push(state.notifications[k]);
			}

			let Itemindex = stateItems.findIndex((itm: NotificationModel) => {
				return itm.id === action.payload;
			});

			stateItems.splice(Itemindex, 1);

			return { ...state, notifications: stateItems };
        }
        case types.CLOSE_COMPLETED_NOTIFICATION: {
			let stateItems = [];
			// eslint-disable-next-line
			for (var k = 0; k < state.completedNotifications.length; k++) {
				stateItems.push(state.completedNotifications[k]);
			}

			let Itemindex = stateItems.findIndex((itm: NotificationModel) => {
				return itm.id === action.payload;
			});

			stateItems.splice(Itemindex, 1);

			return { ...state, completedNotifications: stateItems };
		}
		default:
			return state;
	}
}
