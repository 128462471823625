import { store } from "../ts/redux/store/store";
import AuthProvider from "../services/AuthProvider";
import appconfig from "../app-config";
import { ResponseModal } from "./models/ResponseModel";
export const globalStore = store;

class BaseController {
	readonly baseUrl: string;
	private _abortController: AbortController;
	fetchController: any;

	constructor() {
		this.baseUrl = appconfig.api.hostname + "/" + appconfig.api.paths["physical-security"];
		this._abortController = new AbortController();
		this.fetchController = {};
	}

	//TODO
	//More Generic approach
	async getHeaders(method: string, file?: boolean, fileName?: any, contentType?: string): Promise<Headers> {
		let token = await new AuthProvider().getToken();

		let headers: Headers = new Headers({
			Method: method,
		});

		if (file) {
			let type = "";

			if (fileName.split(".")[1] === "xls") type = "application/vnd.ms-excel";

			if (fileName.split(".")[1] === "xlsx") type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

			if (fileName.split(".")[1] === "csv") type = "text/csv";

			headers.append("Content-Type", type ? type : "binary/octet-stream");
			if (!type) {
				headers.append("Content-Filename", fileName);
				headers.append("Authorization", "Bearer " + token);
			}
		} else {
			headers.append("Content-Type", "application/json");
			headers.append("Authorization", "Bearer " + token);
		}

		return headers;
	}

	//TODO
	//More Generic approach
	async useFetch(method: string, fetchKey: string, url: string, body?: any, file?: any, fileName?: string, contentType?: string): Promise<any> {
		if (fetchKey && this.fetchController[fetchKey]) {
			this.fetchController[fetchKey].abort();
		}

		this.fetchController[fetchKey] = new AbortController();
		let { signal } = this.fetchController[fetchKey];

		let options: any = {
			method: method,
			headers: await this.getHeaders(method, file, fileName, contentType),
			signal,
		};
		if (body) {
			options["body"] = file ? body : JSON.stringify(body);
		}

		let response = await fetch(url, options);

		//clear out controller
		this.fetchController[fetchKey] = null;

		return response;
	}

	abortRequest = () => {
		this._abortController.abort();
	};

	requestHeaderGet = async (): Promise<RequestInit> => {
		this._abortController = new AbortController();
		const signal = this._abortController.signal;

		let token = await new AuthProvider().getToken();
		const options: RequestInit = {
			method: "GET",
			headers: new Headers({
				Authorization: "Bearer " + token,
			}),
			signal: signal,
		};
		return options;
	};

	requestHeaderPost = async (requestBody: any): Promise<RequestInit> => {
		let token = await new AuthProvider().getToken();
		const options: RequestInit = {
			method: "POST",
			headers: new Headers({
				Authorization: "Bearer " + token,
				"Content-Type": "application/json",
			}),
			body: JSON.stringify(requestBody),
		};
		return options;
	};

	requestFileHeaderPost = async (requestBody: any, filetype: string): Promise<RequestInit> => {
		let type = "";

		if (filetype.split(".")[1] === "xls") type = "application/vnd.ms-excel";

		if (filetype.split(".")[1] === "xlsx") type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

		if (filetype.split(".")[1] === "csv") type = "text/csv";

		let token = await new AuthProvider().getToken();
		const options: RequestInit = {
			method: "POST",
			headers: new Headers({
				Authorization: "Bearer " + token,
				"Content-Type": type,
			}),
			body: requestBody,
		};

		return options;
	};

	requestFileHeaderDelete = async (requestBody: any, filetype: string): Promise<RequestInit> => {
		let type = "";

		if (filetype.split(".")[1] === "xls") type = "application/vnd.ms-excel";

		if (filetype.split(".")[1] === "xlsx") type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

		if (filetype.split(".")[1] === "csv") type = "text/csv";

		let token = await new AuthProvider().getToken();
		const options: RequestInit = {
			method: "DELETE",
			headers: new Headers({
				Authorization: "Bearer " + token,
				"Content-Type": type,
			}),
			body: requestBody,
		};

		return options;
	};

	requestHeaderDelete = async (requestBody: any): Promise<RequestInit> => {
		let token = await new AuthProvider().getToken();
		const options: RequestInit = {
			method: "DELETE",
			headers: new Headers({
				Authorization: "Bearer " + token,
				"Content-Type": "application/json",
			}),
			body: JSON.stringify(requestBody),
		};

		return options;
	};

	postData = async (baseUrl: string, url: string, body?: any): Promise<any> => {
		if (baseUrl === "/mock") {
			url = url + ".json";
		}

		url = this.baseUrl + url;

		let queryString = "";

		try {
			const response = await fetch(url + queryString, await this.requestHeaderPost(body));

			if (response.status === 200) {
				const data = await response.json();

				return data;
			} else {
				return null;
			}
		} catch (e) {
			console.log("ERROR: " + e);
		}
	};

	postDataRaw = async (baseUrl: string, url: string, body?: any): Promise<any> => {
		if (baseUrl === "/mock") {
			url = url + ".json";
		}

		url = this.baseUrl + url;

		let queryString = "";

		try {
			const response = await fetch(url + queryString, await this.requestHeaderPost(body));

			return response;
		} catch (e) {
			console.log("ERROR: " + e);
		}
	};

	postFile = async (baseUrl: string, url: string, body: any, filetype: string): Promise<any> => {
		if (baseUrl === "/mock") {
			url = url + ".json";
		}

		url = this.baseUrl + url;

		let queryString = "";

		try {
			const response = await fetch(url + queryString, await this.requestFileHeaderPost(body, filetype));

			if (response.status === 200) {
				const data = await response.json();

				return data;
			} else {
				const data = await response.text();
				return data;
			}
		} catch (e) {
			console.log("ERROR: " + e);
		}
	};

	deleteFile = async (baseUrl: string, url: string, body: any, filetype: string): Promise<any> => {
		if (baseUrl === "/mock") {
			url = url + ".json";
		}

		url = this.baseUrl + url;

		let queryString = "";

		try {
			const response = await fetch(url + queryString, await this.requestFileHeaderDelete(body, filetype));

			if (response.status === 200) {
				const data = await response.json();

				return data;
			} else {
				const data = await response.text();
				return data;
			}
		} catch (e) {
			console.log("ERROR: " + e);
		}
	};

	deleteData = async (baseUrl: string, url: string, body?: any): Promise<any> => {
		if (baseUrl === "/mock") {
			url = url + ".json";
		}

		url = this.baseUrl + url;

		let queryString = "";

		try {
			const response = await fetch(url + queryString, await this.requestHeaderDelete(body));

			if (response.status === 200) {
				const data = await response.json();

				return data;
			} else {
				return null;
			}
		} catch (e) {
			return null;
		}
	};

	deleteDataRaw = async (baseUrl: string, url: string, body?: any): Promise<any> => {
		if (baseUrl === "/mock") {
			url = url + ".json";
		}

		url = this.baseUrl + url;

		let queryString = "";

		try {
			const response = await fetch(url + queryString, await this.requestHeaderDelete(body));

			return response;
		} catch (e) {
			return null;
		}
	};

	fetchData = async (baseUrl: string, url: string, query?: any): Promise<any> => {
		if (baseUrl === "/mock") {
			url = url + ".json";
		}

		url = baseUrl + url;

		let queryString = "";

		if (query) {
			var esc = encodeURIComponent;
			queryString =
				"?" +
				Object.keys(query)
					.map((k) => esc(k) + "=" + esc(query[k]))
					.join("&");
		}

		try {
			const response = await fetch(url + queryString, await this.requestHeaderGet());

			if (response.status === 200) {
				const data = await response.json();

				return data;
			} else {
				return null;
			}
		} catch (e) {
			return null;
		}
	};

	fetchDataAndStatus = async (baseUrl: string, url: string, query?: any): Promise<ResponseModal> => {
		if (baseUrl === "/mock") {
			url = url + ".json";
		}

		url = baseUrl + url;

		let queryString = "";

		if (query) {
			var esc = encodeURIComponent;
			queryString =
				"?" +
				Object.keys(query)
					.map((k) => esc(k) + "=" + esc(query[k]))
					.join("&");
		}

		try {
			const response = await fetch(url + queryString, await this.requestHeaderGet());

			let responseData = null;

			//Get response data
			if(response.status === 200) {
				responseData = await response.json();
			}

			return {
				data: responseData,
				status: response.status
			}
		} catch (e) {
			return {
				data: null,
				status: 500 //Unkown error
			};
		}
	}; 

	fetchWithStatusData = async (baseUrl: string, url: string, query?: any): Promise<any> => {
		if (baseUrl === "/mock") {
			url = url + ".json";
		}

		url = baseUrl + url;

		let queryString = "";

		if (query) {
			var esc = encodeURIComponent;
			queryString =
				"?" +
				Object.keys(query)
					.map((k) => esc(k) + "=" + esc(query[k]))
					.join("&");
		}

		try {
			const response = await fetch(url + queryString, await this.requestHeaderGet());

			if (response.status === 200) {
				const data = await response.json();

				return data;
			} else {
				return response.status;
			}
		} catch (e) {
			return null;
		}
	};

	AbortablefetchData = async (baseUrl: string, url: string, query?: any): Promise<any> => {
		if (baseUrl === "/mock") {
			url = url + ".json";
		}

		url = baseUrl + url;

		let queryString = "";

		if (query) {
			var esc = encodeURIComponent;
			queryString =
				"?" +
				Object.keys(query)
					.map((k) => esc(k) + "=" + esc(query[k]))
					.join("&");
		}

		try {
			const response = await fetch(url + queryString, await this.requestHeaderGet());

			if (response.status === 200) {
				const data = await response.json();

				return data;
			} else {
				return null;
			}
		} catch (e) {
			return e;
		}
	};

	fetchPicture = async (baseUrl: string, url: string, query?: any): Promise<any> => {
		if (baseUrl === "/mock") {
			url = url + ".png";
		}

		url = baseUrl + url;

		let queryString = "";

		if (query) {
			var esc = encodeURIComponent;
			queryString =
				"?" +
				Object.keys(query)
					.map((k) => esc(k) + "=" + esc(query[k]))
					.join("&");
		}

		try {
			const response = await fetch(url + queryString, await this.requestHeaderGet());

			if (response.status === 200) {
				const data = await response.blob();

				return URL.createObjectURL(data);
			} else {
				return null;
			}
		} catch (e) {
			console.log("ERROR: " + e);
		}
	};
}

export default BaseController;
