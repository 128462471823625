type Handler<E> = (event: E) => void;

class EventDispatcher<E> { 
    private handlers: Handler<E>[] = [];
    fire(event: E) { 
        for (let h of this.handlers)
            h(event);
    }
    register(handler: Handler<E>) { 
        this.handlers.push(handler);
    }
}

interface IDidPerformOperation {
    operationData: any;
}

export class NotificationHandler {

    data: any;
    client: WebSocket;
    clearanceId: string;
    operation: string;

    constructor(_data: any, _client: WebSocket, _clearanceId: string, _operation: string) {
        this.data = _data;
        this.client = _client;
        this.clearanceId = _clearanceId;
        this.operation = _operation;
    }

    private didPerformOperation = new EventDispatcher<IDidPerformOperation>();

    private fireDidPerformOperation(event: IDidPerformOperation) { 
        console.log("Custom event fired");
        this.didPerformOperation.fire(event);
    }

    public onOperationPerformed(handler: Handler<IDidPerformOperation>) {
        this.didPerformOperation.register(handler);
    }

    async Start() {

        this.client.onmessage = (message) => {

            console.log("Client message!");
            let messageData = JSON.parse(message.data);

            if (messageData.type === "operation_response") {
                let record = messageData.operation_response;
                let recordData = this.data;

                console.log("client message: record data - ", recordData);

                recordData.map((itm: any) => {
                    if (itm.record_id === record.data.record_id) {
                        itm.success = record.success;
                        itm.message = record.message;
                    }
                });

                this.fireDidPerformOperation({operationData: recordData});
            }
        }
    }
}