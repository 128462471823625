import React, { FC, Fragment } from 'react'
import Alert, { AlertProps } from '../../clearances/components/AlertComponent'

interface SpecialClearanceAlertProps {
  showAlert: boolean
}

export const SpecialClearanceAlert: FC<SpecialClearanceAlertProps & AlertProps> = props => {
  const { showAlert, ...rest } = props

  return <Fragment>{showAlert ? <Alert {...rest} icon='exclamation-triangle' /> : <></>}</Fragment>
}
