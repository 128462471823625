import React, { Fragment } from 'react';
import { Feedback, NavItem, GridContainer, LayoutColumn, TextType, TemplateModalSize, Text, ErrorContext, Error, ErrorType } from '@mit/hui';
import HomeController from '../api/HomeController';
import { connect } from 'react-redux';
import { withComponent } from '../common/WithComponent';
import Modal from '../common/Modal';

interface FeedbackState
{
    showFeedbackResponse: boolean;
    showFeedbackResponseFailed: boolean;
    showFeedbackModal: boolean;
}

class FeedbackPopup extends React.Component<any, FeedbackState>
{
    homeController: HomeController;

    constructor(props: any) 
    {
        super(props);

        this.state = {
            showFeedbackResponse: false,
            showFeedbackResponseFailed: false,
            showFeedbackModal: false
        }

        this.homeController = new HomeController();
    }

    render()
    {
        const FeedbackData = withComponent(Feedback);
        const PopupData = withComponent(Modal);

        return <Fragment>
            <NavItem icon="comments" text="Feedback" iconOnly={true} onClick={() => this.setState({ showFeedbackModal: true })} />
            <FeedbackData
                actionText={"Submitting..."}
                show={this.state.showFeedbackModal}
                onClose={() => this.setState({ showFeedbackModal: false })}
                name="feedbackModal"
                formName={this.props.user.display_name}
                formEmail={this.props.user.email}
                onSubmit={(response: any) =>
                {
                    if (response.valid)
                    {
                        let body = {
                            "template": "physical-security/feedback",
                            "variables": {
                                "message": response.comment,
                                "stars": response.stars.toString()
                            }
                        }

                        this.homeController.feedback(body).then((response: any) =>
                        {
                            this.setState({ showFeedbackModal: false })

                            if (response)
                            {
                                this.setState({ showFeedbackResponse: true })
                            }
                            else
                            {
                                this.setState({ showFeedbackResponseFailed: true })
                            }
                        });
                    }

                    return null;
                }}
            />
            <PopupData
                show={this.state.showFeedbackResponse}
                onClose={() => this.setState({ showFeedbackResponse: false })}
                containerless={false} size={TemplateModalSize.Default}
                body={<Fragment>
                    <GridContainer showGutters>
                        <LayoutColumn colSize={12} alignment={"center"} padded={true}>
                            <Text content={"Your Feedback has been submitted! Thank you!"} bold type={TextType.Heading3} />
                        </LayoutColumn>
                    </GridContainer>
                </Fragment>} footer={""} header={<Text content="Feedback" type={TextType.Heading4} icon="" />} name={"feedbackResponse"} />
            <PopupData
                show={this.state.showFeedbackResponseFailed}
                onCLose={() => this.setState({ showFeedbackResponseFailed: false })}
                containerless={false} size={TemplateModalSize.Default} body={<Error context={ErrorContext.Component} message="Something went wrong" type={ErrorType.Generic} />} footer={""} header={<Text content="Feedback" type={TextType.Heading4} icon="" />} name={"feedbackResponseFailed"} />
        </Fragment>
    }
}

const mapStateToProps = (state: any) => ({
    user: state.app.user
});

export default connect(mapStateToProps)(FeedbackPopup);
