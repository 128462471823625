import React from "react";
import { Error, ErrorContext, ErrorType } from '@mit/hui';
import { LoadingIndicator } from "./LoadingIndicator";

export function withData(WrappedComponent: any, title: string, selectData: any, LoadingChild?: any): any 
{
    interface WithDataState
    {
        data: any;
    }

    return class WithData extends React.Component<any, WithDataState>
    {
        async componentDidMount(): Promise<void> 
        {
            const data = await selectData(this.props);
            this.setState({ data: data });
        }

        render(): React.ReactNode 
        {
            return this.state
                ?
                this.state.data ? <WrappedComponent data={this.state.data} {...this.props} /> : <Error context={ErrorContext.Component} message={"No data available for " + title} type={ErrorType.NoData} />
                :
                (LoadingChild ? LoadingChild : < LoadingIndicator />)
        }
    };
}