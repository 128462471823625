import React, { Fragment } from 'react';
import { Text, TextboxType, Spacer, TextType, AutoComplete, ActionList } from '@mit/hui';
import DepartmentView from './components/DepartmentView';
import { DepartmentViewReadOnly } from './components/DepartmentViewReadOnly';
import ClearanceController from '../../api/ClearanceController';
import { withData } from '../../common/WithData';
import { connect } from 'react-redux';


 class ClearanceDepartments extends React.Component<any>
{
    clearanceController: ClearanceController;

    constructor(props: any)
    {
        super(props)

        this.clearanceController = new ClearanceController();
     }

    render()
    {
        const DepartmentViewData = withData(
            this.props.roles.includes('PSEC RO SUPER USER')? DepartmentViewReadOnly: DepartmentView,
            "Departments",
            async () => await this.clearanceController.getDepartment(this.props.match.params.id),
            <Fragment>
                <Spacer size={"2"} />
                <AutoComplete
                    name="departmentsAC"
                    isLoading items={[]}
                    searchOptions={{
                        startIcon: 'search',
                        startIconAccessibilityText: 'search',
                        placeholderText: 'Loading Departments',
                        type: TextboxType.IconStart,
                        name: "search-department"
                    }} />
                <Spacer size={"2"} />
                <ActionList isLoading={true} />
            </Fragment>
        );

        return <Fragment>
            <Text content= {this.props.roles.includes('PSEC RO SUPER USER')? "Departments" : "Add Department"} type={TextType.Heading4} padded={true} icon='' />
            <DepartmentViewData clearanceId={this.props.match.params.id} />
        </Fragment>
    }
}

const mapStateToProps = (state: any) => ({
	roles: state.app.roles
});

export default connect(mapStateToProps)(ClearanceDepartments);