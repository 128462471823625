import React, { FC, useState, Fragment, useEffect } from 'react'
import { Alert, FormContainer, FormField, GridContainer, LayoutColumn, Spacer, Text, Toggle } from '@mit/hui'
import AlertTimer from '../../../common/AlertTimer'
import ScheduleController from '../../../api/ScheduleController'
import { init2Time, initTime, MultiTimeRange, MultiTimeRanges, validateTimeRanges } from './MultiTimeRanges'
import ScheduleFrequency, {ScheduleFrequencyEnum} from "./ScheduleFrequency";

export interface DailyScheduleObject {
  times: MultiTimeRange[]
  allDay: boolean
  comment: string
  lastUpdatedBy: string
}

export interface DailyScheduleFormProps {
  scheduleId: string
  schedule: DailyScheduleObject
  scheduleString: string
  onScheduleTypeChange: any
}

export const initDailyScheduleObject: DailyScheduleObject = {
  times: [initTime, init2Time],
  allDay: false,
  comment: '',
  lastUpdatedBy: ''
}

const DailyScheduleForm: FC<DailyScheduleFormProps> = props => {
  const MAXLENGTH = 200
  const scheduleController = new ScheduleController()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [fromProcessing, setFormProcessing] = useState<boolean>(false)
  const [comment] = useState<string>(props.schedule.comment)
  const [showLastUpdatedBy, setShowLastUpdatedBy] = useState<boolean>(false)
  const [allDayCheck, setAllDayCheck] = useState<boolean>(props.schedule.allDay)

  const [times, setTimes] = useState<MultiTimeRange[]>(props.schedule.times ?? [initTime, init2Time])

  useEffect(() => {
    if (props.schedule.lastUpdatedBy !== '') {
      setShowLastUpdatedBy(true)
    }
  }, [props.schedule.lastUpdatedBy, times, allDayCheck])

  function submitValidation (valid: boolean) {
    if (!valid) {
      setErrorMessage('Your submission contains errors. Refer to the error messages provided on correcting these issues before resubmitting.')
      return false
    }

    if (comment.length > MAXLENGTH) {
      setErrorMessage(`Please provide a comment that is no more than ${MAXLENGTH} characters long. It is currently ${comment.length} characters`)
      return false
    }

    // no more then 12 time ranges on Daily
    if (times.length > 12) {
      setErrorMessage('Can only select a maximum of 12 time ranges')
      return false
    }

    if (!validateTimeRanges(times, setErrorMessageMulti)) {
      return false
    }

    return true
  }

  function onSubmit (data: any, valid: boolean) {
    setErrorMessage('')
    if (submitValidation(valid)) {
      let dailyTimes: any = []
      if (!allDayCheck) {
        dailyTimes = [
          ...times.map(time => {
            return {
              day: null,
              date: null,
              times: [
                {
                  start_time: time.start.concat(':00'),
                  end_time: time.end === '23:59' ? '24:00:00' : time.end.concat(':00')
                }
              ]
            }
          })
        ]
      } else {
        // all day time
        dailyTimes = [
          {
            day: null,
            date: null,
            times: [
              {
                start_time: '00:00:00',
                end_time: '24:00:00'
              }
            ]
          }
        ]
      }
      setFormProcessing(true)
      const body = {
        schedule_id: props.scheduleId,
        frequency: 'daily',
        comment: data.comment,
        unlock_dates_and_times: dailyTimes
      }

      scheduleController
        .postSchedule(props.scheduleId, body)
        .then(async data => {
          const dataJson = await data.text()
          if (data.status !== 200) {
            setErrorMessage('Update was unsuccessful. \n' + dataJson)
            return
          }

          // Show success message
          setSuccessMessage('Update successful!')

          // Navigate back to ScheduleInformation route after a delay
          setTimeout(() => {
            setSuccessMessage('')
          }, 3000) // Delay for 3 seconds (adjust as needed)
        })
        .catch(error => {
          setErrorMessage('Something went wrong, your update was unsuccessful.')
          console.error('Error Response', error)
        })
        .finally(() => {
          setFormProcessing(false)
          document.querySelector('[id="scrollTo"]')?.scrollIntoView({ behavior: 'smooth' })
        })
    } else {
      document.querySelector('[id="scrollTo"]')?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  function getToggleChoice (state: boolean) {
    if (state === false) {
      return 'Negative'
    } else if (state === true) {
      return 'Positive'
    } else {
      return 'Negative'
    }
  }

  function getReverseToggleChoice (state: any) {
    let choice = state?.SWITCH_STATE?.choice !== undefined ? state?.SWITCH_STATE?.choice : state
    return choice === 'Positive'
  }

  function onAllDayChecked (checked: boolean) {
    setAllDayCheck(checked)
  }

  function setTimeRanges (index: number, timeRanges: MultiTimeRange[]) {
    setTimes(prevDateRanges => {
      return [...timeRanges]
    })
  }

  function setErrorMessageMulti (message: string) {
    if (message !== '') {
      setErrorMessage('There seems to be an issue with the time ranges. Kindly correct them before proceeding with the update.')
    } else {
      setErrorMessage('')
    }
  }

  return (
    <Fragment>
      <div className='card-entity col-xl-12 col-md-10'>
        <div className='card card-entity-container p-4 mb-4'>
          <FormContainer
            action={(e: any, valid) => onSubmit(e, valid)}
            actionDisabled={false}
            formValidationTrigger='onChange'
            actionIsBusy={fromProcessing}
            submitText={'Update'}
            id='DailyForm'>
            {successMessage && <Alert text={successMessage} type={'success'} />}
            <AlertTimer message={errorMessage} />
            <ScheduleFrequency onScheduleTypeChange={props.onScheduleTypeChange} scheduleFrequency={ScheduleFrequencyEnum.Daily} />
            <span className='custom-toggle-container'>
              <Toggle
                name={`allDaySpecific`}
                choice={getToggleChoice(allDayCheck)}
                inverse={false}
                negativeLabel='All day'
                positiveLabel='All day'
                onClick={(choice: any) => onAllDayChecked(getReverseToggleChoice(choice))}
              />
            </span>
            <hr />
            <Spacer size={'2'} />
            <MultiTimeRanges maxTimes={12} disabled={allDayCheck} name={'daily'} times={times} setTimes={setTimeRanges} setErrorMessage={setErrorMessageMulti} />
            <Spacer size={'2'} />
            <hr />
            <GridContainer>
              <LayoutColumn colSize={9}>
                <FormField
                  id='comment'
                  properties={{ maxLength: MAXLENGTH, properties: { disabled: false } }}
                  value={comment ? comment : ''}
                  editor='multilinetextbox'
                  label='Comment'
                  labelDisplay='nextto'
                  required
                />
              </LayoutColumn>
            </GridContainer>
            {showLastUpdatedBy ? (
              <Fragment>
                <hr />
                <GridContainer>
                  <LayoutColumn colSize={3}>
                    <Text content='Last Updated By' bold />
                  </LayoutColumn>
                  <LayoutColumn colSize={6}>
                    <Text content={props.schedule.lastUpdatedBy} bold />
                  </LayoutColumn>
                </GridContainer>
              </Fragment>
            ) : null}
          </FormContainer>
        </div>
      </div>
    </Fragment>
  )
}

export default DailyScheduleForm
