import React, { FC } from "react";
import Loader from "react-loader";

export const LoadingIndicator: FC<any> = () =>
{
    return (<div style={{ height: "100px", padding: "50px" }}>
        {
            <Loader loaded={false} className={"spinner"} position={"relative"} scale={0.5} radius={15} corners={1} color={"#000"} opacity={0.25} rotate={0} direction={1} speed={1} trail={60} />
        }
    </div>)
}