import React, { Fragment } from 'react'
import History from './common/History'
import { render } from 'react-dom'
import Home from './ts/Home'
import { Provider } from 'react-redux'
import { store } from '../src/ts/redux/store/store'
import { Router, Route, Switch } from 'react-router-dom'
import Logout from './ts/Logout'
import { Loader } from '@mit/hui'
import NoAccess from './ts/403'
import './scss/main.scss'
export const globalStore = store

class App extends React.Component {
  render (): React.ReactNode {
    return (
      <Fragment>
        <Provider store={globalStore}>
          <Router history={History}>
            <Switch>
              <Route path='/logout' component={Logout} exact />
              <Route path='/' component={Home} exact />
              <Route path='/403'>
                <NoAccess />
              </Route>
              <Route path='/clearances/clearance-information/:id?' component={Home} exact />
              <Route path='/clearances/access-management/:id?' component={Home} exact />
              <Route path='/clearances/gatekeepers/:id?' component={Home} exact />
              <Route path='/clearances/departments/:id?' component={Home} exact />
              <Route path='/doors/events/:building?/:door?' component={Home} exact />
              <Route path='/doors/gatekeepers/:building?/:door?' component={Home} exact />
              <Route path='/elevators/gatekeepers/:building?/:elevator?' component={Home} exact />
              <Route path='/alarms/area-information/:id?' component={Home} exact />
              <Route path='/alarms/access-management/:id?' component={Home} exact />
              <Route path='/alarms/gatekeepers/:id?' component={Home} exact />
              <Route path='/alarms/contact-information/:id?' component={Home} exact />
              <Route path='/alarms/departments/:id?' component={Home} exact />
              <Route path='/people/clearance-information/:id?' component={Home} exact />
              <Route path='/people/alarms/:id?' component={Home} exact />
              <Route path='/people/general/:id?' component={Home} exact />
              <Route path='/schedules/schedule-information/:id?' component={Home} exact />
              <Route path='/schedules/manage/:id?' component={Home} exact />
              <Route path='/schedules/gatekeepers/:id?' component={Home} exact />
              <Route path='/schedules/departments/:id?' component={Home} exact />
              <Route path='*'>
                <Loader contactEmail={'nist-physec@mit.edu'} pageNotFound={true} name='Access Control Dashboard' />
              </Route>
            </Switch>
          </Router>
        </Provider>
      </Fragment>
    )
  }
}

render(<App />, document.getElementById('root'))
