import { AccordionItem, AccordionItemState, ListItemProps, ListItemState } from '@mit/hui'
import React, { FC, Fragment } from 'react'
import { ElevatorModel, EntityModel } from '../../../api/models/ScheduleEntitiesModel'

export interface EntityDetailsProps {
  doors: EntityModel[]
  elevators: ElevatorModel[]
}

export const EntityDetails: FC<EntityDetailsProps> = props => {
  function textTranslation (text?: string) {
    if (!text) return ''

    switch (text) {
      case 'UnlockSchedule':
        return 'Free Access'
      case 'UnlockExceptionSchedule':
        return 'Controlled Access'
      default:
        return ''
    }
  }

  function buildAccordionDoors (doors: EntityModel[]) {
    let accordianItems: ListItemProps[] = []

    for (let door of doors) {
      accordianItems.push({
        text: door.name,
        icon: 'door-closed',
        tertiaryText: textTranslation(door.scheduleAssociation)
      })
    }

    return accordianItems
  }

  function buildAccordionElevators (elevators: ElevatorModel[]): ListItemProps[] {
    let accordianItems: ListItemProps[] = []

    for (let elevator of elevators) {
      // get elevator floors
      let elevatorFloors: ListItemProps[] = elevator.floors.map(floor => {
        return {
          text: floor.name,
          icon: 'door-closed',
          secondaryText: null,
          state: ListItemState.None,
          tertiaryText: floor.scheduleAssociation
        }
      })

      accordianItems.push({
        text: elevator.name,
        template: () => <AccordionItem icon='sort-circle' selectable={false} title={elevator.name} state={AccordionItemState.Expanded} items={elevatorFloors} />
      })
    }

    return accordianItems
  }

  return (
    <Fragment>
      <AccordionItem selectable={false} title='Doors' state={AccordionItemState.Expanded} items={buildAccordionDoors(props.doors)} />
      <AccordionItem selectable={false} title='Elevators' state={AccordionItemState.Expanded} items={buildAccordionElevators(props.elevators)} />
    </Fragment>
  )
}
