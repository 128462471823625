import React, { Fragment } from "react";
import { Text, TextType, TemplateModalSize, HelpIconProps, Error, ErrorContext, ErrorType, BulkUpload, DrawerSize, BulkUploadField } from "@mit/hui";
import { CardholdersResponseModel } from "../../../api/models/CardholdersResponseModel";
import { withComponent } from "../../../common/WithComponent";
import Modal from "../../../common/Modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as notificationActionCreator from "../../../ts/redux/actions/notification";
import NotificationController from "../../../api/NotificationController";
import UploadController from "../../../api/UploadController";
import AlarmController from "../../../api/AlarmController";
import { uuidv4 } from "../../../ts/redux/actions/notification";

interface BulkRemovalsProps {
	alarmId: string;
	actions?: any;
}

interface BulkRemovalsState {
	items: CardholdersResponseModel;
	isLoading: boolean;
	errorText: string;
	showPopup: boolean;
	showFailedPoppup: boolean;
}

class BulkRemovals extends React.Component<BulkRemovalsProps, BulkRemovalsState> {
	alarmController: AlarmController;
	notificationController: NotificationController;
	uploadController: UploadController;

	constructor(props: BulkRemovalsProps) {
		super(props);

		this.alarmController = new AlarmController();
		this.notificationController = new NotificationController();
		this.uploadController = new UploadController();

		this.state = {
			items: { clearance_id: "", operation: "", job_id: "", results: [] },
			isLoading: false,
			errorText: "",
			showFailedPoppup: false,
			showPopup: false,
		};
	}

	toArrayBuffer = (file: File) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsArrayBuffer(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	isResultSucceeded(str: any) {
		if (str && str.clearance_id) return true;

		return false;
	}

	postBulkData = async (data: any) => {
		this.props.actions.addNotification(uuidv4(), "Bulk Removals", "Your bulk upload is being processed", data, this.props.alarmId, "remove-alarm");
	};

	render() {
		const PopupData = withComponent(Modal);

		const helpProps: HelpIconProps = {
			description: "Upload a .xslx, .xsl or .csv file using the template provided",
			title: "Bulk Removeals",
			embedded: true,
		};

		const fieldData: Record<string, BulkUploadField> = {
			"Kerberos ID": { required: false },
			"MIT ID": { required: false },
		};

		return (
			<Fragment>
				<Text content="Bulk removals" type={TextType.Heading5} icon="" help={helpProps} />
				<BulkUpload showTemplate={false} drawerSize={DrawerSize.Small} formFields={fieldData} onImport={this.postBulkData} />
				{/*<FileUpload isLoading={this.state.isLoading} acceptedMimeTypes={['.csv', '.xls', '.xlsx']} onConfirm={(file) => this.postBulkData(file)} />*/}
				<PopupData
					show={this.state.showPopup}
					onClose={() => this.setState({ showPopup: false })}
					size={TemplateModalSize.Default}
					body={<Text content={"Processing list. We will notify you when this is completed"} />}
					header={<Text content="Cardholder(s) removed" type={TextType.Heading4} icon="" />}
					name={"popupRemove"}
				/>
				<PopupData
					show={this.state.showFailedPoppup}
					onClose={() => this.setState({ showFailedPoppup: false })}
					size={TemplateModalSize.Default}
					body={<Error context={ErrorContext.Component} message={this.state.errorText ? this.state.errorText : "Something went wrong"} type={ErrorType.Generic} />}
					footer=""
					header={<Text content="Cardholder(s) removed" type={TextType.Heading4} icon="" />}
					name={"popupRemoveFailed"}
				/>
			</Fragment>
		);
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	actions: bindActionCreators(notificationActionCreator, dispatch),
});

export default connect(null, mapDispatchToProps)(BulkRemovals);
