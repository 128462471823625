import {
	Drawer,
	DrawerLayout,
	DrawerSize,
	DrawerType,
	NavItem,
	Text,
	TextType,
	Error,
	ListItem,
	TemplateModal,
	TemplateModalAlignment,
	TemplateModalSize,
	Icon,
	Table,
	ErrorContext,
	ErrorType,
	Button,
	ButtonType,
} from "@mit/hui";
import { TableRowProps } from "@mit/hui/build/components/Table/TableRow";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import StringProvider from "../services/StringProvider";
import { NotificationModel } from "./redux/reducers/notification";
//@ts-ignore
import ReactExport from "react-export-excel";
import { bindActionCreators } from "redux";
import * as notificationActionCreator from "../ts/redux/actions/notification";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

interface IReleaseNotesState {
	showReleaseNotes: boolean;
	show: boolean;
	data: any;
}

class SessionNotifications extends React.Component<any, IReleaseNotesState> {
	stringProvider: StringProvider;

	constructor(props: any) {
		super(props);

		this.stringProvider = new StringProvider();

		this.state = {
			showReleaseNotes: false,
			show: false,
			data: [],
		};
	}

	render() {
		const tableItems: TableRowProps[] = [];

		let today = new Date();
		let date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

		this.state.data.length > 0 &&
			this.state.data.map((itm: any) => {
				const data: TableRowProps = {
					items: [
						itm.kerberos_id ? itm.kerberos_id : itm.mit_id,
						itm.success ? (
							<div className="text-center">
								<Icon icon="check" color="green" type="solid" />
							</div>
						) : (
							<div className="text-center">
								<Icon icon="times" color="red" type="solid" />
							</div>
						),
						itm.message,
					],
					state: "",
				};

				tableItems.push(data);

				return null;
			});

		return (
			<Fragment>
				<NavItem
					icon="envelope-open-text"
					text="Bulk Upload Notifications"
					badge={this.props.notificationCount > 0 ? {content: this.props.notificationCount, type: "warning"} : undefined}
					iconOnly={true}
					onClick={() => this.setState({ showReleaseNotes: true })}
				/>
				<Drawer
					show={this.state.showReleaseNotes}
					onClose={() => {this.setState({ showReleaseNotes: false }); this.props.actions.clearNotificationCount()}}
					type={DrawerType.Right}
					size={DrawerSize.Medium}
					layout={DrawerLayout.Hero}
					header={<Text content="Session Notifications" type={TextType.Heading4} icon="envelope-open-text" />}
					contents={
						<Fragment>
							{this.props.notifications.length > 0 && (
								<Fragment>
									{this.props.notifications.map((itm: NotificationModel) => {
										return (
											<ListItem
												icon="bell"
												text={`Bulk upload operation for ${itm.clearanceId}`}
												secondaryText={this.stringProvider.toTimeFormat(itm.message)}
												onClick={() => this.setState({ show: true, data: itm.data })}
											/>
										);
									})}
								</Fragment>
							)}
							{
								this.props.notifications.length === 0 && (
									<Error type={ErrorType.NoData} context={ErrorContext.Component} message="No notifications available" />
								)
							}
						</Fragment>
					}
					footer={[]}
				/>
				<TemplateModal
					bodyAlignment={TemplateModalAlignment.Left}
					show={this.state.show}
					onClose={() => this.setState({ show: false })}
					padded={false}
					size={TemplateModalSize.Large}
					body={
						tableItems.length > 0 ? (
							<Table columns={3} header={["ID", "Status", "Description"]} rows={tableItems} type={""} flush={true} />
						) : (
							<Error context={ErrorContext.Component} message={"Empty request data uploaded"} type={ErrorType.NoData} />
						)
					}
					footer={
						<ExcelFile filename={"Cardholders_" + date} element={<Button type={ButtonType.IconNaked} icon={"download"} text={"export"} />}>
							<ExcelSheet data={this.state.data} name={"Cardholder List"}>
								<ExcelColumn label="Kerberos ID" value={(col: any) => col.kerberos_id} />
								<ExcelColumn label="MIT ID" value={(col: any) => col.mit_id} />
								<ExcelColumn label="Success" value={(col: any) => col.success} />
								<ExcelColumn label="Message" value={(col: any) => col.message} />
							</ExcelSheet>
						</ExcelFile>
					}
					header={<Text content={`Cardholder(s) Result`} type={TextType.Heading4} icon="" />}
					name={"sessionNotifications"}
				/>
			</Fragment>
		);
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	actions: bindActionCreators(notificationActionCreator, dispatch),
});

const mapStateToProps = (state: any) => ({
	notifications: state.notification.completedNotifications,
	notificationCount: state.notification.notificationCount
});

export default connect(mapStateToProps,mapDispatchToProps)(SessionNotifications);
