import React from 'react'
import { NavBar, NavBarTypes, ResponsiveTypes } from '@mit/hui'
import { NavItemState } from '../../enums/NavItemState'
import {connect} from "react-redux";

class SubNavBar extends React.Component<any> {
  render () {
    let defaultTabData = [
      {
        icon: 'id-card',
        iconOnly: false,
        text: 'Clearances',
        to: '/clearances/clearance-information',
        state: window.location.pathname.includes('clearances') ? NavItemState.Active : NavItemState.None
      },
      {
        icon: 'door-open',
        iconOnly: false,
        text: 'Doors',
        state: window.location.pathname.includes('doors') ? NavItemState.Active : NavItemState.None,
        to: '/doors/events'
      },
      {
        icon: 'sort-circle',
        iconOnly: false,
        text: 'Elevators',
        state: window.location.pathname.includes('elevators') ? NavItemState.Active : NavItemState.None,
        to: '/elevators/gatekeepers'
      },
      {
        icon: 'siren',
        iconOnly: false,
        text: 'Alarms',
        state: window.location.pathname.includes('alarms') ? NavItemState.Active : NavItemState.None,
        to: '/alarms/area-information'
      },
      {
        icon: 'users',
        iconOnly: false,
        text: 'People',
        state: window.location.pathname.includes('people') ? NavItemState.Active : NavItemState.None,
        to: '/people/clearance-information'
      }
    ]

    if (this.props.features.includes('schedules')) {
      defaultTabData.push({
        icon: 'clock',
        iconOnly: false,
        text: 'Schedules',
        state: window.location.pathname.includes('schedules') ? NavItemState.Active : NavItemState.None,
        to: '/schedules/schedule-information'
      })
    }

    return <NavBar responsiveType={ResponsiveTypes.CollapseSome} type={NavBarTypes.IconText} tabs={defaultTabData} />
  }
}

const mapStateToProps = (state: any) => ({
  features: state.app.features
});

export default connect(mapStateToProps)(SubNavBar)