import React, { Fragment } from 'react'
import EntitiesController from '../../api/EntitiesController'
import StringProvider from '../../services/StringProvider'
import { withData } from '../../common/WithData'
import { TemplateTwoColumns, MetricGroup, Spacer, Text, TextType, ActionList } from '@mit/hui'
import EventsSummaryEvents from './components/EventsSummaryEvents'
import EventsSceduledEvents from './components/EventsScheduledEvents'
import EventScheduleEventsReadOnly from './components/EventsScheduledEventsReadOnly'
import DoorClearances from './components/DoorClearnaces'
import { connect } from 'react-redux'
import DoorSummary from './components/DoorSummary'

interface IDoorEventsProps {
  isOnline: boolean
  doorId: string
  buildingId: string
  match?: any
  roles: string[]
}

class DoorEvents extends React.Component<IDoorEventsProps> {
  entitiesController: EntitiesController
  stringProvider: StringProvider

  constructor(props: IDoorEventsProps) {
    super(props)

    this.entitiesController = new EntitiesController()
    this.stringProvider = new StringProvider()
  }

  render() {
    let doorId = this.stringProvider.fromUrl(this.props.doorId)

    const EventsSummaryEventsData = withData(
      EventsSummaryEvents,
      'Recent Activities',
      async () => await this.entitiesController.getDoorRecentActivity(doorId),
      <Fragment>
        <Text content='Summary' type={TextType.Heading4} icon='' />
        <Text content={'Summarized overview of past week'} padded={true} type={TextType.Body} />
        {
          <MetricGroup
            items={[
              { label: 'Access Granted', value: 0, isLoading: true },
              { label: 'Access Denied', value: 0, isLoading: true }
            ]}
          />
        }
      </Fragment>
    )

    const DoorClearanceData = withData(
      DoorClearances,
      'clearances',
      async () => this.entitiesController.getDoorClearances(doorId),
      <Fragment>
        <Spacer size={'3'} />
        <ActionList selectable={false} compact={true} items={[]} isLoading />
      </Fragment>
    )

    return (
      <TemplateTwoColumns
        padded={true}
        showDivider={true}
        showGutters={false}
        leftArea={
          <Fragment>
            {/* <EventsSummaryEventsData isOnline={this.props.isOnline} doorId={this.props.doorId} /> */}
            <DoorSummary doorId={this.props.doorId} isOnline={this.props.isOnline} />
            <Spacer size={'3'} />
            <Text content='Clearances' type={TextType.Heading4} icon='' />
            <DoorClearanceData />
          </Fragment>
        }
        rightArea={<Fragment></Fragment>}
        primarySize={6}
        leftAlignment={'left'}
        rightAlignment={'left'}
      />
    )
  }
}

const mapStateToProps = (state: any) => ({
  roles: state.app.roles
})

export default connect(mapStateToProps)(DoorEvents)
