import React, { Fragment } from 'react';
import { TemplateTwoColumns, ActionList, Text, TextType } from '@mit/hui';
import ManageBuildingDetail from './components/ManageBuildingDetail';
import ManageSchedule from './components/ManageSchedule';
import ClearanceController from '../../api/ClearanceController';
import { withData } from './components/WithData';
import AreaController from '../../api/AreaController';


export default class ClearanceInformation extends React.Component<any>
{
    clearanceController: ClearanceController;
    areaController: AreaController;

    constructor(props: any)
    {
        super(props)

        this.clearanceController = new ClearanceController();
    }

    render()
    {
        const ManageBuildingDetailData = withData(
            ManageBuildingDetail,
            "Buildings Detail",
            async () => await this.clearanceController.getAreasWithStatus(this.props.match.params.id),
            <ActionList isLoading={true} />,
            "clearance"
        );

        return <TemplateTwoColumns
            padded={true}
            showDivider={true}
            showGutters={false}
            leftArea={<Fragment>
                <Text content="Building Details" type={TextType.Heading4} icon='' padded={true} />
                <ManageBuildingDetailData />
            </Fragment>}
            rightArea={<ManageSchedule clearanceId={this.props.match.params.id} />}
            primarySize={6}
            leftAlignment={"left"}
            rightAlignment={"left"}
        />
    }
}