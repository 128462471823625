import React, { Fragment } from 'react';
import { Error, ErrorContext, ErrorType } from '@mit/hui';
import { Route } from 'react-router-dom';
import PeopleClearance from './PeopleClearance'

interface UserDetailProps
{
    userId: string;
}

export default class PeopleDetail extends React.Component<UserDetailProps>
{
    render()
    {
        if (!this.props.userId)
        return <Error context={ErrorContext.Component} message="Please select a user" type={ErrorType.FirstAction} />

        return (<Fragment>           
            <Route path={"/people/clearance-information/:id?"} component={PeopleClearance} />    
        </Fragment>);
    }
}
