import React, { Fragment } from 'react'
import {
  AutoComplete,
  Alert,
  ActionList,
  Button,
  ButtonType,
  Drawer,
  DrawerType,
  DrawerSize,
  Dropdown,
  Error,
  ErrorContext,
  ErrorType,
  TemplateTwoColumns,
  Text,
  TextType,
  Spacer,
  ButtonState,
  TextboxType
} from '@mit/hui'
import PeopleController from '../../../api/PeopleController'
import ClearanceController from '../../../api/ClearanceController'
import HomeController from '../../../api/HomeController'
import { UserClearance } from './UserClearance'
import UserClearanceBuildings from '../UserClearanceBuildings'
import UserClearanceSchedule from '../UserClearanceSchedule'
import { MultipleCardholderGroupWarning } from './MultipleCardholderGroupWarning'
import { connect } from 'react-redux'

interface UserClearanceProps {
  user: any
  data: any
  roles: string[]
}

interface UserClearanceState {
  clearanceName: string
  clearanceId: string
  clearances: any
  clearancesData: any
  addClearanceId: string
  isloading: boolean
  show: boolean
  addClearance: boolean
  error: string
  success: string
  isBusy: boolean
}

class UserClearanceDetail extends React.Component<UserClearanceProps, UserClearanceState> {
  peopleController: PeopleController
  clearanceController: ClearanceController
  homeController: HomeController

  constructor (props: UserClearanceProps) {
    super(props)

    this.peopleController = new PeopleController()
    this.homeController = new HomeController()
    this.clearanceController = new ClearanceController()

    this.state = {
      clearanceName: '',
      clearanceId: '',
      clearances: [],
      clearancesData: [],
      isloading: false,
      addClearanceId: '',
      show: false,
      addClearance: false,
      error: '',
      success: '',
      isBusy: false
    }
  }

  async componentDidMount () {
    this.clearanceController.getClearances().then(data => {
      if (data) {
        const newClearances = data.clearances.map(clearance => {
          return {
            icon: '',
            text: clearance.name,
            id: clearance.id
          }
        })

        this.setState({
          clearancesData: newClearances
        })
        this.allowedActions(newClearances, null)
      }
    })

    this.setState({
      clearanceId: '',
      clearances: this.props.data.clearances,
      isloading: false
    })
  }

  allowedActions (clearances: any, userClearance: any) {
    if (clearances) {
      const allowedClearances = clearances.map((item: any) => item.id)
      const assignedClearance = this.state.clearances.map((clearance: any) => {
        for (let i = 0; i < allowedClearances.length; i++) {
          if (allowedClearances[i] === clearance.id) {
            clearance.allowed = true
          }
        }
        return clearance
      })
      // console.log('ON LOAD', {allowedClearances, assignedClearance})

      this.setState({
        clearanceId: '',
        clearances: assignedClearance,
        isloading: false,
        success: ''
      })
    } else {
      const allowedClearances = this.state.clearancesData.map((item: any) => item.id)
      const assignedClearance = userClearance.map((clearance: any) => {
        for (let i = 0; i < allowedClearances.length; i++) {
          if (allowedClearances[i] === clearance.id) {
            clearance.allowed = true
          }
        }
        return clearance
      })
      // console.log('ON UPDATE', {allowedClearances, assignedClearance})

      this.setState({
        clearanceId: '',
        clearances: assignedClearance,
        isloading: false,
        success: ''
      })
    }
  }

  async updateClearances () {
    await this.peopleController.getClearanceByUserId(this.props.user.kerberos_id ? this.props.user.kerberos_id : this.props.user.mit_id).then(response => {
      this.allowedActions(null, response.clearances)
    })
  }

  getClearanceInfo (e: any, value: any) {
    const clearanceId = `gsc-clearance-${value.text}`
    this.setState({
      clearanceName: value.text,
      clearanceId: clearanceId
    })
  }

  handleSearch (query: any) {
    this.setState({
      addClearanceId: query.id
    })
  }

  async removeClearance (clearanceId: string) {
    this.setState({
      isloading: true,
      clearanceName: '',
      clearanceId: ''
    })
    await this.clearanceController.deleteRemovals(clearanceId, this.props.user.kerberos_id ? this.props.user.kerberos_id : this.props.user.mit_id).then(async response => {
      let data = await response.json()

      this.setState({
        success: data.message
      })
      this.updateClearances()
    })
  }

  async saveClearance () {
    this.setState({
      show: false,
      addClearance: true,
      isloading: true
    })
    await this.clearanceController
      .postAdditions(this.state.addClearanceId, this.props.user.kerberos_id ? this.props.user.kerberos_id : this.props.user.mit_id)
      .then(async response => {
        let data = await response.json()
        this.setState({
          show: false,
          addClearance: false,
          addClearanceId: '',
          success: data.message
        })
        this.updateClearances()
      })
      .catch(err => {
        this.setState({
          show: false,
          addClearance: false,
          addClearanceId: '',
          error: 'User could not be added to the list'
        })
      })
  }

  render () {
    const emptyData = {
      startIcon: 'search',
      startIconAccessibilityText: 'search',
      placeholderText: 'Search by Clearance id',
      type: TextboxType.IconStart,
      name: 'addSpecificClearance'
    }

    return (
      <>
        {this.state.error && <Alert text={this.state.error} type={'error'} />}
        {this.state.success && <Alert text={this.state.success} type={'success'} />}
        <TemplateTwoColumns
          padded={true}
          showDivider={true}
          showGutters={false}
          leftArea={
            <Fragment>
              <Text
                content={this.props.user.first_name ? `${this.props.user.first_name} has access to the following clearances:` : 'Access to the following clearances:'}
                type={TextType.Heading5}
                icon=''
                padded={true}
                bold
              />
              <Spacer size='3' />
              {this.state.clearances.length === 0 ? (
                <Error context={ErrorContext.Component} message={'No clearance access.'} type={ErrorType.NoData} />
              ) : this.state.isloading ? (
                <ActionList isLoading={true} />
              ) : (
                <UserClearance
                  readOnly={this.props.roles.includes('PSEC RO SUPER USER')}
                  data={this.state.clearances}
                  onClick={(e: any, v: any) => this.getClearanceInfo(e, v)}
                  onDelete={(v: any) => this.removeClearance(v)}
                />
              )}
              <Spacer size='3' />
              {this.props.roles.includes('PSEC RO SUPER USER') ? (
                <></>
              ) : (
                <Button
                  text='Add another clearance'
                  type={ButtonType.Ghost | ButtonType.TextNaked}
                  onClick={() =>
                    this.setState({
                      show: true,
                      clearanceName: '',
                      clearanceId: ''
                    })
                  }
                />
              )}
            </Fragment>
          }
          rightArea={
            <Fragment>
              <Text content={`${this.state.clearanceName}`} type={TextType.Heading5} icon='' padded={true} bold />
              <Spacer size='3' />
              {this.state.clearanceId && (
                <>
                  <Text content='Clearance Schedule' type={TextType.Body} bold />
                  <UserClearanceSchedule data={this.state.clearanceId} />
                  <hr />
                  <Text content='Building Details' type={TextType.Body} bold />
                  <UserClearanceBuildings data={this.state.clearanceId} />
                </>
              )}
            </Fragment>
          }
          primarySize={6}
          leftAlignment={'left'}
          rightAlignment={'left'}
        />

        <Drawer
          show={this.state.show}
          themedColor='default'
          onClose={() => this.setState({ show: false, addClearanceId: '' })}
          contents={
            <>
              <Text content='Select Clearance' type={TextType.Heading3} />
              <Spacer size='3' />
              <AutoComplete
                name='addSpecificClearance'
                limit={20}
                searchOptions={emptyData}
                items={this.state.clearancesData}
                isBusy={this.state.isBusy}
                onChange={(q: any) => {
                  this.handleSearch(q)
                }}
              />
              <Spacer size='3' />
              {this.state.addClearanceId && (
                <>
                  <MultipleCardholderGroupWarning clearanceId={this.state.addClearanceId} />
                  <Text content='Clearance Info' type={TextType.Heading3} />
                  <Spacer size='1' />
                  <Text content='When you add a cardholder to this clearance, they will have access to the following:' type={TextType.Body} />
                  <Spacer size='1' />
                  <Text content='Doors in Clearance' type={TextType.Heading5} />
                  <UserClearanceBuildings data={this.state.addClearanceId} />
                  <hr />
                  <Text content='Clearance Schedule' type={TextType.Heading5} />
                  <UserClearanceSchedule data={this.state.addClearanceId} />
                  <hr />
                  <Spacer size='4' />
                  <Button
                    text={'Add'}
                    type={ButtonType.Primary}
                    state={this.state.addClearance ? ButtonState.Disabled : ButtonState.Enabled}
                    isBusy={this.state.addClearance}
                    onClick={() => this.saveClearance()}
                  />
                </>
              )}
            </>
          }
          header={<Text content='Add Person to Clearance' type={TextType.Heading4} icon='' />}
          footer={[]}
          type={DrawerType.Right}
          size={DrawerSize.Small}
        />
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  roles: state.app.roles
})

export default connect(mapStateToProps)(UserClearanceDetail)
