import { GatekeepersModel } from './models/GatekeepersModel'
import { AdminModel } from './models/AdminModel'
import { CardHoldersModel } from './models/CardHoldersModel'
import { PersonModel } from './models/PersonModel'
import BaseController from './BaseController'
import { CardholderListModel } from './models/CardholderListModel'
import { ProfilesModel } from './models/ProfilesModel'
import { ResponseModal } from './models/ResponseModel'

class PeopleController extends BaseController {
  getPeople = async (nameOrId: string): Promise<PersonModel[]> => {
    return await this.AbortablefetchData(this.baseUrl, '/people', { name_or_id: nameOrId })
  }

  getSpecificPerson = async (id: string): Promise<PersonModel> => {
    return await this.fetchData(this.baseUrl, '/people/' + id)
  }

  getGatekeepersByClearanceId = async (clearanceId: string): Promise<ResponseModal> => {
    return await this.fetchDataAndStatus(this.baseUrl, '/people/gatekeepers', { clearance_id: clearanceId })
  }

  getGatekeepersByAlarmId = async (alarmId: string): Promise<GatekeepersModel> => {
    return await this.fetchData(this.baseUrl, '/people/gatekeepers', { alarm_id: alarmId })
  }

  getGatekeepersByDoorId = async (doorId: string): Promise<GatekeepersModel> => {
    return await this.fetchData(this.baseUrl, '/people/gatekeepers', { door_id: doorId })
  }

  getGatekeepersByElevatorId = async (elevatorId: string): Promise<GatekeepersModel> => {
    return await this.fetchData(this.baseUrl, '/people/gatekeepers', { elevator_id: elevatorId })
  }

  getGatekeepersByScheduleId = async (scheduleId: string): Promise<ResponseModal> => {
    return await this.fetchDataAndStatus(this.baseUrl, '/people/gatekeepers', { schedule_id: scheduleId })
  }

  getAdminsByClearanceId = async (clearanceId: string): Promise<ResponseModal> => {
    return await this.fetchDataAndStatus(this.baseUrl, '/people/admins', { clearance_id: clearanceId })
  }

  getAdminsByAlarmId = async (alarmId: string): Promise<AdminModel> => {
    return await this.fetchData(this.baseUrl, '/people/admins', { alarm_id: alarmId })
  }

  getAdminsByDoorId = async (doorId: string): Promise<AdminModel> => {
    return await this.fetchData(this.baseUrl, '/people/admins', { door_id: doorId })
  }

  getAdminsByElevatorId = async (elevatorId: string): Promise<AdminModel> => {
    return await this.fetchData(this.baseUrl, '/people/admins', { elevator_id: elevatorId })
  }

  getAdminsByScheduleId = async (scheduleId: string): Promise<ResponseModal> => {
    return await this.fetchDataAndStatus(this.baseUrl, '/people/admins', { schedule_id: scheduleId })
  }

  getCardholdersByClearanceId = async (clearanceId: string): Promise<CardHoldersModel> => {
    return await this.fetchData(this.baseUrl, '/people/cardholders', { clearance_id: clearanceId })
  }

  getCardholdersByAlarmId = async (alarmId: string): Promise<CardHoldersModel> => {
    return await this.fetchData(this.baseUrl, '/people/cardholders', { alarm_id: alarmId })
  }

  getCardholdersByClearanceSearch = async (clearanceId: string, name_or_id: string): Promise<CardholderListModel> => {
    return await this.AbortablefetchData(this.baseUrl, '/people/cardholders', { clearance_id: clearanceId, cardholder_name_or_id: name_or_id })
  }

  getCardholdersByAlarmSearch = async (alarmId: string, name_or_id: string): Promise<CardholderListModel> => {
    return await this.AbortablefetchData(this.baseUrl, '/people/cardholders', { alarm_id: alarmId, cardholder_name_or_id: name_or_id })
  }

  getClearanceByUserId = async (userId: string): Promise<any> => {
    return await this.fetchData(this.baseUrl, `/people/profiles/${userId}/clearances`)
  }

  //POST

  postPeopleProfile = async (ids: string | string[]): Promise<ProfilesModel> => {
    return await this.postData(this.baseUrl, '/people/profiles', { user_ids: ids })
  }
}

export default PeopleController
