import React, { Fragment } from 'react';
import { Error, ListItemState, ButtonType, Text, ButtonProps, ButtonState, ListItem, Spacer, AutoComplete, TextboxProps, TextboxType, ListItemProps, TemplateModalSize, TextType, ButtonBarType, AutoCompleteFilterOn, ButtonBar, Drawer, DrawerType, DrawerLayout, DrawerSize, ActionList, ErrorContext, GridContainer, LayoutColumn, ErrorType, Toggle } from '@mit/hui';
import { DepartmentsModel } from '../../../api/models/DepartmentsModel';
import { Departments } from '../../../common/Default';
import { withComponent } from '../../../common/WithComponent';
import Modal from '../../../common/Modal';
import DepartmentController from '../../../api/DepartmentController';
import AlarmController from '../../../api/AlarmController';
import '../../clearances/css/toggle-overide.css'
import { SpecialAlarmAlert } from './SpecialAlarmAlert';

interface DepartmentViewProps
{
    data: DepartmentsModel;
    alarmId: string;
}

interface DepartmentViewState
{
    isLoading: boolean;
    itemAdded: boolean;
    departments: string[];
    department: string;
    specialHierarchyTag: boolean;
    toggleLoading: boolean;
    showClearanceList: boolean;
    loadingClearances: boolean;
    alarms: any;
    showDepartmentPopup: boolean;
}

export default class DepartmentView extends React.Component<DepartmentViewProps, DepartmentViewState>
{
    alarmController: AlarmController;
    departmentController: DepartmentController;

    constructor(props: any)
    {
        super(props)

        this.alarmController = new AlarmController();
        this.departmentController = new DepartmentController();

        this.state = {
            isLoading: true,
            itemAdded: false,
            departments: [],
            department: "",
            specialHierarchyTag: false,
            toggleLoading: false,
            showClearanceList: false,
            loadingClearances: false,
            alarms: null,
            showDepartmentPopup: false
        }
    }

    componentDidMount()
    {
        this.setState({ 
            departments: this.props.data.departments,
            specialHierarchyTag: this.props.data.specialHierarchyTag
        })
    }

    getDepartmentClearances(departmentId: string)
    {
        this.setState({ loadingClearances: true, showClearanceList: true, department: departmentId })

        this.departmentController.getAlarms(departmentId).then((response: any) =>
        {
            this.setState({ alarms: response, loadingClearances: false })
        })
    }

    async onChange(department: string)
    {
        //this.setState({ isLoading: true })

        const departmentsList = this.state.departments;

        departmentsList.unshift(department);

        this.alarmController.postDepartments(this.props.alarmId, department);

        this.setState({ departments: departmentsList, isLoading: false, itemAdded: true })
    }

    arrayRemove(arr: string[], value: string) { return arr.filter(function (ele) { return ele !== value; }); }

    onRemove(department: string)
    {
        const departmentsList = this.state.departments;

        const newDepartments = this.arrayRemove(departmentsList, department);

        this.alarmController.deleteDepartment(this.props.alarmId, department);

        this.setState({ departments: newDepartments, isLoading: false, itemAdded: false, showDepartmentPopup: false })
    }

    extractQualifier(departmentName: string) {

        let qualifier;
        if(departmentName.includes(";")) {
            //Is Special case
            qualifier = departmentName.split(';')[0];
            qualifier = qualifier.replace(/ /g,'');
        } else {
            qualifier = departmentName.split('-')[0];
            qualifier = qualifier.replace(/ /g,'');
        }
        return qualifier;
    }

    extractDepartmentDescription (departmentName: string) {

        let isSpecialCase = departmentName.includes(';');
        let departmentDesc = "";

        if(isSpecialCase) {
            departmentDesc = departmentName.split(';')[1];
            //Remove dash from the description
            if(departmentDesc.trim().substring(0,1) === '-') {
                //Remove first character, which will be a dash
                departmentDesc = departmentDesc.trim().substring(1);
            }
        } else {
            departmentDesc = departmentName.split('-')[1];
        }

        return departmentDesc;
    }

    getToggleChoice (state: boolean) {
        if(state === false) {
            return "Negative"
        }else if(state === true) {
            return "Positive"
        } else {
            return "Negative"
        }
    }

    onDepartmentToggle (data: any) {

        let switchState: "Positive"|"Negative" = data.SWITCH_STATE.choice;
        let booleanChoice = switchState === "Positive"? true : false;

        //Disable toggle while operation in progress
        this.setState({
            toggleLoading: true
        });

        this.departmentController.updateAlarmSpecialHierachyTag(this.props.alarmId, booleanChoice).then(data => {
            this.setState({
                toggleLoading: false,
                specialHierarchyTag: booleanChoice
            });
        }).catch(err => {
            console.log("e: ", err);
            this.setState({
                toggleLoading: false
            }); 
        })
    }

    render()
    {
        const SearchBar = withComponent(AutoComplete);
        const PopupData = withComponent(Modal);

        const emptySearch: TextboxProps =
        {
            startIcon: 'search',
            startIconAccessibilityText: 'search',
            placeholderText: 'Choose departments',
            type: TextboxType.IconStart,
            name: "search-department"
        }

        let defaultListData: ListItemProps[] = [];

        Departments.map((itm: string) =>
        {
            defaultListData.push({
                icon: "building", text: this.extractDepartmentDescription(itm), tertiaryText: this.extractQualifier(itm), onClick: () =>
                {
                    this.state.departments.includes(this.extractQualifier(itm)) ? this.setState({ isLoading: false }) : this.onChange(this.extractQualifier(itm))
                },
                state: this.state.departments.includes(this.extractQualifier(itm)) ? ListItemState.Disabled : ListItemState.None
            })

            return null;
        })

        const confirmButtons: ButtonProps[] = [
            {
                text: "Confirm",
                icon: "trash",
                type: ButtonType.Primary,
                onClick: () => this.onRemove(this.state.department),
                state: ButtonState.Enabled
            },
            {
                text: "Cancel",
                icon: "times",
                type: ButtonType.Ghost | ButtonType.Secondary,
                onClick: () =>
                {
                    this.setState({ showDepartmentPopup: false })
                },
                state: ButtonState.Enabled
            }
        ]

        return <Fragment>
            <span className = "custom-toggle-container">
                <Toggle 
                    disabled = {this.state.toggleLoading} 
                    choice = {this.getToggleChoice(this.state.specialHierarchyTag)} 
                    inverse = {true} 
                    negativeLabel = 'Mark as special alarm' 
                    positiveLabel = 'Mark as special alarm'
                    onClick = {(choice:any) => this.onDepartmentToggle(choice)}
                />
            </span>
            <Spacer size={"2"} />
            <SpecialAlarmAlert showAlert = {this.state.specialHierarchyTag}  text="Do not assign departments, this is a special hierarchy alarm" />
            <Spacer size={"2"} />
            <SearchBar additionalFilterOn={AutoCompleteFilterOn.tertiaryText} name="departmentAC" items={defaultListData} searchOptions={emptySearch} />
            <Spacer size={"2"} />
            <Fragment>
                {
                    this.state.departments.map((department: string, index: number) =>
                    {
                        const defaultButtonData: ButtonProps[] = [
                            {
                                icon: 'trash', text: '', type: ButtonType.TextNaked,
                                onClick: () =>
                                {
                                    this.setState({ department: department, showDepartmentPopup: true });
                                },
                                state: ButtonState.Enabled
                            },
                        ];

                        return <ListItem onClick={() => { this.getDepartmentClearances(department) }} text={department} actions={defaultButtonData} state={this.state.itemAdded && index === 0 ? ListItemState.RecentlyAdded : ListItemState.None} />
                    })
                }
            </Fragment>
            <Drawer
                contents={<div>
                    <Text content={this.state.department} />
                    {
                        this.state.loadingClearances
                            ?
                            <ActionList isLoading items={[]} />
                            :
                            <ActionList selectable={false} items={this.state.alarms && this.state.alarms.alarms.map((itm: any) => { return { text: itm.name, icon: "id-card" } })} />
                    }
                </div>} onClose={() => this.setState({ showClearanceList: false })} show={this.state.showClearanceList} type={DrawerType.Right} layout={DrawerLayout.Clean} size={DrawerSize.Small} footer={[]} header={<Text content="Department Clearances" bold type={TextType.Heading3} />} />
            <PopupData
                show={this.state.showDepartmentPopup}
                onClose={() => this.setState({ showDepartmentPopup: false })}
                containerless={false} size={TemplateModalSize.Default}
                body={<div>
                    <Error context={ErrorContext.Component} message={`Are you sure you want to remove ${this.state.department} from ${this.props.alarmId}?`} type={ErrorType.Generic} />
                    <GridContainer>
                        <LayoutColumn alignment={"center"} colSize={12}>
                            <ButtonBar buttons={confirmButtons} type={ButtonBarType.Padded} />
                        </LayoutColumn>
                    </GridContainer>
                </div>}
                footer={""} header={<Text content="Remove Department" type={TextType.Heading4} icon="" />} name={"departmentPopup"} />
        </Fragment>
    }
}