import React from 'react'
import { TemplateMasterDetail, NavItemProps, NavBar, NavBarTypes, ResponsiveTypes, MobileView } from '@mit/hui'
import { connect } from 'react-redux'
import ScheduleMaster from './ScheduleMaster'
import ScheduleDetail from './ScheduleDetail'
import { NavItemState } from '../../enums/NavItemState'
import { withComponent } from '../../common/WithComponent'
import History from '../../common/History'

interface SchedulesState {
  view: number
  selectedSchedule: string
}

interface SchedulesProps {
  clearance?: string
  match: any
  roles?: string[]
}

class Schedules extends React.Component<SchedulesProps, SchedulesState> {
  constructor (props: any) {
    super(props)
    this.state = {
      view: 1,
      selectedSchedule: ''
    }
  }

  componentDidMount () {
    this.setState({ view: this.props.match.params.id ? MobileView.Detail : MobileView.Master })
  }

  onItemClick (id: string, name: string) {
    this.setState({ view: 2, selectedSchedule: name })
  }

  render () {
    const isSuperUser = this.props.roles && (this.props.roles.includes('PSEC SUPER USER') || this.props.roles.includes('PSEC RO SUPER USER'))
    if (window.location.pathname.includes('departments') && this.props.roles && !isSuperUser) History.push('/schedules/clearance-information')

    let scheduleId = ''
    if (this.props.match.params.id) scheduleId = '/' + this.props.match.params.id

    let scheduleTabData: NavItemProps[] = [
      {
        icon: '',
        iconOnly: false,
        text: 'Schedule Info',
        state: window.location.pathname.includes('schedule-information') ? NavItemState.Active : NavItemState.None,
        to: '/schedules/schedule-information' + scheduleId
      },
      {
        icon: '',
        iconOnly: false,
        text: 'Manage',
        state: window.location.pathname.includes('manage') ? NavItemState.Active : NavItemState.None,
        to: '/schedules/manage' + scheduleId
      },
      {
        icon: '',
        iconOnly: false,
        text: 'Gatekeepers',
        state: window.location.pathname.includes('gatekeepers') ? NavItemState.Active : NavItemState.None,
        to: '/schedules/gatekeepers' + scheduleId
      }
    ]

    if (isSuperUser)
      scheduleTabData.push({
        icon: '',
        iconOnly: false,
        text: 'Departments',
        state: window.location.pathname.includes('departments') ? NavItemState.Active : NavItemState.None,
        to: '/schedules/departments' + scheduleId
      })

    const NavBarData = withComponent(NavBar)

    return (
      <TemplateMasterDetail
        mobileView={this.state.view}
        startState={true}
        onBack={() => this.setState({ view: 1 })}
        master={<ScheduleMaster clearanceId={this.props.match.params.id} onclick={(id: string, name: string) => this.onItemClick(id, name)} />}
        masterTitle='My Schedules'
        navigation={scheduleId ? <NavBarData responsiveType={ResponsiveTypes.CollapseFit} type={NavBarTypes.Tabs} tabs={scheduleTabData} /> : <div></div>}
        detail={<ScheduleDetail scheduleId={this.props.match.params.id} />}
        detailTitle={this.state.selectedSchedule}
      />
    )
  }
}

const mapStateToProps = (state: any) => ({
  roles: state.app.roles
})

export default connect(mapStateToProps)(Schedules)
