import React, { Fragment } from 'react';
import { Text, MetricGroup, Spacer, MetricCardProps, TextType, ButtonType, Error, TemplateModalSize, Table, ErrorContext, ErrorType, ButtonState, ButtonBar, ButtonBarType, ButtonProps } from '@mit/hui';
import { RecentActivitiesModel } from '../../../api/models/DoorSummaryModel';
import Modal from '../../../common/Modal';
import { withComponent } from '../../../common/WithComponent';
import StringProvider from '../../../services/StringProvider';

interface SummaryViewReadOnlyProps
{
    doorId: string,
    isOnline: boolean;
    data: RecentActivitiesModel
}

interface SummaryViewReadOnlyState
{
    isUnlocking: boolean;
    showUnlockedModal: boolean;
    showViewEventsModal: boolean;
}

export default class SummaryViewReadOnly extends React.Component<SummaryViewReadOnlyProps, SummaryViewReadOnlyState>
{
    stringProvider: StringProvider;

    constructor(props: SummaryViewReadOnlyProps)
    {
        super(props)

        this.stringProvider = new StringProvider();

        this.state = {
            isUnlocking: false,
            showUnlockedModal: false,
            showViewEventsModal: false
        }
    }

    viewEvents()
    {
        this.setState({ showViewEventsModal: true })
    }

    render()
    {
        const PopupData = withComponent(Modal);

        let metrics: MetricCardProps[] = [
            { label: "Access Granted", value: (this.props.data && this.props.data.summary && this.props.data.summary.access_granted) ? this.props.data.summary.access_granted : 0 },
            { label: "Access Denied", value: (this.props.data && this.props.data.summary && this.props.data.summary.access_denied) ? this.props.data.summary.access_denied : 0 }
        ];

        let tableData = this.props.data.activities.map((itm: any) =>
        {
            return {
                state: "",
                items: [this.stringProvider.toLongDateWithTime(itm.time_stamp), itm.event_type]
            }
        })

        const buttonItems: ButtonProps[] = [{
            icon: "",
            onClick: () => this.viewEvents(),
            text: "View All Actions",
            state: ButtonState.Enabled,
            type: ButtonType.Ghost | ButtonType.Secondary
        }]

        return <Fragment>
            <MetricGroup items={metrics} />
            <Spacer size="2" />
            <ButtonBar buttons={buttonItems} alignment={"left"} type={ButtonBarType.Padded} />
            <PopupData
                show={this.state.showUnlockedModal}
                onClose={() => this.setState({ showUnlockedModal: false })}
                size={TemplateModalSize.Default} body={<Text content="Unlocked Successfully" bold type={TextType.Body} />} footer="" header={<Text content="Momentarily Unlocked" type={TextType.Heading4} icon="" />} name={"unlockedModal"} />
            <PopupData
                show={this.state.showViewEventsModal}
                onClose={() => this.setState({ showViewEventsModal: false })}
                padded={false} size={TemplateModalSize.Large} body={this.props.data.activities.length > 0 ? <Table flush type={""} rows={tableData} columns={2} header={["Time", "Status"]} /> : <Error context={ErrorContext.Component} type={ErrorType.NoData} message={"No Activities Available"} />} footer="" header={<Text content="Door Actions" type={TextType.Heading4} icon="" />} name={"viewEventsModal"} />
        </Fragment>
    }
} 