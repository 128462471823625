import * as types from "../constants/ActionTypes";

const initialState =
{
    clearance: "",
    cardholder_id: ""
};

export default function app(state = initialState, action: any) 
{
    switch (action.type) 
    {
        case types.SELECT_CLEARANCE:
            {
                return { ...state, clearance: action.payload };
            }
        case types.SELECT_CARDHOLDER:
            {
                return { ...state, cardholder_id: action.payload }
            }
        default:
            return state;
    }
}