import React from 'react';
import { TemplateMasterDetail, NavItemProps, NavBarTypes, NavBar, ResponsiveTypes, MobileView } from "@mit/hui";
import ElevatorMaster from './ElevatorsMaster';
import ElevatorsDetails from './ElevatorsDetails';
import { NavItemState } from '../../enums/NavItemState';
import StringProvider from '../../services/StringProvider';
import { connect } from 'react-redux';

interface ElevatorState
{
    view: number;
    elevator: any;
}

class Elevators extends React.Component<any, ElevatorState>
{
    stringProvider: StringProvider;

    constructor(props: any)
    {
        super(props)
        this.stringProvider = new StringProvider();
        
        this.state = {
            view: MobileView.Master,
            elevator: null
        }
    }

    onItemClick(elevator: any)
    {
        this.setState({ view: MobileView.Detail, elevator: elevator})
    }

    componentDidMount()
    {
        this.setState({ view: this.props.match.params.elevator ? MobileView.Detail : MobileView.Master })
    }

    render()
    {
        let elevatorId = this.stringProvider.fromUrl(this.props.match.params.elevator);
        let buildingId = this.stringProvider.fromUrl(this.props.match.params.building);

        let url = "";
        if (buildingId && elevatorId)
            url = "/" + buildingId + "/" + elevatorId;

        let elevatorTabData: NavItemProps[] = [
            //{
            //    icon: '',
            //    iconOnly: false,
            //    text: 'Events',
            //    state: window.location.pathname.includes("events") ? NavItemState.Active : NavItemState.None,
            //    to: '/elevators/events' + url
            //},
            {
                icon: '',
                iconOnly: false,
                text: 'Gatekeepers',
                state: window.location.pathname.includes("gatekeepers") ? NavItemState.Active : NavItemState.None,
                to: '/elevators/gatekeepers' + url
            }
        ];

        let isSuperUser = false;

        if (this.props.roles && (this.props.roles.includes("PSEC SUPER USER") || this.props.roles.includes("PSEC RO SUPER USER")))
            isSuperUser = true

        return (
            <TemplateMasterDetail
                mobileView={this.state.view}
                startState={true}
                onBack={() => this.setState({ view: MobileView.Master })}
                masterTitle="My Elevators"
                master={<ElevatorMaster superUser={isSuperUser} buildingId={buildingId} elevatorId={elevatorId} onClick={(e: any) => this.onItemClick(e)} />}
                navigation={elevatorId ? <NavBar responsiveType={ResponsiveTypes.CollapseFit} type={NavBarTypes.Tabs} tabs={elevatorTabData} /> : <div></div>}
                detailTitle={this.state.elevator ? this.state.elevator.name : ""}
                detail={<ElevatorsDetails buildingId={buildingId} elevatorId={elevatorId} />}
            />
        )
    }
}

const mapStateToProps = (state: any) => ({
    roles: state.app.roles
});

export default connect(mapStateToProps)(Elevators);