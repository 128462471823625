import React, { Fragment } from 'react';
import { Text, TextType, Spacer } from '@mit/hui';
import SummaryView from './SummaryView';
import SummaryViewReadOnly from './SummaryViewReadOnly';
import { RecentActivitiesModel } from '../../../api/models/DoorSummaryModel';
import { connect } from 'react-redux';

interface EventsSummaryEventsProps
{
    data: RecentActivitiesModel;
    doorId: string;
    isOnline: boolean;
    roles: string[];
}

class EventsSummaryEvents extends React.Component<EventsSummaryEventsProps>
{
    render()
    {
        return <Fragment>
            <Text content="Summary" type={TextType.Heading4} icon='' />
            <Text content={"Summarized overview of past week"} padded={true} type={TextType.Body} />
            {
                this.props.roles.includes("PSEC RO SUPER USER") ?
                    <SummaryViewReadOnly isOnline={this.props.isOnline} doorId={this.props.doorId} data={this.props.data} />
                    :
                    <SummaryView isOnline={this.props.isOnline} doorId={this.props.doorId} data={this.props.data} />
            }
            <Spacer size={"2"} />
        </Fragment>
    }
}

const mapStateToProps = (state: any) => ({
	roles: state.app.roles
});

export default connect(mapStateToProps)(EventsSummaryEvents);