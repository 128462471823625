import React, { Fragment, FC } from 'react';
import { BuildingModel } from '../../../api/models/BuildingModel';
import { withComponent } from '../../../common/WithComponent';
import { BuildingEntityView } from './BuildingEntityView';

interface BuildingsDoorsViewProps
{
    data: BuildingModel[];
}

export const BuildingsView: FC<BuildingsDoorsViewProps> = (props) =>
{
    const BuildingEntityViewData = withComponent(BuildingEntityView);

    return <Fragment>
        {
            props.data.map((buildings: BuildingModel, index: number) =>
            {
                return <Fragment>
                    <BuildingEntityViewData key={index} data={buildings} />
                </Fragment>
            })
        }
    </Fragment>
}