import React, { Fragment } from 'react';
import { Icon, Table } from '@mit/hui';


interface MultipleCardholderModalProps {
    groups: string[];
    managedGroup: string;
}

export const MultipleCardholderModal: React.FC<MultipleCardholderModalProps> = (props) => {

    function getCardholderGroups(groups: string[], managedGroup: string) {

        if (groups.length < 1) {
            return [];
        }

        let filteredGroups = groups.filter(e => e !== managedGroup);

        if (filteredGroups.length > 0) {
            let cardholderGroups = filteredGroups.map((itm) => {
                return {
                    state: "",
                    items: [<Fragment><Icon type={"regular"} icon="bars" /> {itm}</Fragment>]
                }
            });

            return cardholderGroups;
        } else {
            return [];
        }
    }

    return (
        <Fragment>
            <p style={{ fontSize: 17 }}>
                Other cardholder groups linked to his clearance are: 
            </p>
            {
                <Table columns={1} type={""} flush={true} header={[]} rows={getCardholderGroups(props.groups, props.managedGroup)} />
            }
            <br />
            <br />
        </Fragment>
    );
}
