import BaseController from "./BaseController";
import { SpecificCardholderResponseModel } from "./models/CardholdersResponseModel";
import StringProvider from "../services/StringProvider";

export interface AlarmContactsResponse
{
    contact_1: string;
    contact_1_department: string;
    contact_1_email: string;
    contact_1_phone_1: string;
    contact_1_phone_2: string;
    contact_2: string;
    contact_2_department: string;
    contact_2_email: string;
    contact_2_phone_1: string;
    contact_2_phone_2: string;
}
class AlarmController extends BaseController
{
    //GET

    getAlarms = async (): Promise<any> =>
    {
        return await this.fetchData(this.baseUrl, "/alarms")
    }

    getAlarmSchedules = async (alarmId: string): Promise<any> =>
    {
        let alarm = new StringProvider().toUrl(alarmId);
        return await this.fetchData(this.baseUrl, `/alarms/${alarm}/schedules`)
    }

    getAlarmDepartments = async (alarmId: string): Promise<any> =>
    {
        let alarm = new StringProvider().toUrl(alarmId);
        return await this.fetchData(this.baseUrl, `/alarms/${alarm}/departments`)
    }

    getAreas = async (alarmId: string): Promise<any> =>
    {
        let alarm = new StringProvider().toUrl(alarmId);
        return await this.fetchData(this.baseUrl, `/alarms/${alarm}/areas`)
    }

    getContacts = async (alarmId: string): Promise<AlarmContactsResponse> =>
    {
        let alarm = new StringProvider().toUrl(alarmId);
        return await this.AbortablefetchData(this.baseUrl, `/alarms/${alarm}/contacts`)
    }

    //POST

    postAdditions = async (alarmId: string, kerbId: string): Promise<Response> =>
    {
        let alarm = new StringProvider().toUrl(alarmId);

        let body = {
            cardholder_id: kerbId
        }

        return await this.postDataRaw(this.baseUrl, "/alarms/" + alarm + "/cardholders", body);
    }

    postBulkAdditions = async (alarmId: string, body: any): Promise<any> =>
    {
        let alarm = new StringProvider().toUrl(alarmId);
        return await this.postData(this.baseUrl, "/alarms/" + alarm + "/cardholders/bulk", body);
    }

    postDepartments = async (alarmId: string, department: string): Promise<any> =>
    {
        let alarm = new StringProvider().toUrl(alarmId);

        const body = {
            department: department
        }

        return await this.postData(this.baseUrl, "/alarms/" + alarm + "/departments", body);
    }

    postContacts = async (alarmId: string, body: AlarmContactsResponse): Promise<any> =>
    {
        let alarm = new StringProvider().toUrl(alarmId);

        return await this.postData(this.baseUrl, "/alarms/" + alarm + "/contacts", body);
    }
    
    //DELETE
    deleteRemovals = async (alarmId: string, kerbId: any): Promise<Response> =>
    {
        let alarm = new StringProvider().toUrl(alarmId);

        let body = {
            cardholder_id: kerbId
        }

        return await this.deleteDataRaw(this.baseUrl, "/alarms/" + alarm + "/cardholders", body);
    }

    deleteDepartment = async (alarmId: string, department: string): Promise<SpecificCardholderResponseModel> =>
    {
        let alarm = new StringProvider().toUrl(alarmId);

        const body = {
            department: department
        }

        return await this.deleteData(this.baseUrl, "/alarms/" + alarm + "/departments", body);
    }

    deleteBulkRemovals = async (alarmId: string, body: any): Promise<any> =>
    {
        let alarm = new StringProvider().toUrl(alarmId);
        return await this.deleteData(this.baseUrl, "/alarms/" + alarm + "/cardholders/bulk", body);
    }
}

export default AlarmController;