import React, { Fragment } from 'react'
import { PersonModel } from '../../../api/models/PersonModel'
import PeopleController from '../../../api/PeopleController'
import { ContactCard, Error, ErrorContext, ErrorType } from '@mit/hui'
import HomeController from '../../../api/HomeController'
import StringProvider from '../../../services/StringProvider'

interface FullListDetailViewState {
  image: string
}

interface FullListDetailViewProps {
  data: PersonModel
}

class FullListDetailView extends React.Component<FullListDetailViewProps, FullListDetailViewState> {
  peopleController: PeopleController
  homeController: HomeController
  stringProvider: StringProvider

  constructor (props: FullListDetailViewProps) {
    super(props)

    this.peopleController = new PeopleController()
    this.homeController = new HomeController()
    this.stringProvider = new StringProvider()

    this.state = {
      image: ''
    }
  }

  async componentDidMount () {
    if (this.props.data) {
      if (this.props.data.mit_id && this.props.data.mit_id.match(/^333([0-9]){6}$/g)) {
        this.homeController.geCSGoldPicture(this.props.data.mit_id).then((data: string) => {
          this.setState({ image: data })
        })
      } else {
        this.homeController.getPictureByKerb(this.props.data.kerberos_id ? this.props.data.kerberos_id : this.props.data.mit_id).then((data: string) => {
          this.setState({ image: data })
        })
      }
    } else {
      //@ts-ignore
      this.setState({ isLoading: false })
    }
  }

  getText (cardholder: PersonModel) {
    if (cardholder.display_name) return cardholder.display_name

    if (cardholder.kerberos_id) return cardholder.kerberos_id

    if (cardholder.first_name && cardholder.last_name) return `${cardholder.first_name} ${cardholder.last_name} (${cardholder.mit_id})`

    if (cardholder.mit_id) return cardholder.mit_id

    return ''
  }

  render () {
    return (
      <Fragment>
        <div className='p-4'>
          {this.props.data ? (
            <ContactCard
              isPerson={true}
              profilePicUrl={this.state.image ? this.state.image : ''}
              address={this.props.data.office_location ? this.props.data.office_location : ' - '}
              name={this.getText(this.props.data)}
              company=''
              affiliation={this.stringProvider.capitalizeFirstLetter(this.props.data.affiliation)}
              department={this.props.data.department}
              email={this.props.data.email}
              telephone={this.props.data.phone_number ? this.props.data.phone_number : ' - '}
            />
          ) : (
            <Error context={ErrorContext.Component} message={'No data available'} type={ErrorType.NoData} />
          )}
        </div>
      </Fragment>
    )
  }
}

export default FullListDetailView
