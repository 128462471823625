import React, { Fragment } from 'react';
import { Error, ErrorContext, ErrorType, Table, Icon } from '@mit/hui';
import { CardHoldersModel } from '../../../api/models/CardHoldersModel';

interface SubListViewProps
{
    data: CardHoldersModel;
    cardholder_id?: string;
}

interface SubListViewState
{
    id: string;
}

class SubListView extends React.Component<SubListViewProps, SubListViewState>
{
    render()
    {
        let lists: any[] = [];

        if (this.props.data && this.props.data.sub_lists)
        {
            lists = this.props.data.sub_lists.map((itm: string) =>
            {
                return {
                    state: "",
                    items: [<Fragment><Icon type={"regular"} icon="bars" /> {itm}</Fragment>]
                }
            })
        }

        return <Fragment>
            {
                this.props.data && this.props.data.sub_lists && this.props.data.sub_lists.length > 0
                    ?
                    <Fragment>
                        {
                            <Table columns={1} type={""} flush={true} header={[]} rows={lists} />
                        }
                    </Fragment>
                    :
                    <Error context={ErrorContext.Component} message={"No sub lists available"} type={ErrorType.NoData} />
            }
        </Fragment>
    }
}

export default SubListView;