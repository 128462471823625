import React, { Fragment } from 'react';
import { Error, ErrorContext, ErrorType } from '@mit/hui';
import ClearanceController from '../../../api/ClearanceController';
import { LoadingIndicator } from '../../../common/LoadingIndicator';
import AreaController from '../../../api/AreaController';
import { BuildingsView } from './BuildingsView';

interface ManageBuildingDetailProps
{
    data: any;
}

interface ManageBuildingDetailState
{
    buildingsList: any;
    isLoading: boolean;
}

export default class ManageBuildingDetail extends React.Component<ManageBuildingDetailProps, ManageBuildingDetailState>
{
    clearanceController: ClearanceController;
    areaController: AreaController;

    constructor(props: ManageBuildingDetailProps)
    {
        super(props)

        this.clearanceController = new ClearanceController();
        this.areaController = new AreaController();

        this.state = {
            buildingsList: [],
            isLoading: true
        }
    }

    async componentDidMount()
    {
        this.setState({
            buildingsList: this.props.data.buildings,
            isLoading: false
        })
    }

    render()
    {
        if (!this.props.data.buildings && this.props.data !== 404)
            return <Error context={ErrorContext.Component} message={"No data available for building details"} type={ErrorType.NoData} />

        if (this.state.isLoading)
            return <LoadingIndicator />

        return <Fragment>
            {
                this.state.buildingsList.length === 0
                    ?
                    <Error context={ErrorContext.Component} message={"No buildings available."} type={ErrorType.NoData} retry={true} />
                    :
                    <Fragment>
                        <BuildingsView data={this.state.buildingsList} />
                    </Fragment>
            }
        </Fragment>
    }
}