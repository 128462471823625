import { ActionList, TextType, Text, Spacer, ListItem, ListItemState, ErrorType, ErrorContext, Error } from '@mit/hui'
import React, { Fragment, useEffect, useState } from 'react'
import { ScheduleEntitiesModel } from '../../../api/models/ScheduleEntitiesModel'
import ScheduleController from '../../../api/ScheduleController'
import { EntityDetails } from './EntityDetails'

interface ScheduleEntitiesProps {
  scheduleId: string
}

export default function ScheduleEntities (props: ScheduleEntitiesProps) {
  const scheduleController = new ScheduleController()
  const [loading, setLoading] = useState<boolean>(true)
  const [response, setResponse] = useState<ScheduleEntitiesModel>()
  const [error, setError] = useState<Boolean>(false)
  const [noData, setNoData] = useState<boolean>(false)

  useEffect(() => {
    setError(false)
    setLoading(true)

    scheduleController
      .getScheduleEntities(props.scheduleId)
      .then(data => {
        let response: ScheduleEntitiesModel = {
          clearances: data.clearances,
          doors: data.doors,
          elevators: data.elevators
        }
        setResponse(response)
        if (response.clearances.length === 0 && response.doors.length === 0 && response.elevators.length === 0) {
          setNoData(true)
        } else {
          setNoData(false)
        }
        setLoading(false)
      })
      .catch(error => {
        setError(true)
      })
  }, [props.scheduleId])

  if (error) {
    return <Error context={ErrorContext.Component} message='Error retrieving entities connected to Schedule' type={ErrorType.Generic} />
  }

  if (loading) {
    return <ActionList isLoading items={[]} />
  }

  if (response === undefined) {
    return <Error context={ErrorContext.Component} message='No data available' type={ErrorType.NoData} />
  }

  if (noData) {
    return <Error context={ErrorContext.Component} message='There are no clearances, elevators or doors connected to this schedule' type={ErrorType.NoData} />
  }

  return (
    <Fragment>
      <Fragment>
        {response?.clearances.length > 0 ? (
          <Fragment>
            <Text content='Clearances' type={TextType.Heading4} icon='' />
            <Spacer size='2' />
            {response.clearances.map((clearance, index) => {
              return <ListItem key={index} icon='id-card' text={clearance} state={ListItemState.None} />
            })}
          </Fragment>
        ) : null}
        <Spacer size='2' />
        {response?.doors.length < 1 && response?.elevators.length < 1 ? null : (
          <Fragment>
            <Text content='Entities' type={TextType.Heading4} icon='' />
            <Spacer size='2' />
            <EntityDetails doors={response.doors} elevators={response.elevators} />
          </Fragment>
        )}
      </Fragment>
    </Fragment>
  )
}
