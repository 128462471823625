import { ClearancesModel } from './models/ClearancesModel'
import { SchedulesModel } from './models/SchedulesModel'
import BaseController from './BaseController'
import { SpecificCardholderResponseModel } from './models/CardholdersResponseModel'
import { DepartmentsModel } from './models/DepartmentsModel'
import StringProvider from '../services/StringProvider'
import { ResponseModal } from './models/ResponseModel'

class ClearanceController extends BaseController {
  //GET

  getClearances = async (): Promise<ClearancesModel> => {
    return await this.fetchData(this.baseUrl, '/clearances')
  }

  getSchedules = async (clearanceId: string): Promise<SchedulesModel> => {
    let clearance = new StringProvider().toUrl(clearanceId)
    return await this.fetchWithStatusData(this.baseUrl, '/clearances/' + clearance + '/schedules')
  }

  getSchedulesAndStatus = async (clearanceId: string): Promise<ResponseModal> => {
    let clearance = new StringProvider().toUrl(clearanceId)
    return await this.fetchDataAndStatus(this.baseUrl, '/clearances/' + clearance + '/schedules')
  }

  getDepartment = async (clearanceId: string): Promise<DepartmentsModel> => {
    let clearance = new StringProvider().toUrl(clearanceId)
    return await this.fetchData(this.baseUrl, '/clearances/' + clearance + '/departments')
  }

  getAreas = async (clearanceId: string): Promise<DepartmentsModel> => {
    let clearance = new StringProvider().toUrl(clearanceId)
    return await this.fetchData(this.baseUrl, '/clearances/' + clearance + '/areas')
  }

  getAreasWithStatus = async (clearanceId: string): Promise<ResponseModal> => {
    let clearance = new StringProvider().toUrl(clearanceId)
    return await this.fetchDataAndStatus(this.baseUrl, '/clearances/' + clearance + '/areas')
  }

  //POST

  postAdditions = async (clearanceId: string, kerbId: string): Promise<any> => {
    let clearance = new StringProvider().toUrl(clearanceId)

    let body = {
      cardholder_id: kerbId
    }

    return await this.postDataRaw(this.baseUrl, '/clearances/' + clearance + '/cardholders', body)
  }

  postBulkAdditions = async (clearanceId: string, body: any): Promise<any> => {
    let clearance = new StringProvider().toUrl(clearanceId)
    return await this.postData(this.baseUrl, '/clearances/' + clearance + '/cardholders/bulk', body)
  }

  postDepartment = async (clearanceId: string, department: string): Promise<any> => {
    let clearance = new StringProvider().toUrl(clearanceId)

    const body = {
      department: department
    }

    return await this.postData(this.baseUrl, '/clearances/' + clearance + '/departments', body)
  }

  //DELETE
  deleteRemovals = async (clearanceId: string, kerbId: any): Promise<Response> => {
    let clearance = new StringProvider().toUrl(clearanceId)

    let body = {
      cardholder_id: kerbId
    }

    return await this.deleteDataRaw(this.baseUrl, '/clearances/' + clearance + '/cardholders', body)
  }

  deleteBulkRemovals = async (clearanceId: string, body: any): Promise<any> => {
    let clearance = new StringProvider().toUrl(clearanceId)
    return await this.deleteData(this.baseUrl, '/clearances/' + clearance + '/cardholders/bulk', body)
  }

  deleteDepartment = async (clearanceId: string, department: string): Promise<SpecificCardholderResponseModel> => {
    let clearance = new StringProvider().toUrl(clearanceId)

    const body = {
      department: department
    }

    return await this.deleteData(this.baseUrl, '/clearances/' + clearance + '/departments', body)
  }

  getClearanceBasicInfo = async (clearanceId: string): Promise<ResponseModal> => {
    let clearance = new StringProvider().toUrl(clearanceId)
    let data = await this.fetchDataAndStatus(this.baseUrl, '/clearances/' + clearance + '/basic_info')
    return data
  }

  timeout (ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  async getClearanceCardholderGroups (clearanceId: string): Promise<{ groups: string[]; managedGroup: string } | null> {
    try {
      let clearance = new StringProvider().toUrl(clearanceId)
      let data = await this.fetchData(this.baseUrl, '/clearances/' + clearance + '/moira-groups')
      return data
    } catch (error) {
      //if anyting goes wrong, return null
      return null
    }
  }

  async getClearanceCardholderGroupsAndStatus (clearanceId: string): Promise<ResponseModal> {
    let clearance = new StringProvider().toUrl(clearanceId)
    let data = await this.fetchDataAndStatus(this.baseUrl, '/clearances/' + clearance + '/moira-groups')
    return data
  }
}

export default ClearanceController
