import BaseController from "./BaseController";
import { ProfileModel } from "./models/ProfileModel";
import appconfig from "../app-config";
import { SearchModel } from "./models/SearchModel";
import StringProvider from "../services/StringProvider";

class HomeController extends BaseController
{
    //GET
    getProfile = async (id?: string): Promise<ProfileModel> =>
    {
        return await this.fetchData(appconfig.api.hostname + "/" + appconfig.api.paths["digital-id"], `/profile${id ? '/' + id : ""}`);
    }

    getCsGoldProfile = async (id: string): Promise<any> => {
        return await this.fetchDataAndStatus(appconfig.api.hostname, "/" + appconfig.api.paths["physical-security"] + "/people/profiles/csgold/" + id)
    }

    getFeatures = async (): Promise<{featureFlags: string[]}> => {
        let feature = new StringProvider().toUrl(appconfig.atlasFeatureAppId)
        return await this.fetchData(appconfig.api.hostname + '/' + appconfig.api.paths['atlas-ui'], `/features/${feature}`)
    }

    getPicture = async (): Promise<any> =>
    {
        return await this.fetchPicture(appconfig.api.hostname + "/" + appconfig.api.paths["digital-id"], "/picture");
    }

    getPictureByKerb = async (kerb: string): Promise<any> =>
    {
        return await this.fetchPicture(appconfig.api.hostname + "/" + appconfig.api.paths["digital-id"], "/picture/" + kerb);
    }

    geCSGoldPicture = async (mitId: string): Promise<any> =>
    {
        return await this.fetchPicture(appconfig.api.hostname + "/" + appconfig.api.paths["physical-security"], "/people/picture", { cardholder_id: mitId });
    }

    getSearch = async (nameOrId: string): Promise<SearchModel[]> =>
    {
        return await this.AbortablefetchData(appconfig.api.hostname + "/" + appconfig.api.paths["digital-id"], "/search", { q: nameOrId });
    }

    feedback = async (body: any): Promise<any> =>
    {
        try
        {
            const response = await fetch(appconfig.api.hostname + "/feedback-v1/feedback ", await this.requestHeaderPost(body));

            if (response.status === 200)
            {
                const data = await response.json();

                return data;
            }
            else
            {
                return response.status;
            }
        } catch (e)
        {
            console.log(e);
            return null;
        }
    }

    initialize = async (): Promise<any> =>
    {
        try
        {
            const response = await fetch(this.baseUrl + "/initialize", await this.requestHeaderGet());

            if (response.status === 200)
            {
                const data = await response.json();

                return data;
            }
            else
            {
                return response.status;
            }
        } catch (e)
        {
            return null;
        }
    }
}

export default HomeController;