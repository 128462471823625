import React, { Fragment } from 'react';
import { TemplateModal, TemplateModalAlignment, TemplateModalSize } from '@mit/hui';

interface ModalProps
{
    body: any;
    header: any;
    footer: any;
    show: boolean;
    onClose: any;
    name: string;
    padded?: boolean;
    size: TemplateModalSize;
    containerless: boolean;
}

export default class Modal extends React.Component<ModalProps>
{
    render()
    {
        return <Fragment>
            <TemplateModal
                show={this.props.show}
                onClose={this.props.onClose}
                padded={this.props.padded}
                body={this.props.body}
                bodyAlignment={TemplateModalAlignment.Center}
                footer={this.props.footer}
                header={this.props.header}
                imageUrl=""
                name={this.props.name}
                size={this.props.size}
                containerless={this.props.containerless}
            />
        </Fragment>
    }
}