import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { NotificationModel } from './redux/reducers/notification';
import NotificationModal from '../common/NotificationModal';
import { ToastProps, ToastContainer, ToastContainerProps, ToastPosition } from '@mit/hui';

class Notification extends React.Component<any>
{
    render()
    {
        let toastData: ToastContainerProps = { position: ToastPosition.BottomRight, toasts: [] };
        const iconData: any = {
            type: "regular", icon: "bell", padded: true
        };
        // eslint-disable-next-line
        this.props.notifications.map((notification: NotificationModel) =>
        {
            let toastItem: ToastProps = {
                icon: iconData,
                title: "",
                message: "",
                show: true,
                onClose: () =>
                {
                    return null
                },
                template: () =>
                {
                    return <NotificationModal data={notification}/>
                }
            }

            toastData.toasts.push(toastItem);
        })

        return <Fragment>
            <ToastContainer {...toastData} />
        </Fragment>
    }
}

const mapStateToProps = (state: any) => ({
    notifications: state.notification.notifications
});


export default connect(mapStateToProps)(Notification);
