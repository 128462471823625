import React, { Fragment, FC, useState } from 'react';
import { BuildingModel } from '../../../api/models/BuildingModel';
import { AccordionItem, ListItemProps, AccordionItemState, ListItemState } from '@mit/hui';
import { ElevatorsModel } from '../../../api/models/ElevatorsModel';

interface BuildingDoorEntityViewProps
{
    data: BuildingModel;
    onClick: any;
    elevatorId: string;
    buildingId: string;
    keyword: string;
    superUser: boolean;
}

export const BuildingElevatorEntityView: FC<BuildingDoorEntityViewProps> = (props) =>
{
    const onClick = () =>
    {
        let items: ListItemProps[] = [];

        if (props.data.areas && props.data.areas.length > 0)
        {
            // eslint-disable-next-line
            props.data.areas.map((itm: BuildingModel) =>
            {
                const subItem = () =>
                {
                    return <BuildingElevatorEntityView superUser={props.superUser} data={itm} onClick={props.onClick} buildingId={props.buildingId} elevatorId={props.elevatorId} keyword={props.keyword} />;
                }

                let buildingItem: ListItemProps = {
                    text: props.data.name,
                    icon: "building",
                    template: subItem
                }

                items.push(buildingItem)
            })
        }

        if (props.data.elevators && props.data.elevators.length > 0)
        {
            // eslint-disable-next-line
            props.data.elevators.map((elevator: ElevatorsModel) =>
            {
                let elevatorState = ListItemState.None;

                elevatorState = (!elevator.is_online ? ListItemState.Disabled : ((props.elevatorId === elevator.id && props.buildingId === props.data.id) ? ListItemState.Active : ListItemState.None))

                if (props.superUser)
                    elevatorState = (props.elevatorId === elevator.id && props.buildingId === props.data.id) ? ListItemState.Active : ListItemState.None

                let buildingItem: ListItemProps = {
                    text: elevator.name,
                    secondaryText: elevator.is_online ? "" : "Not available",
                    icon: "door-closed",
                    onClick: () =>
                    {
                        if (elevatorState !== ListItemState.Disabled)
                            props.onClick(props.data, elevator)
                    },
                    disabledTitle: !elevator.is_online ? "This elevator is not available yet" : "",
                    state: elevatorState
                }

                items.push(buildingItem)
            })
        }

        return items
    }

    const [data] = useState<any[]>(onClick());

    let accState = AccordionItemState.Contracted;

    if (props.keyword)
    {
        accState = AccordionItemState.Expanded;
        onClick();
    }

    if (props.buildingId && props.data.name === "Buildings")
    {
        accState = AccordionItemState.Expanded;
        onClick();
    }

    if (props.buildingId === props.data.id)
    {
        accState = AccordionItemState.Expanded;
        onClick();
    }

    if (props.data.areas.some((itm) => itm.id === props.buildingId))
    {
        accState = AccordionItemState.Expanded;
        onClick();
    }

    return <Fragment>
        {
            data && data.length > 0
            ?
            <AccordionItem icon={"building"} items={data} title={props.data.name} state={accState} onClick={() => onClick()} />
            :
            <AccordionItem icon={"building"} items={[{text: "No elevators available", icon: '', state: ListItemState.Disabled}]} title={props.data.name} state={accState} onClick={() => onClick()} />
        }
    </Fragment>
}