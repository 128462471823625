import * as types from "../constants/ActionTypes";

const initialState =
{
    user: {
        kerberos: "",
        first_name: "",
        last_name: "",
        display_name: "",
        email: ""
    },
    picture: "",
    roles: [],
    features: []
};

export default function app(state = initialState, action: any) 
{
    switch (action.type) 
    {
        case types.CHANGE_USER:
            {
                return { ...state, user: action.payload };
            }
        case types.CHANGE_PICTURE:
            {
                return { ...state, picture: action.payload };
            }
        case types.CHANGE_ROLES:
            {
                return { ...state, roles: action.payload };
            }
        case types.CHANGE_FEATURES:
            {
                return { ...state, features: action.payload };
            }
        default:
            return state;
    }
}