import React from "react";
import { FilteredList, Error, ErrorContext, ErrorType } from "@mit/hui";
import EntitiesController from "../../api/EntitiesController";
import History from "../../common/History";
import { BuildingModel } from "../../api/models/BuildingModel";
import StringProvider from "../../services/StringProvider";
import { BuildingsDoorsView } from "./components/BuildingsDoorsView";
import { withComponent } from "../../common/WithComponent";
import FilterProvider from "../../services/FilterProvider";

interface DoorMasterState {
	buildings: any;
	buildingsData: any;
	isLoading: boolean;
	isError: boolean;
	isEmpty: boolean;
	filteredData: any;
	keyword: string;
}

interface DoorMasterProps {
	buildingId: string;
	doorId: string;
	onClick: any;
	superUser: boolean;
	onSelect: (isOnline: boolean) => void;
}

const WAIT_INTERVAL = 200;
let timerID: any;

export default class DoorMaster extends React.Component<DoorMasterProps, DoorMasterState> {
	entitiesController: EntitiesController;
	stringProvider: StringProvider;
	filterProvider: FilterProvider;

	constructor(props: any) {
		super(props);

		this.entitiesController = new EntitiesController();
		this.stringProvider = new StringProvider();
		this.filterProvider = new FilterProvider();

		this.state = {
			buildings: [],
			buildingsData: null,
			isLoading: true,
			isError: false,
			isEmpty: false,
			filteredData: null,
			keyword: "",
		};
	}

	componentWillUnmount() {
		this.entitiesController.abortRequest();
	}

	mapBuildingsAndDoors = (data: BuildingModel[]) => {
		if (this.props.doorId) {
			let door = this.filterProvider.getObjectById(data, this.props.doorId);
			this.props.onClick(door);
		}

		let defaultListData: any = [];
		const BuildingsDoorsViewData = withComponent(BuildingsDoorsView);

		if (data && data.length === 0) return defaultListData;

		const componentObject = {
			template: () => (
				<BuildingsDoorsViewData
					superUser={this.props.superUser}
					data={data}
					onClick={(itmBuilding: any, itm: any) => this.onChange(itmBuilding, itm)}
					doorId={this.props.doorId}
					buildingId={this.props.buildingId}
					keyword={this.state.keyword}
				/>
			),
		};

		defaultListData.push(componentObject);

		return defaultListData;
	};

	filterArea(area: BuildingModel, filterBy: string) {
		let filteredArea = Object.assign({}, area);

		// child areas
		let filteredChildren = [];

		if (area.areas && area.areas.length > 0)
			for (let childArea of area.areas) {
				// "recursive" traversal call for child areas
				let filteredChild = this.filterArea(childArea, filterBy.toLowerCase());

				if (filteredChild) {
					filteredChildren.push(filteredChild);
				}
			}

		filteredArea.areas = filteredChildren;

		// filter check on current area name
		if (filteredArea.name.toLowerCase().includes(filterBy.toLowerCase())) {
			filteredArea.doors = area.doors;
			filteredArea.elevators = area.elevators;
			return filteredArea;
		}

		// area doors
		let filteredDoors = [];
		if (area.doors && area.doors.length > 0)
			for (let door of area.doors) {
				if (door.name.toLowerCase().includes(filterBy.toLowerCase())) {
					filteredDoors.push(door);
				}
			}
		filteredArea.doors = filteredDoors;

		// area elevators
		let filteredElevators = [];
		if (area.elevators && area.elevators.length > 0)
			for (let elevator of area.elevators) {
				if (elevator.name.toLowerCase().includes(filterBy.toLowerCase())) {
					filteredElevators.push(elevator);
				}
			}
		filteredArea.elevators = filteredElevators;

		// filter check on children, doors and elevators
		if (filteredChildren.length !== 0 || filteredDoors.length !== 0 || filteredElevators.length !== 0) {
			return filteredArea;
		}

		// no filter matched so return nothing
		return null;
	}

	filterBuildingsAndDoors = (keyword: string) => {
		this.setState({ keyword: keyword });
		clearTimeout(timerID);

		if (keyword.length < 1) {
			this.setState({ filteredData: null, isLoading: false });
			return;
		}

		this.setState({ isLoading: true });

		timerID = setTimeout(() => {
			if (keyword.length < 1) {
				this.setState({ filteredData: null, isLoading: false });
				return;
			}

			let allData = this.state.buildingsData;

			let filteredData = this.filterArea(allData.buildings[0], keyword);

			if (filteredData) {
				let mapped = this.mapBuildingsAndDoors([filteredData]);
				this.setState({ filteredData: mapped, isLoading: false });
			} else {
				this.setState({ filteredData: [], isLoading: false });
			}
		}, WAIT_INTERVAL);
	};

	getObject = (theObject: any, name: string): any => {
		var result = null;
		if (theObject instanceof Array) {
			for (var i = 0; i < theObject.length; i++) {
				result = this.getObject(theObject[i], name);
				if (result) {
					break;
				}
			}
		} else {
			for (var prop in theObject) {
				if (prop === "id") {
					if (theObject[prop] === name) {
						return theObject;
					}
				}
				if (theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
					result = this.getObject(theObject[prop], name);
					if (result) {
						break;
					}
				}
			}
		}
		return result;
	};

	async componentDidMount() {
		const buildingsData = await this.entitiesController.getDoors();

		if (buildingsData) {
			if (this.props.buildingId && this.props.doorId) {
				let doorInfo = this.getObject(buildingsData.buildings[0], this.props.doorId);
				if (doorInfo) {
					this.props.onSelect(doorInfo.is_online);
				}
			}

			let mapped = this.mapBuildingsAndDoors(buildingsData.buildings);

			this.setState({
				buildings: mapped,
				buildingsData: buildingsData,
				isLoading: false,
			});
		} else {
			this.setState({
				isLoading: false,
				isError: true,
			});
		}
	}

	onChange = (building: any, door: any): void => {
		this.props.onClick(door);
		this.props.onSelect(door.is_online);

		const newPath = this.generateURL(building.id, door.id);
		History.push(newPath);
	};

	checkEmpty() {
		if (this.state.filteredData && this.state.filteredData.length === 0) return true;

		if (this.state.buildings && this.state.buildings.length === 0) return true;

		return false;
	}

	generateURL(building: string, door: string) {
		const path = window.location.pathname;

		building = this.stringProvider.toUrl(building);
		door = this.stringProvider.toUrl(door);

		if (path === "/doors/events") {
			return "/doors/events/" + building + "/" + door;
		} else if (path === "/doors/gatekeepers") {
			return "/doors/gatekeepers/" + building + "/" + door;
		} else {
			const pathParts = window.location.pathname.split("/");

			pathParts.pop();
			pathParts.pop();
			pathParts.push(building + "/" + door);
			return pathParts.join("/");
		}
	}

	emptyData = {
		startIcon: "search",
		placeholderText: "Filter my buildings or doors",
		state: "icon-start",
		inputType: "text",
		name: "doorMaster",
	};

	render() {
		if (this.state.isError) return <Error context={ErrorContext.Component} message="No data available for Doors" type={ErrorType.NoData} />;

		return (
			<FilteredList
				name="filteredListDoor"
				loadingText="Loading your buildings and doors"
				isLoading={this.state.isLoading}
				isEmpty={this.state.isLoading ? false : this.checkEmpty()}
				selectable={true}
				height={60}
				compact={true}
				searchOptions={this.emptyData}
				items={this.state.filteredData ? this.state.filteredData : this.state.buildings}
				onSearch={this.filterBuildingsAndDoors}
			/>
		);
	}
}
