import React, { Fragment } from 'react'
import { Text, AutoComplete, TemplateModalSize, Error, Table, TextboxType, TextType, TextboxProps, ErrorContext, ErrorType, Icon } from '@mit/hui'
import PeopleController from '../../../api/PeopleController'
import ClearanceController from '../../../api/ClearanceController'
import StringProvider from '../../../services/StringProvider'
import { ResultModel } from '../../../api/models/CardholdersResponseModel'
import { withComponent } from '../../../common/WithComponent'
import Modal from '../../../common/Modal'
import HomeController from '../../../api/HomeController'
import * as notificationActionCreator from '../../../ts/redux/actions/notification'
import { PersonModel } from '../../../api/models/PersonModel'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { TableRowProps } from '@mit/hui/build/components/Table/TableRow'
import { PostResultModel } from '../../../api/models/PostResultModel'
import { CSGoldSearch, queryMatchesCSGold } from '../../../common/CSGoldSupport'
import { queryMatchesVistorId, vistorIdSearch } from '../../../common/VisitorIdSupport'

interface SpecificAdditionProps {
  clearanceId: string
  actions?: any
  roles?: string[]
}

interface SpecificAdditionState {
  cardholders: any
  isBusy: boolean
  isPosting: boolean
  items: PostResultModel
  showSpecificAdditionsModal: boolean
  showSpecificAdditionsFailedModal: boolean
}

const WAIT_INTERVAL = 700
let timerID: any

class SpecificAddition extends React.Component<SpecificAdditionProps, SpecificAdditionState> {
  homeController: HomeController
  peopleController: PeopleController
  clearanceController: ClearanceController
  stringProvider: StringProvider

  constructor (props: SpecificAdditionProps) {
    super(props)

    this.homeController = new HomeController()
    this.peopleController = new PeopleController()
    this.clearanceController = new ClearanceController()
    this.stringProvider = new StringProvider()

    this.state = {
      cardholders: [],
      isBusy: false,
      isPosting: false,
      showSpecificAdditionsModal: false,
      showSpecificAdditionsFailedModal: false,
      items: {
        data: {
          kerberos_id: '',
          mit_id: ''
        },
        id: '',
        message: '',
        success: false
      }
    }

    this.onChange = this.onChange.bind(this)
  }

  async onChange (kerbId: string) {
    this.setState({ isPosting: true, isBusy: false })

    await this.clearanceController.postAdditions(this.props.clearanceId, kerbId).then(async response => {
      let json = await response.json()

      this.setState({
        cardholders: [],
        items: json,
        isPosting: false,
        showSpecificAdditionsModal: true
      })
    })
  }

  getText (cardholder: PersonModel) {
    if (cardholder.display_name) return cardholder.display_name

    if (cardholder.kerberos_id) return cardholder.kerberos_id

    if (cardholder.mit_id) return cardholder.mit_id

    return ''
  }

  getId (cardholder: PersonModel | ResultModel) {
    if (cardholder.kerberos_id) return cardholder.kerberos_id

    if (cardholder.mit_id) return cardholder.mit_id

    return ''
  }

  handleSearch = (query: string, element: React.KeyboardEvent<HTMLInputElement>): void => {
    clearTimeout(timerID)

    if (query.length < 1) {
      this.setState({ cardholders: [], isBusy: false })
      return
    }

    //Check if backspace or delete
    if (element.keyCode === 8 || element.keyCode === 46) return

    this.setState({ isBusy: true })

    timerID = setTimeout(() => {
      this.homeController.abortRequest()

      if (query.length < 1) {
        this.setState({ cardholders: [], isBusy: false })
        return
      }
      // eslint-disable-next-line
      let hasAuthForCSGold = (this.props.roles && this.props.roles.includes('CAN MAINTAIN SPECIAL ID CARDS')) || false

      if (queryMatchesCSGold(query)) {
        this.setState(CSGoldSearch(query, this.onChange, 'add', hasAuthForCSGold))
        return
      }

      if (queryMatchesVistorId(query)) {
        this.setState(vistorIdSearch(query, this.onChange, 'add'))
        return
      }

      this.homeController.getSearch(query).then((cardholders: any) => {
        //check if status not 200 (null is return)
        if (cardholders === null) {
          this.setState({ isBusy: false })
          return
        }

        //Check if type error
        if (cardholders && cardholders.name === 'TypeError') {
          this.setState({ isBusy: false })
          return
        }

        //Check if request was aborted
        if (cardholders && cardholders.name !== 'AbortError') {
          const newData = cardholders.map((itm: PersonModel) => {
            return {
              icon: 'user',
              text: this.getText(itm),
              secondaryText: this.getId(itm),
              tertiaryText: this.stringProvider.capitalizeFirstLetter(itm.department),
              quaternaryText: itm.mit_id,
              onClick: () => this.onChange(this.getId(itm))
            }
          })

          this.setState({
            cardholders: newData,
            isBusy: false
          })
        }
      })
    }, WAIT_INTERVAL)
  }

  render () {
    const emptyData: TextboxProps = {
      startIcon: 'search',
      startIconAccessibilityText: 'search',
      placeholderText: 'Search Name, Kerb or MIT ID ',
      endIconAccessibilityText: 'Clear',
      type: TextboxType.IconStart,
      name: 'specificAddition'
    }

    const tableItems: TableRowProps[] = [
      {
        items: [
          this.getId(this.state.items.data),
          this.state.items.success ? <Icon icon='check' color='green' type='solid' /> : <Icon icon='times' color='red' type='solid' />,
          this.state.items.message
        ],
        state: ''
      }
    ]

    const PopupData = withComponent(Modal)

    return (
      <Fragment>
        <Text content='Specific additions' type={TextType.Heading5} icon='' />
        {this.state.isPosting ? (
          <AutoComplete isLoading items={[]} name='' searchOptions={emptyData} />
        ) : (
          <AutoComplete
            name='specificAdditionAC'
            searchOptions={emptyData}
            limit={20}
            items={this.state.cardholders}
            isBusy={this.state.isBusy}
            onSearch={(q: any, e: any) => {
              this.handleSearch(q, e)
            }}
          />
        )}
        <PopupData
          show={this.state.showSpecificAdditionsModal}
          onClose={() => this.setState({ showSpecificAdditionsModal: false })}
          padded={false}
          size={TemplateModalSize.Large}
          containerless={false}
          body={<Table columns={3} header={['ID', 'Status', 'Description']} rows={tableItems} type={''} flush={true} />}
          footer={''}
          header={<Text content='Cardholder Added' type={TextType.Heading4} icon='' />}
          name={'specificAdditionsModal'}
        />
        <PopupData
          show={this.state.showSpecificAdditionsFailedModal}
          onClose={() => this.setState({ showSpecificAdditionsFailedModal: false })}
          size={TemplateModalSize.Large}
          containerless={false}
          body={<Error context={ErrorContext.Component} message={'Something went wrong'} type={ErrorType.Generic} />}
          footer=''
          header={<Text content='Cardholder added' type={TextType.Heading4} icon='' />}
          name={'specificAdditionsModalFailed'}
        />
      </Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(notificationActionCreator, dispatch)
})

const mapStateToProps = (state: any) => ({
  roles: state.app.roles
})

export default connect(mapStateToProps, mapDispatchToProps)(SpecificAddition)
