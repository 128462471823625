import React, { Fragment } from "react";
import { ActionList, Text, TextType } from "@mit/hui";
import AreaController from "../../api/AreaController";
import AlarmController from "../../api/AlarmController";
import { withData } from "../../common/WithData";
import ManageAlarmBuildingDetail from "./components/ManageAlarmBuildingDetail";
import AlarmFeedback from "./components/AlarmFeedback";

interface IAlarmAreaInformationProps {
	alarmId: string;
	match?: any
}

export default class AlarmAreaInformation extends React.Component<IAlarmAreaInformationProps> {
	alarmController: AlarmController;
	areaController: AreaController;

	constructor(props: any) {
		super(props);

		this.alarmController = new AlarmController();

		this.state = {
			showReportIssue: false,
		};
	}

	render() {
		const ManageAlarmBuildingDetailData = withData(
			ManageAlarmBuildingDetail,
			"Alarm Area Details",
			async () => await this.alarmController.getAreas(this.props.match.params.id),
			<ActionList isLoading={true} />
		);

		return (
			<Fragment>
				<div className="row">
					<div className="col-10">
						<Text content="Alarm Area Details" type={TextType.Heading4} icon="" padded={true} />
					</div>
					<div className="col-2">
						<AlarmFeedback alarmId={this.props.match.params.id} />
					</div>
				</div>
				<ManageAlarmBuildingDetailData />
			</Fragment>
		);
	}
}
