import React, { Fragment } from 'react'
import {
  Error,
  ListItemState,
  ButtonType,
  Text,
  ButtonProps,
  ButtonState,
  ListItem,
  Spacer,
  AutoComplete,
  TextboxProps,
  TextboxType,
  ListItemProps,
  TemplateModalSize,
  TextType,
  ButtonBarType,
  AutoCompleteFilterOn,
  ButtonBar,
  Drawer,
  DrawerType,
  DrawerLayout,
  DrawerSize,
  ActionList,
  ErrorContext,
  GridContainer,
  LayoutColumn,
  ErrorType,
  Toggle
} from '@mit/hui'
import { Departments } from '../../../common/Default'
import { withComponent } from '../../../common/WithComponent'
import Modal from '../../../common/Modal'
import DepartmentController from '../../../api/DepartmentController'
import '../css/toggle-overide.css'
import ScheduleController from '../../../api/ScheduleController'
import { SpecialClearanceAlert } from './SpecialClearanceAlert'

interface ScheduleDepartmentModel {
  scheduleName: string
  departments: string[]
  specialHierarchyTag: boolean
}

interface DepartmentViewProps {
  data: ScheduleDepartmentModel
  scheduleId: string
}

interface DepartmentViewState {
  isLoading: boolean
  itemAdded: boolean
  departments: string[]
  specialHierarchyTag: boolean
  toggleLoading: boolean
  department: string
  showScheduleList: boolean
  loadingSchedules: boolean
  schedules: any
  showDepartmentPopup: boolean
}

export default class DepartmentView extends React.Component<DepartmentViewProps, DepartmentViewState> {
  departmentController: DepartmentController
  scheduleController: ScheduleController

  constructor (props: any) {
    super(props)

    this.departmentController = new DepartmentController()
    this.scheduleController = new ScheduleController()

    this.state = {
      isLoading: true,
      itemAdded: false,
      departments: [],
      specialHierarchyTag: false,
      toggleLoading: false,
      department: '',
      showScheduleList: false,
      loadingSchedules: false,
      schedules: null,
      showDepartmentPopup: false
    }
  }

  componentDidMount () {
    this.setState({
      departments: this.props.data.departments,
      specialHierarchyTag: this.props.data.specialHierarchyTag
    })
  }

  getDepartmentSchedules (departmentId: string) {
    this.setState({ loadingSchedules: true, showScheduleList: true, department: departmentId })

    this.departmentController.getSchedules(departmentId).then((response: any) => {
      this.setState({ schedules: response, loadingSchedules: false })
    })
  }

  extractQualifier (departmentName: string) {
    let qualifier
    if (departmentName.includes(';')) {
      //Is Special case
      qualifier = departmentName.split(';')[0]
      qualifier = qualifier.replace(/ /g, '')
    } else {
      qualifier = departmentName.split('-')[0]
      qualifier = qualifier.replace(/ /g, '')
    }
    return qualifier
  }

  extractDepartmentDescription (departmentName: string) {
    let isSpecialCase = departmentName.includes(';')
    let departmentDesc = ''

    if (isSpecialCase) {
      departmentDesc = departmentName.split(';')[1]
      //Remove dash from the description
      if (departmentDesc.trim().substring(0, 1) === '-') {
        //Remove first character, which will be a dash
        departmentDesc = departmentDesc.trim().substring(1)
      }
    } else {
      departmentDesc = departmentName.split('-')[1]
    }

    return departmentDesc
  }

  async onChange (department: string) {
    //this.setState({ isLoading: true })
    const departmentsList = this.state.departments
    departmentsList.unshift(department)
    this.scheduleController.postDepartments(this.props.scheduleId, department)
    this.setState({ departments: departmentsList, isLoading: false, itemAdded: true })
  }

  arrayRemove (arr: string[], value: string) {
    return arr.filter(function (ele) {
      return ele !== value
    })
  }

  onRemove (department: string) {
    const departmentsList = this.state.departments

    const newDepartments = this.arrayRemove(departmentsList, department)

    this.scheduleController.deleteDepartment(this.props.scheduleId, department)

    this.setState({ departments: newDepartments, isLoading: false, itemAdded: false, showDepartmentPopup: false })
  }

  async onDepartmentToggle (data: any) {
    let switchState: 'Positive' | 'Negative' = data.SWITCH_STATE.choice
    let booleanChoice = switchState === 'Positive' ? true : false

    //Disable toggle while call is processing
    this.setState({
      toggleLoading: true
    })

    this.departmentController
      .updateSCheduleSpecialHierarchyTag(this.props.scheduleId, booleanChoice)
      .then(data => {
        this.setState({
          toggleLoading: false,
          specialHierarchyTag: booleanChoice
        })
      })
      .catch(err => {
        console.log('e: ', err)
        this.setState({
          toggleLoading: false
        })
      })
  }

  getToggleChoice (state: boolean) {
    if (state === false) {
      return 'Negative'
    } else if (state === true) {
      return 'Positive'
    } else {
      return 'Negative'
    }
  }

  render () {
    const SearchBar = withComponent(AutoComplete)
    const PopupData = withComponent(Modal)

    const emptySearch: TextboxProps = {
      startIcon: 'search',
      startIconAccessibilityText: 'search',
      placeholderText: 'Choose departments',
      type: TextboxType.IconStart,
      name: 'search-department'
    }

    let defaultListData: ListItemProps[] = []

    Departments.map((itm: string) => {
      defaultListData.push({
        icon: 'building',
        text: this.extractDepartmentDescription(itm),
        tertiaryText: this.extractQualifier(itm),
        onClick: () => {
          this.state.departments.includes(this.extractQualifier(itm)) ? this.setState({ isLoading: false }) : this.onChange(this.extractQualifier(itm))
        },
        state: this.state.departments.includes(this.extractQualifier(itm)) ? ListItemState.Disabled : ListItemState.None
      })

      return null
    })

    const confirmButtons: ButtonProps[] = [
      {
        text: 'Confirm',
        icon: 'trash',
        type: ButtonType.Primary,
        onClick: () => this.onRemove(this.state.department),
        state: ButtonState.Enabled
      },
      {
        text: 'Cancel',
        icon: 'times',
        type: ButtonType.Ghost | ButtonType.Secondary,
        onClick: () => {
          this.setState({ showDepartmentPopup: false })
        },
        state: ButtonState.Enabled
      }
    ]

    return (
      <Fragment>
        <span className='custom-toggle-container'>
          <Toggle
            disabled={this.state.toggleLoading}
            choice={this.getToggleChoice(this.state.specialHierarchyTag)}
            inverse={true}
            negativeLabel='Mark as special schedule'
            positiveLabel='Mark as special schedule'
            onClick={(choice: any) => this.onDepartmentToggle(choice)}
          />
          <Spacer size={'2'} />
        </span>
        <SpecialClearanceAlert
          showAlert={this.state.specialHierarchyTag}
          text={<span style={{ paddingLeft: '18px' }}>Do not assign departments, this is a special hierarchy schedule</span>}
        />
        <Spacer size={'2'} />
        <SearchBar additionalFilterOn={AutoCompleteFilterOn.tertiaryText} name='departmentAC' items={defaultListData} searchOptions={emptySearch} />
        <Spacer size={'2'} />
        <Fragment>
          {this.state.departments.map((department: string, index: number) => {
            const defaultButtonData: ButtonProps[] = [
              {
                icon: 'trash',
                text: '',
                type: ButtonType.TextNaked,
                onClick: () => {
                  this.setState({ department: department, showDepartmentPopup: true })
                },
                state: ButtonState.Enabled
              }
            ]

            return (
              <ListItem
                onClick={() => {
                  this.getDepartmentSchedules(department)
                }}
                text={department}
                actions={defaultButtonData}
                state={this.state.itemAdded && index === 0 ? ListItemState.RecentlyAdded : ListItemState.None}
              />
            )
          })}
        </Fragment>
        <Drawer
          contents={
            <div>
              <Text content={this.state.department} />
              {this.state.loadingSchedules ? (
                <ActionList isLoading items={[]} />
              ) : (
                <ActionList
                  selectable={false}
                  items={
                    this.state.schedules &&
                    this.state.schedules.schedules.map((itm: any) => {
                      return { text: itm.name, icon: 'id-card' }
                    })
                  }
                />
              )}
            </div>
          }
          onClose={() => this.setState({ showScheduleList: false })}
          show={this.state.showScheduleList}
          type={DrawerType.Right}
          layout={DrawerLayout.Clean}
          size={DrawerSize.Small}
          footer={[]}
          header={<Text content='Department schedules' bold type={TextType.Heading3} />}
        />
        <PopupData
          show={this.state.showDepartmentPopup}
          onClose={() => this.setState({ showDepartmentPopup: false })}
          containerless={false}
          size={TemplateModalSize.Default}
          body={
            <div>
              <Error
                context={ErrorContext.Component}
                message={`Are you sure you want to remove ${this.state.department} from ${this.props.data.scheduleName}?`}
                type={ErrorType.Generic}
              />
              <GridContainer>
                <LayoutColumn alignment={'center'} colSize={12}>
                  <ButtonBar buttons={confirmButtons} type={ButtonBarType.Padded} />
                </LayoutColumn>
              </GridContainer>
            </div>
          }
          footer={''}
          header={<Text content='Remove Department' type={TextType.Heading4} icon='' />}
          name={'departmentPopup'}
        />
      </Fragment>
    )
  }
}
