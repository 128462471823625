import React from 'react';
import { ActionList } from '@mit/hui'
import ClearanceController from '../../api/ClearanceController';
import ManageSchedule from './components/ManageSchedule';
import { withData } from '../../common/WithData';

interface UserClearanceScheduleProps {
    data: any;
}

export default class UserClearanceSchedule extends React.Component<UserClearanceScheduleProps> {
    clearanceController: ClearanceController;

    constructor(props: UserClearanceScheduleProps) {
        super(props)

        this.clearanceController = new ClearanceController();
    } 

    render() {
        const ManageScheduleData = withData(
            ManageSchedule,
            "Schedule",
            async () => await this.clearanceController.getSchedules(this.props.data),
            <ActionList isLoading={true} />
        )

        return <ManageScheduleData />
    }   
}