import { FormContainer, FormField, Alert, ValidationUtility } from "@mit/hui";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AlarmController, { AlarmContactsResponse } from "../../../api/AlarmController";
import HomeController from "../../../api/HomeController";
import { ResultModel } from "../../../api/models/CardholdersResponseModel";
import { PersonModel } from "../../../api/models/PersonModel";
import StringProvider from "../../../services/StringProvider";
import * as notificationActionCreator from "../../../ts/redux/actions/notification";

interface IContactInfoViewProps {
	alarmId: string;
	data: AlarmContactsResponse;
	actions?: any;
	roles: string[];
}

interface IContactInfoViewState {
	isReadOnlyAccount: boolean;
	isBusy: boolean;
	isBusySearching: boolean;
	isBusySearching2: boolean;
	isGettingProfile: boolean;
	isGettingProfile2: boolean;
	error: string;
	success: string;
	warning: string;
	cardholders: any;
	cardholders2: any;
	selectedContact1: PersonModel;
	selectedContact2: PersonModel;
}

const WAIT_INTERVAL = 700;
let timerID: any;
class ContactInfoView extends React.Component<IContactInfoViewProps, IContactInfoViewState> {
	alarmController: AlarmController;
	homeController: HomeController;
	stringProvider: StringProvider;

	constructor(props: IContactInfoViewProps) {
		super(props);

		this.alarmController = new AlarmController();
		this.homeController = new HomeController();
		this.stringProvider = new StringProvider();

		this.state = {
			isReadOnlyAccount: this.props.roles.includes('PSEC RO SUPER USER'),
			isBusy: false,
			isBusySearching: false,
			isBusySearching2: false,
			isGettingProfile: false,
			isGettingProfile2: false,
			error: "",
			success: "",
			warning: "",
			cardholders: [],
			cardholders2: [],
			selectedContact1: {
				display_name: this.props.data.contact_1,
				department: this.props.data.contact_1_department,
				email: this.props.data.contact_1_email,
				phone_number: this.props.data.contact_1_phone_1,
				phone_number_2: this.props.data.contact_1_phone_2,
				affiliation: "",
				first_name: "",
				kerberos_id: "",
				last_name: "",
				list_name: "",
				middle_initial: "",
				mit_id: "",
				office_location: "",
				on_sub_list: "",
			},
			selectedContact2: {
				display_name: this.props.data.contact_2,
				department: this.props.data.contact_2_department,
				email: this.props.data.contact_2_email,
				phone_number: this.props.data.contact_2_phone_1,
				phone_number_2: this.props.data.contact_2_phone_2,
				affiliation: "",
				first_name: "",
				kerberos_id: "",
				last_name: "",
				list_name: "",
				middle_initial: "",
				mit_id: "",
				office_location: "",
				on_sub_list: "",
			},
		};
	}

	getText(cardholder: PersonModel) {
		if (cardholder.display_name) return cardholder.display_name;

		if (cardholder.kerberos_id) return cardholder.kerberos_id;

		if (cardholder.mit_id) return cardholder.mit_id;

		return "";
	}

	getId(cardholder: PersonModel | ResultModel) {
		if (cardholder.kerberos_id) return cardholder.kerberos_id;

		if (cardholder.mit_id) return cardholder.mit_id;

		return "";
	}

	async onChange(person: PersonModel, contact: number) {
		if (contact === 1) {
			this.setState({ isGettingProfile: true });
		} else {
			this.setState({ isGettingProfile2: true });
		}

		this.homeController.getProfile(person.kerberos_id).then((response: any) => {
			if (contact === 1)
				this.setState({
					selectedContact1: response,
					isGettingProfile: false,
				});

			if (contact === 2)
				this.setState({
					selectedContact2: response,
					isGettingProfile2: false,
				});
		});
	}

	handleSearch = (query: string, element: React.KeyboardEvent<HTMLInputElement>): void => {
		clearTimeout(timerID);

		if (query.length < 1) {
			this.setState({ cardholders: [], isBusySearching: false, selectedContact1: {
				display_name: "",
				phone_number: "",
				phone_number_2: "",
				department: "",
				affiliation: "",
				email: "",
				first_name: "",
				mit_id: "",
				kerberos_id: "",
				last_name: "",
				list_name: "",
				middle_initial: "",
				office_location: "",
				on_sub_list: "",
			} });
			return;
		}

		//Check if backspace or delete
		if (element.keyCode === 8 || element.keyCode === 46) return;

		this.setState({ isBusySearching: true });

		timerID = setTimeout(() => {
			this.homeController.abortRequest();

			if (query.length < 1) {
				this.setState({ cardholders: [], isBusySearching: false });
				return;
			}

			this.homeController.getSearch(query).then((cardholders: any) => {
				//check if status not 200 (null is return)
				if (cardholders === null) {
					this.setState({ isBusySearching: false });
					return;
				}

				//Check if type error
				if (cardholders && cardholders.name === "TypeError") {
					this.setState({ isBusySearching: false });
					return;
				}

				//Check if request was aborted
				if (cardholders && cardholders.name !== "AbortError") {
					const newData = cardholders.map((itm: PersonModel) => {
						return {
							icon: "user",
							text: this.getText(itm),
							secondaryText: this.getId(itm),
							tertiaryText: this.stringProvider.capitalizeFirstLetter(itm.department),
							quaternaryText: itm.mit_id,
							onClick: () => this.onChange(itm, 1),
						};
					});

					this.setState({
						cardholders: newData,
						isBusySearching: false,
					});
				}
			});
		}, WAIT_INTERVAL);
	};

	handleSecondSearch = (query: string, element: React.KeyboardEvent<HTMLInputElement>): void => {
		clearTimeout(timerID);

		if (query.length < 1) {
			this.setState({ cardholders2: [], isBusySearching2: false, selectedContact2: {
				display_name: "",
				phone_number: "",
				phone_number_2: "",
				department: "",
				affiliation: "",
				email: "",
				first_name: "",
				mit_id: "",
				kerberos_id: "",
				last_name: "",
				list_name: "",
				middle_initial: "",
				office_location: "",
				on_sub_list: "",
			}});
			return;
		}

		//Check if backspace or delete
		if (element.keyCode === 8 || element.keyCode === 46) return;

		this.setState({ isBusySearching2: true });

		timerID = setTimeout(() => {
			this.homeController.abortRequest();

			if (query.length < 1) {
				this.setState({ cardholders2: [], isBusySearching2: false });
				return;
			}

			this.homeController.getSearch(query).then((cardholders: any) => {
				//check if status not 200 (null is return)
				if (cardholders === null) {
					this.setState({ isBusySearching2: false });
					return;
				}

				//Check if type error
				if (cardholders && cardholders.name === "TypeError") {
					this.setState({ isBusySearching2: false });
					return;
				}

				//Check if request was aborted
				if (cardholders && cardholders.name !== "AbortError") {
					const newData = cardholders.map((itm: PersonModel) => {
						return {
							icon: "user",
							text: this.getText(itm),
							secondaryText: this.getId(itm),
							tertiaryText: this.stringProvider.capitalizeFirstLetter(itm.department),
							quaternaryText: itm.mit_id,
							onClick: () => this.onChange(itm, 2),
						};
					});

					this.setState({
						cardholders2: newData,
						isBusySearching2: false,
					});
				}
			});
		}, WAIT_INTERVAL);
	};

	onSave(data: any, valid: boolean) {
		this.setState({ isBusy: true, error: "" });
		if (valid) {
			let body: AlarmContactsResponse = {
				contact_1: data.contact_1,
				contact_1_department: data.contact_1 ? this.state.selectedContact1.department : "",
				contact_1_email: data.contact_1_email,
				contact_1_phone_1: data.contact_1_phone_1,
				contact_1_phone_2: data.contact_1_phone_2,
				contact_2: data.contact_2,
				contact_2_department: data.contact_2 ? this.state.selectedContact2.department : "",
				contact_2_email: data.contact_2_email,
				contact_2_phone_1: data.contact_2_phone_1,
				contact_2_phone_2: data.contact_2_phone_2,
			};

			this.alarmController.postContacts(this.props.alarmId, body).then((response) => {
				this.setState({
					isBusy: false,
					selectedContact2: {
						display_name: data.contact_2,
						phone_number: data.contact_2_phone_1,
						phone_number_2: data.contact_2_phone_2,
						department: this.state.selectedContact2.department,
						affiliation: "",
						email: data.contact_2_email,
						first_name: "",
						mit_id: "",
						kerberos_id: "",
						last_name: "",
						list_name: "",
						middle_initial: "",
						office_location: "",
						on_sub_list: "",
					},
					selectedContact1: {
						display_name: data.contact_1,
						phone_number: data.contact_1_phone_1,
						phone_number_2: data.contact_1_phone_2,
						department: this.state.selectedContact1.department,
						affiliation: "",
						email: data.contact_1_email,
						first_name: "",
						mit_id: "",
						kerberos_id: "",
						last_name: "",
						list_name: "",
						middle_initial: "",
						office_location: "",
						on_sub_list: "",
					},
				});

				if (response) {
					this.setState({ success: "Contact info successfully updated" });
					this.props.actions.onContactSave();
					window.scrollTo(0, 0);

					setTimeout(() => {
						this.setState({ success: "" });
					}, 3000);
				}
			});
		} else {
			this.setState({ isBusy: false, error: "Please complete all the required info" });
			window.scrollTo(0, 0);
		}
	}

	renderAlert() {
		if (!this.state.selectedContact1.display_name && !this.props.roles.includes('PSEC RO SUPER USER')) return <Alert text={"Please add a primary contact."} type={"error"} />;

		if (!this.state.selectedContact2.display_name && !this.props.roles.includes('PSEC RO SUPER USER')) return <Alert text={"This area only has one contact. Please add a second."} type={"warning"} />;

		return <Fragment></Fragment>;
	}

	render() {
		return (
			<Fragment>
				{this.state.error && <Alert text={this.state.error} type={"error"} />}
				{this.state.success && <Alert text={this.state.success} type={"success"} />}
				{this.renderAlert()}
				{/* Contact Info 1 */}
				<FormContainer
					id="contactForm"
					actionIsBusy={this.state.isBusy}
					actionDisabled={this.state.isBusy || this.state.isReadOnlyAccount}
					formValidationTrigger={"onChange"}
					submitText="Save"
					action={(data, valid) => this.onSave(data, valid)}>
					<div className="card-entity col-xl-12 col-md-10">
						<div className="card card-entity-container p-4 mb-4">
							<div className="row mb-10">
								<div className="col-10">
									<FormField
										label=""
										value={this.state.selectedContact1.display_name}
										items={this.state.cardholders}
										labelDisplay="ontop"
										properties={{
											disabled: this.state.isReadOnlyAccount,
											onSearch: this.handleSearch,
											isBusy: this.state.isBusySearching,
											onClear: () =>
												this.setState({
													selectedContact1: {
														display_name: "",
														department: "",
														affiliation: "",
														email: "",
														phone_number: "",
														phone_number_2: "",
														first_name: "",
														kerberos_id: "",
														last_name: "",
														list_name: "",
														middle_initial: "",
														mit_id: "",
														office_location: "",
														on_sub_list: "",
													},
												}),
										}}
										editor="autocomplete"
										id="contact_1"
										required = {!this.props.roles.includes('PSEC RO SUPER USER')}
									/>
								</div>
							</div>
							<div className="row mb-3">
								<div className="col-10 m-1">{this.state.selectedContact1.department}</div>
							</div>
							<div className="row mb-10">
								<div className="col-6">
									<FormField
										label="Email"
										isBusy={this.state.isGettingProfile}
										value={this.state.selectedContact1.email}
										labelDisplay="nextto"
										editor="textbox"
										id="contact_1_email"
										properties={{ pattern: { value: ValidationUtility.emailPattern, message: "Email format" }, disabled: this.state.isReadOnlyAccount }}
										required = {!this.props.roles.includes('PSEC RO SUPER USER')}
									/>
								</div>
								<div className="col-6">
									<FormField
										label="Phone number #1"
										isBusy={this.state.isGettingProfile}
										value={this.state.selectedContact1.phone_number}
										labelDisplay="nextto"
										properties={{ pattern: { value: ValidationUtility.phonePattern, message: "Phone format" }, disabled: this.state.isReadOnlyAccount }}
										editor="textbox"
										id="contact_1_phone_1"
										required = {!this.props.roles.includes('PSEC RO SUPER USER')}
									/>
									<FormField
										label="Phone number #2"
										isBusy={this.state.isGettingProfile}
										value={this.state.selectedContact1.phone_number_2}
										labelDisplay="nextto"
										properties={{ pattern: { value: ValidationUtility.phonePattern, message: "Phone format" }, disabled: this.state.isReadOnlyAccount }}
										editor="textbox"
										id="contact_1_phone_2"
										required = {!this.props.roles.includes('PSEC RO SUPER USER')}
									/>
								</div>
							</div>
						</div>
					</div>
					{/* Contact Info 2 */}
					<div className="card-entity col-xl-12 col-md-10">
						<div className="card card-entity-container p-4 mb-4">
							<div className="row mb-10">
								<div className="col-10">
									<FormField
										label=""
										value={this.state.selectedContact2.display_name}
										items={this.state.cardholders2}
										labelDisplay="ontop"
										properties={{
											disabled: this.state.isReadOnlyAccount,
											onSearch: this.handleSecondSearch,
											isBusy: this.state.isBusySearching2,
											onClear: () =>
												this.setState({
													selectedContact2: {
														display_name: "",
														department: "",
														affiliation: "",
														email: "",
														phone_number: "",
														phone_number_2: "",
														first_name: "",
														kerberos_id: "",
														last_name: "",
														list_name: "",
														middle_initial: "",
														mit_id: "",
														office_location: "",
														on_sub_list: "",
													},
												}),
										}}
										editor="autocomplete"
										id="contact_2"
									/>
								</div>
							</div>
							<div className="row mb-3">
								<div className="col-10 m-1">{this.state.selectedContact2.department}</div>
							</div>
							<div className="row mb-10">
								<div className="col-6">
									<FormField
										label="Email"
										isBusy={this.state.isGettingProfile2}
										value={this.state.selectedContact2.email}
										labelDisplay="nextto"
										editor="textbox"
										id="contact_2_email"
										properties={{ pattern: { value: ValidationUtility.emailPattern, message: "Email format" }, disabled: this.state.isReadOnlyAccount }}
									/>
								</div>
								<div className="col-6">
									<FormField
										label="Phone number #1"
										isBusy={this.state.isGettingProfile2}
										value={this.state.selectedContact2.phone_number}
										labelDisplay="nextto"
										editor="textbox"
										id="contact_2_phone_1"
										properties={{ pattern: { value: ValidationUtility.phonePattern, message: "Phone format" }, disabled: this.state.isReadOnlyAccount }}
									/>
									<FormField
										label="Phone number #2"
										isBusy={this.state.isGettingProfile2}
										value={this.state.selectedContact2.phone_number_2}
										labelDisplay="nextto"
										editor="textbox"
										properties={{ pattern: { value: ValidationUtility.phonePattern, message: "Phone format" }, disabled: this.state.isReadOnlyAccount }}
										id="contact_2_phone_2"
									/>
								</div>
							</div>
						</div>
					</div>
				</FormContainer>
			</Fragment>
		);
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	actions: bindActionCreators(notificationActionCreator, dispatch),
});

const mapStateToProps = (state: any) => ({
	roles: state.app.roles
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactInfoView) ;
