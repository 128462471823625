import React, { FC } from 'react'

type AlertType = 'success' | 'info' | 'warning' | 'error'

export interface AlertProps {
  text: any
  type?: AlertType
  onClose?: () => void
  icon?: string
}

const Alert: FC<AlertProps> = props => {
  const { text, type, onClose, icon } = props

  const getClasses = (): string => {
    var classes: string[] = []
    if (type === 'success') classes.push('alert-success')
    else if (type === 'info') classes.push('alert-info')
    else if (type === 'warning') classes.push('alert-warning')
    else if (type === 'error') classes.push('alert-danger')

    return classes.join(' ')
  }

  return (
    <div className={`d-flex flex-nowrap align-items-stretch alert ${getClasses()}`} role='alert'>
      {!!icon && (
        <div className='alert-icon align-self-center'>
          <i aria-hidden={true} aria-label={`icon`} className={`fa fa-${icon} m-1'}`} role={'img'} />
        </div>
      )}
      <div className={`flex-grow-1 mx-2 align-self-center`}>{text}</div>
      {!!onClose && (
        <div className='alert-close-icon align-self-center'>
          <i
            aria-hidden={false}
            aria-label={`close alert`}
            className={`close-button fa fa-times m-1'}`}
            role={'button'}
            onClick={() => {
              onClose && onClose()
            }}
          />
        </div>
      )}
    </div>
  )
}

Alert.defaultProps = {
  type: 'info'
}

export default Alert
