import React, { Fragment } from "react";
import { TemplateModalSize, Text, TextType, Icon, ErrorContext, Error, ErrorType, Table, Button, ButtonType, TemplateModal, TemplateModalAlignment, Toast, ButtonState } from "@mit/hui";
import { bindActionCreators } from "redux";
import * as notificationActionCreator from "../ts/redux/actions/notification";
import appConfig from "../app-config";
import { NotificationModel } from "../ts/redux/reducers/notification";
import { connect } from "react-redux";
import { TableRowProps } from "@mit/hui/build/components/Table/TableRow";
import AuthProvider from "../services/AuthProvider";
import ClearanceController from "../api/ClearanceController";
import { uuidv4 } from "../ts/redux/actions/notification";
import Loader from "react-loader";
import AlarmController from "../api/AlarmController";
//@ts-ignore
import ReactExport from "react-export-excel";
import { NotificationHandler } from "./NotificationHandler";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

interface NotificationModalProps {
	data: NotificationModel;
	actions?: any;
}

interface NotificationModalState {
	show: boolean;
	error: boolean;
	status: string;
	recordsData: any;
	complete: boolean;
}

class NotificationModal extends React.Component<NotificationModalProps, NotificationModalState> {
	clearanceController: ClearanceController;
	alarmController: AlarmController;
	authProvider: AuthProvider;

	constructor(props: NotificationModalProps) {
		super(props);

		this.clearanceController = new ClearanceController();
		this.alarmController = new AlarmController();
		this.authProvider = new AuthProvider();

		this.state = {
			show: false,
			complete: false,
			error: false,
			status: "Your file is being uploaded",
			recordsData: [],
		};
	}

	async componentDidMount() {
		const token = await this.authProvider.getToken();
		let client = new WebSocket(`${appConfig.websocketUrl}?token=${token}`);

		client.onopen = () => {
			let recordData: any = {
				cardholders: [],
			};

			this.props.data.data &&
				// eslint-disable-next-line
				this.props.data.data.map((itm: Array<string>) => {

					let kerbId = null;
					//If value is not null and is not a number
					if(!(itm[0] === null) && isNaN(+itm[0])) {
					  kerbId = itm[0].toLowerCase();
					}

					let record = {
						record_id: uuidv4(), 
						kerberos_id: kerbId,
						mit_id: itm && itm[1],
					};

					recordData.cardholders.push(record);
				});

			if (this.props.data.operation === "add-clearance") {
				this.clearanceController.postBulkAdditions(this.props.data.clearanceId, recordData).then((response) => {
					if (response) {

						this.processServerResponse(response.data, client, this.props.data.clearanceId, this.props.data.operation)

						let checkComplete = response.data.find((itm: any) => itm.message === "Operation queued for processing");
						var today = new Date();
						var time = today.getHours() + ":" + today.getMinutes();

						if (!checkComplete) {
							this.props.actions.addNotificationCount();
							this.props.actions.addCompletedNotification(uuidv4(), "Completed", time, response.data, this.props.data.clearanceId, this.props.data.operation);
							client.close();
						}

						this.setState({ status: "Records are being processed in the queue.", recordsData: response.data });
					} else {
						this.setState({ error: true });
						client.close();
					}
				});
			}

			if (this.props.data.operation === "remove-clearance") {
				this.clearanceController.deleteBulkRemovals(this.props.data.clearanceId, recordData).then((response) => {
					if (response) {

						this.processServerResponse(response.data, client, this.props.data.clearanceId, this.props.data.operation)
						
						let checkComplete = response.data.find((itm: any) => itm.message === "Operation queued for processing");
						var today = new Date();
						var time = today.getHours() + ":" + today.getMinutes();

						if (!checkComplete) {
							this.props.actions.addNotificationCount();
							this.props.actions.addCompletedNotification(uuidv4(), "Completed", time, response.data, this.props.data.clearanceId, this.props.data.operation);
							client.close();
						}

						this.setState({ status: "Records are being processed in the queue.", recordsData: response.data });
					} else {
						this.setState({ error: true });
						client.close();
					}
				});
			}

			if (this.props.data.operation === "add-alarm") {
				this.alarmController.postBulkAdditions(this.props.data.clearanceId, recordData).then((response) => {
					if (response) {
						this.processServerResponse(response.data, client, this.props.data.clearanceId, this.props.data.operation)

						let checkComplete = response.data.find((itm: any) => itm.message === "Operation queued for processing");
						var today = new Date();
						var time = today.getHours() + ":" + today.getMinutes();

						if (!checkComplete) {
							this.props.actions.addNotificationCount();
							this.props.actions.addCompletedNotification(uuidv4(), "Completed", time, response.data, this.props.data.clearanceId, this.props.data.operation);
							client.close();
						}

						this.setState({ status: "Records are being processed in the queue.", recordsData: response.data });
					} else {
						this.setState({ error: true });
					}
				});
			}

			if (this.props.data.operation === "remove-alarm") {
				this.alarmController.deleteBulkRemovals(this.props.data.clearanceId, recordData).then((response) => {
					if (response) {
						this.processServerResponse(response.data, client, this.props.data.clearanceId, this.props.data.operation)

						let checkComplete = response.data.find((itm: any) => itm.message === "Operation queued for processing");
						var today = new Date();
						var time = today.getHours() + ":" + today.getMinutes();

						if (!checkComplete) {
							this.props.actions.addNotificationCount();
							this.props.actions.addCompletedNotification(uuidv4(), "Completed", time, response.data, this.props.data.clearanceId, this.props.data.operation);
							client.close();
						}

						this.setState({ status: "Records are being processed in the queue.", recordsData: response.data });
					} else {
						this.setState({ error: true });
					}
				});
			}
		};
		
		client.onerror = (error) => {
			console.log(error);
		};
	}

	async processServerResponse(response: any, client: WebSocket, clearanceId: string, operation: string) {
		let notificationHandler = new NotificationHandler(response, client, clearanceId, operation)

		notificationHandler.Start();

		notificationHandler.onOperationPerformed(event => {
			console.log("My handler: ",event)

			let operationProgress = event.operationData;
			this.setState({ recordsData: operationProgress });

			if (operationProgress && operationProgress.length > 0) {
				//Check if any operation is still ongoing
				let processingRecord = operationProgress.find((itm: any) => itm.message === "Operation queued for processing");

				if (!processingRecord) {
					const today = new Date();
					const time = today.getHours() + ":" + today.getMinutes();

					this.props.actions.addNotificationCount();
					this.props.actions.addCompletedNotification(uuidv4(), "Completed", time, operationProgress, this.props.data.clearanceId, this.props.data.operation);
					client.close();
				}
			}
		})
	}

	render() {
		const iconData: any = {
			type: "regular",
			icon: "bell",
			padded: true,
		};

		if (this.state.error)
			return <Toast icon={iconData} message="Something went wrong" onClose={() => this.props.actions.closeNotification(this.props.data.id)} show={true} title={this.props.data.title} />;

		let toastItem = {
			icon: iconData,
			title: this.props.data.title,
			subtext: this.props.data.clearanceId.replace("gsc-clearance-", "").replace("gsc-alarm-", ""),
			message: this.state.status,
			show: this.props.data.show,
			footer: (
				<Button
					onClick={() => {
						this.setState({ show: true });
					}}
					state={this.state.status === "Your file is being uploaded" ? ButtonState.Disabled : ButtonState.Enabled}
					isBusy={this.state.status === "Your file is being uploaded" ? true : false}
					icon="chevron-right"
					text="View list"
					type={ButtonType.Primary | ButtonType.Ghost}
				/>
			),
			onClose: () => {
				this.props.actions.closeNotification(this.props.data.id);
			},
		};

		const tableItems: TableRowProps[] = [];

		this.state.recordsData.length > 0 &&
			this.state.recordsData.map((itm: any) => {
				const data: TableRowProps = {
					items: [
						itm.kerberos_id ? itm.kerberos_id : itm.mit_id,
						itm.message === "Operation queued for processing" ? (
							<div className="p-1">
								<Loader
									loaded={false}
									className={"spinner"}
									position={"relative"}
									scale={0.4}
									radius={15}
									corners={1}
									color={"#000"}
									opacity={0.25}
									rotate={0}
									direction={1}
									speed={1}
									trail={60}
								/>
							</div>
						) : itm.success ? (
							<div className="text-center">
								<Icon icon="check" color="green" type="solid" />
							</div>
						) : (
							<div className="text-center">
								<Icon icon="times" color="red" type="solid" />
							</div>
						),
						itm.message,
					],
					state: "",
				};

				tableItems.push(data);

				return null;
			});

		let today = new Date();
		let date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

		return (
			<Fragment>
				<Toast {...toastItem} />
				<TemplateModal
					bodyAlignment={TemplateModalAlignment.Left}
					show={this.state.show}
					onClose={() => this.setState({ show: false })}
					padded={false}
					size={TemplateModalSize.Large}
					body={
						tableItems.length > 0 ? (
							<Table columns={3} header={["ID", "Status", "Description"]} rows={tableItems} type={""} flush={true} />
						) : (
							<Error context={ErrorContext.Component} message={"Empty request data uploaded"} type={ErrorType.NoData} />
						)
					}
					footer={
						<ExcelFile filename={"Cardholders_" + date} element={<Button type={ButtonType.IconNaked} icon={"download"} text={"export"} />}>
							<ExcelSheet data={this.state.recordsData} name={this.props.data.clearanceId}>
								<ExcelColumn label="Kerberos ID" value={(col: any) => col.kerberos_id} />
								<ExcelColumn label="MIT ID" value={(col: any) => col.mit_id} />
								<ExcelColumn label="Success" value={(col: any) => col.success} />
								<ExcelColumn label="Message" value={(col: any) => col.message} />
							</ExcelSheet>
						</ExcelFile>
					}
					header={<Text content={`Cardholder(s) Result`} type={TextType.Heading4} icon="" />}
					name={"popupNotification" + this.props.data.id}
				/>
			</Fragment>
		);
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	actions: bindActionCreators(notificationActionCreator, dispatch),
});

const mapStateToProps = (state: any) => ({
	notifications: state.notification.notifications,
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationModal);