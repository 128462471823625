import React from 'react';
import { TemplateTwoColumns, ActionList } from '@mit/hui';
import { connect } from 'react-redux';
import ClearanceController from '../../api/ClearanceController';
import { ResponseModal } from '../../api/models/ResponseModel';
import { withData } from './components/WithData';
import AccessManagementView from './components/AccessManagementView';

interface ClearanceCardholderGroups {
    groups: string[],
    managedGroup: string;
}

interface ClearanceAccessManagementState {
    isLoading: boolean;
    isLoadingGroups: boolean;
    error: boolean;
    canManage: boolean;
    cardholderGroups: ClearanceCardholderGroups;
}

class ClearanceAccessManagement extends React.Component<any, ClearanceAccessManagementState>
{
    clearanceController: ClearanceController;

    constructor(props: any) {
        super(props)

        this.clearanceController = new ClearanceController();
    }

    shouldComponentUpdate(nextProps: any, nextState: ClearanceAccessManagementState) {

        let shouldUpdate = false;
        if (JSON.stringify(nextProps) !== JSON.stringify(this.props)) {
            shouldUpdate = true;
        }

        if (nextState !== this.state) {
            shouldUpdate = true;
        }

        return shouldUpdate;
    }

    async getData(): Promise<ResponseModal> {
        let promise1 = this.clearanceController.getClearanceCardholderGroupsAndStatus(this.props.match.params.id);
        let promise2 = this.clearanceController.getClearanceBasicInfo(this.props.match.params.id);

        let data = await Promise.all([promise1,promise2]);
        let responseCode = 200;

        if(data[0].status === 403 || data[1].status === 403) {
            responseCode = 403;
        }

        let response = {
            data: {...data[0].data, ...data[1].data},
            status: responseCode
        }

        return response;
    }

    render() {
        const ManageAccessView = withData(
            AccessManagementView,
            "Access Management",
            async () => this.getData(),
            <TemplateTwoColumns
                padded={true}
                showDivider={true}
                showGutters={false}
                leftArea={<ActionList isLoading={true} />}
                rightArea={<ActionList isLoading={true} />}
                primarySize={6}
                leftAlignment={"left"}
                rightAlignment={"left"}
            />
        );
        return <ManageAccessView clearanceId = {this.props.match.params.id}/>
    }
}

const mapStateToProps = (state: any) => ({
    clearance: state.clearance.clearance,
});

export default connect(mapStateToProps)(ClearanceAccessManagement);