import React from 'react';
import { ContactCard } from '@mit/hui';
import HomeController from '../../../api/HomeController'
import StringProvider from '../../../services/StringProvider';

interface UserProfileProps {
    data: any;
}

export default class UserProfile extends React.Component<UserProfileProps> 
{
    homeController: HomeController;
    stringProvider: StringProvider;

    constructor(props: any) {
        super(props)

        this.homeController = new HomeController();
        this.stringProvider = new StringProvider();

    }

    render()
    {
        return (<>
            <ContactCard  
                name={`${this.props.data.user.first_name} ${this.props.data.user.last_name}`} 
                isPerson={false} 
                profilePicIcon={this.props.data.profilePicture}                
                affiliation={this.stringProvider.capitalizeFirstLetter(this.props.data.user.affiliation)}
                department={this.stringProvider.capitalizeFirstLetter(this.props.data.user.department)} 
                telephone={this.stringProvider.formatPhoneNumber(this.props.data.user.phone_number)} 
                email={this.props.data.user.email} 
                // isLoading={this.state.isLoading}
            />
        </>)
    }
}