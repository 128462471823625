import { BuildingsModel } from "./models/BuildingsModel";
import { DoorsModel } from "./models/DoorsModel";
import { ElevatorsModel } from "./models/ElevatorsModel";
import BaseController from "./BaseController";

class AreaController extends BaseController
{
    //GET

    getBuildings = async (): Promise<BuildingsModel> =>
    {
        return await this.fetchData(this.baseUrl, "/areas/buildings")
    }

    getDoors = async (building_id: string, clearanceId: string): Promise<DoorsModel> =>
    {
        return await this.fetchData(this.baseUrl, "/areas/buildings/" + building_id + "/doors", { clearance_id: clearanceId })
    }

    getElevator = async (building_id: string, clearanceId: string): Promise<ElevatorsModel> =>
    {
        return await this.fetchData(this.baseUrl, "/areas/buildings/" + building_id + "/elevators", { clearance_id: clearanceId })
    }

    getFloors = async (building_id: string, elevator_id: string): Promise<ElevatorsModel> =>
    {
        return await this.fetchData(this.baseUrl, "/areas/buildings/" + building_id + "/elevators/" + elevator_id + "/floors")
    }
}

export default AreaController;