import React, { FC, Fragment, useEffect, useState } from 'react'
import { TimePicker } from './TimePicker'
import { Button, ButtonType, GridContainer, LayoutColumn, Spacer, Text, TextType } from '@mit/hui'
import AlertTimer from '../../../common/AlertTimer'

interface TimeRanges {
  times: MultiTimeRange[]
  maxTimes?: number
  name?: string
  disabled?: boolean
  setTimes: any
  setErrorMessage: any
}

export interface MultiTimeRange {
  start: string
  end: string
}

export const initTime: MultiTimeRange = {
  start: '08:00',
  end: '12:00'
}

export const init2Time: MultiTimeRange = {
  start: '13:00',
  end: '17:00'
}

export function validateTimeRanges (timeRanges: MultiTimeRange[], setErrorMessage: any) {
  setErrorMessage('')

  // Sort the time ranges based on the start time
  timeRanges.sort((a, b) => a.start.localeCompare(b.start))

  // Checking for overlaps and adjusting end times
  for (let i = 0; i < timeRanges.length; i++) {
    const currentRange = timeRanges[i]
    const nextRange = timeRanges.length > 1 ? timeRanges[i + 1] : undefined

    const currentValidation = validateTime(currentRange.start, currentRange.end)

    if (!currentValidation.isValid) {
      setErrorMessage(currentValidation.message)
      return false
    }
    if (nextRange) {
      const nextValidation = validateTime(nextRange.start, nextRange.end)

      if (!nextValidation.isValid) {
        setErrorMessage(nextValidation.message)
        return false
      }

      // Check for overlaps
      if (currentRange.end > nextRange.start) {
        setErrorMessage('Please enter non-overlapping time ranges.')
        return false
      }
    }
  }

  return true
}

export function validateTime (startTime: string, endTime: string): { isValid: boolean; message: string } {
  let isValid = true
  let message = ''

  // Invalid date
  if (!isValidTime(startTime) || !isValidTime(endTime)) {
    isValid = false
    message = 'Please enter a valid 24-hour time range.'
  }

  if (startTime > endTime) {
    isValid = false
    message = 'Please enter valid end times that are later than the start times.'
  }

  return {
    isValid: isValid,
    message: message
  }
}

// Helper function to check if the input time is in 24-hour format
function isValidTime (time: string) {
  const timeRegex = /^(?:[01]\d|2[0-3]):[0-5]\d$/
  if (timeRegex.test(time)) {
    const [hours, minutes] = time.split(':').map(Number)
    return hours < 24 && minutes < 60
  }
  return false
}

export function getReverseToggleChoice (state: any) {
  let choice = state?.SWITCH_STATE?.choice !== undefined ? state?.SWITCH_STATE?.choice : state
  return choice === 'Positive'
}

export function getToggleChoice (state: boolean) {
  if (state === false) {
    return 'Negative'
  } else if (state === true) {
    return 'Positive'
  } else {
    return 'Negative'
  }
}

export const MultiTimeRanges: FC<TimeRanges> = props => {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [timeRanges, setTimeRanges] = useState<MultiTimeRange[]>(props.times ?? [initTime, initTime])
  const [name, setName] = useState<string | undefined>(undefined)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [maxTimes, setMaxTimes] = useState<number|undefined>()

  useEffect(() => {
    setTimeRanges([...props.times])
    setName(props.name)
    setDisabled(props.disabled ?? false)
    setMaxTimes(props.maxTimes)
    if(props.maxTimes && props.maxTimes < timeRanges.length) {
      setErrorMessage(`Can only have a max of ${props.maxTimes} time ranges`)
    }
  }, [props])

  function onTimeRangeChange (timeRangeIndex: number, timeRangeType: 'start' | 'end', value: string) {
    setTimeRanges(prevTimeRanges => {
      const newArray = [...prevTimeRanges]
      newArray[timeRangeIndex][timeRangeType] = formatTimeString(value)
      validateTimeRanges(newArray, setErrorMessage)
      props.setTimes(name, newArray)
      return newArray
    })
  }

  function onAddTimeRange () {
    if( maxTimes && maxTimes <= timeRanges.length ) {
      setErrorMessage(`Can only have a max of ${maxTimes} time ranges`)
      setTimeout(() => {
        setErrorMessage('')
      }, 3000)
      return
    }

    setTimeRanges(prevTimeRanges => {
      const tempTimeRanges = [...prevTimeRanges]
      const lastValue = tempTimeRanges[tempTimeRanges.length - 1]
      if (lastValue === undefined) {
        tempTimeRanges.push(initTime)
      } else {
        const prevEndValue = lastValue.end
        const startValue = Number(prevEndValue.split(':')[0]) + 1
        const endValue = startValue + 1
        const formatStartValue = formatTimeString(String(startValue + ':00'))
        const formatEndValue = formatTimeString(String(endValue + ':00'))

        if(!isValidTime(formatStartValue) || !isValidTime(formatEndValue)) {
          setErrorMessage(`Can only have a max of 24 hours`)
          setTimeout(() => {
            setErrorMessage('')
          }, 3000)
          return prevTimeRanges
        }
        tempTimeRanges.push({
          start: formatStartValue,
          end: formatEndValue
        })
      }

      validateTimeRanges(tempTimeRanges, setErrorMessage)
      props.setTimes(name, tempTimeRanges)
      return tempTimeRanges
    })
  }

  function formatTimeString (unformattedTime: string) {
    const [timeString, amOrPm] = unformattedTime.split(' ')

    let [hour, minute] = timeString.split(':')
    if (amOrPm === 'PM') {
      hour = (parseInt(hour) + 12).toString()
    }

    return `${('0' + hour).slice(-2)}:${minute}`
  }

  function onRemoveTimeRange (timeRangeIndex: number) {
    setTimeRanges(prevTimeRanges => {
      const newArray = [...prevTimeRanges]
      newArray.splice(timeRangeIndex, 1)
      validateTimeRanges(newArray, setErrorMessage)
      props.setTimes(props.name, newArray)
      return newArray
    })
  }

  function buildTimeEntryComponent () {
    const tempName = name ? name + '-' : ''
    return timeRanges.map((timeRange, index) => {
      return (
        <Fragment key={name + '-' + index + '-timeRanges'}>
          <div className='time-div'>
            <GridContainer>
              <LayoutColumn colSize={5}>
                <GridContainer>
                  <LayoutColumn colSize={5} alignment={'left'}>
                    <Spacer size='1' />
                    <Text content='Valid From' type={TextType.Body} icon='' />
                  </LayoutColumn>
                  <LayoutColumn colSize={6} alignment={'left'}>
                    <TimePicker
                      minInterval = {'15min'}
                      disabled={disabled}
                      name={'time-picker-from-' + tempName + index}
                      value={timeRange.start}
                      onChange={(e: any) => {
                        onTimeRangeChange(index, 'start', e)
                      }}
                    />
                  </LayoutColumn>
                </GridContainer>
              </LayoutColumn>
              <LayoutColumn colSize={3}>
                <GridContainer>
                  <LayoutColumn colSize={2} alignment={'left'}>
                    <Spacer size='1' />
                    <Text content='To' type={TextType.Body} icon='' />
                  </LayoutColumn>
                  <LayoutColumn colSize={4} alignment={'left'}>
                    <TimePicker
                      minInterval = {'15min'}
                      disabled={disabled}
                      name={'time-picker-to-' + tempName + index}
                      value={timeRange.end}
                      onChange={(e: any) => {
                        onTimeRangeChange(index, 'end', e)
                      }}
                    />
                  </LayoutColumn>
                </GridContainer>
              </LayoutColumn>
              <LayoutColumn alignment='left' colSize={1}>
                <Spacer size='1' />
                <Button state={disabled ? 1 : 0} icon='trash' type={ButtonType.IconNaked} padded={false} onClick={() => onRemoveTimeRange(index)} />
              </LayoutColumn>
            </GridContainer>
          </div>
        </Fragment>
      )
    })
  }

  return (
    <Fragment>
      <AlertTimer message={errorMessage} />
      {buildTimeEntryComponent()}
      <GridContainer>
        <LayoutColumn colSize={12}>
          <Button state={disabled ? 1 : 0} icon='plus' text='Add another time range' type={ButtonType.TextNaked} onClick={() => onAddTimeRange()} />
        </LayoutColumn>
      </GridContainer>
    </Fragment>
  )
}
