import * as types from "../constants/ActionTypes";
import { ProfileModel } from "../../../api/models/ProfileModel";

//Actions
export function userChange(response: any) 
{
    return {
        type: types.CHANGE_USER,
        payload: response
    };
}

export function pictureChange(response: any) 
{
    return {
        type: types.CHANGE_PICTURE,
        payload: response
    };
}

export function rolesChange(response: any) 
{
    return {
        type: types.CHANGE_ROLES,
        payload: response
    };
}
export function featureChange(response: any)
{
    return {
        type: types.CHANGE_FEATURES,
        payload: response
    };
}

//Dispatches
export function changeUser(user: ProfileModel) 
{
    return (dispatch: any) => 
    {
        dispatch(userChange(user));
    };
}

export function changePicture(picture: string) 
{
    return (dispatch: any) => 
    {
        dispatch(pictureChange(picture));
    };
}

export function changeRoles(roles: string[]) 
{
    return (dispatch: any) => 
    {
        dispatch(rolesChange(roles));
    };
}

export function changeFeatures(features: string[])
{
    return (dispatch: any) =>
    {
        dispatch(featureChange(features));
    };
}

