import React, { Fragment } from 'react';
import { Text, TextType, Spacer } from '@mit/hui';
import { withComponent } from '../../../common/WithComponent';
//@ts-ignore
import MediaQuery from 'react-responsive';
import SpecificAddition from './SpecificAddition';
import BulkAddition from './BulkAddition';

interface IPeopleAddProps
{
    alarmId: string;
}

export default class AlarmPeopleAdd extends React.Component<IPeopleAddProps>
{
    render()
    {
        const BulkAdditionData = withComponent(
            BulkAddition
        );

        const SpecificAdditionData = withComponent(SpecificAddition);
        
        return <Fragment>
            <Text content="Add people" padded={true} type={TextType.Heading4} icon='user-plus' />
            <Spacer size="2" />
            <SpecificAdditionData alarmId={this.props.alarmId} />
            <MediaQuery minWidth={577}>
                <Spacer size="3" />
                <BulkAdditionData alarmId={this.props.alarmId} />
            </MediaQuery>
            <Spacer size="2" />
        </Fragment>
    }
}