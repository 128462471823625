import React, { FC, Fragment, useEffect, useState } from 'react'
import { ActionList, Error, ErrorContext, ErrorType, Spacer, Text, TextType } from '@mit/hui'
import DailyScheduleForm, { DailyScheduleObject, initDailyScheduleObject } from './components/DailyScheduleForm'
import ScheduleController from '../../api/ScheduleController'
import WeeklyScheduleForm, { initWeeklyScheduleObject, IWeekDay, WeeklyScheduleObject } from './components/WeeklyScheduleForm'
import SpecificScheduleForm, { DateRanges, initSpecificScheduleObject, SpecificScheduleObject } from './components/SpecificScheduleForm'
import { MultiTimeRange } from './components/MultiTimeRanges'
import Alert from '../clearances/components/AlertComponent'
import moment from 'moment'

const ManageSchedule: FC<any> = props => {
  const scheduleController = new ScheduleController()

  const [scheduleFrequency, setScheduleFrequency] = useState<string>('Daily')
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [dailySchedule, setDailySchedule] = useState<DailyScheduleObject>(initDailyScheduleObject)
  const [weeklySchedule, setWeeklySchedule] = useState<WeeklyScheduleObject>(initWeeklyScheduleObject)

  const [specificSchedule, setSpecificSchedule] = useState<SpecificScheduleObject>(initSpecificScheduleObject)

  function checkScheduleFrequency (schedule: any) {
    if (Array.isArray(schedule?.daily) && schedule?.daily.length > 0) {
      return 'Daily'
    }

    if (schedule?.weekly != null) {
      return 'Weekly'
    }

    if (schedule?.specific != null) {
      return 'Specific'
    }

    return ''
  }

  function removeSeconds (timeString: string) {
    if (timeString.length === 8) {
      return timeString.substring(0, 5)
    } else {
      return timeString
    }
  }

  function processDailySchedule (schedules: any) {
    let allDayCheck = false
    if (schedules.daily[0].startTime === '00:00:00' && schedules.daily[0].endTime === '24:00:00') {
      allDayCheck = true
    }
    try {
      let times: MultiTimeRange[] = schedules.daily.map((day: { startTime: string; endTime: string }) => {
        return {
          start: removeSeconds(day.startTime),
          end: allDayCheck || day.endTime === '24:00:00' ? '23:59' : removeSeconds(day.endTime)
        }
      })
      setDailySchedule({
        times: times,
        allDay: allDayCheck,
        comment: schedules.comment,
        lastUpdatedBy: schedules.lastUpdatedBy
      })
    } catch (e) {
      console.log('empty array', e)
    }
  }

  function processWeeklySchedule (schedules: any) {
    try {
      let weekdays: IWeekDay[] = schedules.weekly.map((weekday: { weekDay: string; ranges: any[] }) => {
        let allDayCheck = false
        if (weekday.ranges.length !== 0) {
          if (weekday.ranges[0].startTime === '00:00:00' && weekday.ranges[0].endTime === '24:00:00') {
            allDayCheck = true
          }
        }
        return {
          weekDayType: weekday.weekDay,
          weekDayChecked: weekday.ranges.length > 0 ?? false,
          allDay: allDayCheck,
          times: weekday.ranges.map((range: { startTime: string; endTime: string }) => {
            return {
              start: removeSeconds(range.startTime),
              end: allDayCheck || range.endTime === '24:00:00' ? '23:59' : removeSeconds(range.endTime)
            }
          })
        }
      })
      setWeeklySchedule({
        weekDays: weekdays,
        comment: schedules.comment,
        lastUpdatedBy: schedules.lastUpdatedBy
      })
    } catch (e) {
      console.log('empty array', e)
    }
  }

  function processSpecificSchedule (schedules: any) {
    try {
      let dates: DateRanges[] = schedules.specific.map((day: any) => {
        let allDayCheck = false
        if (day.range[0].startTime === '00:00:00' && day.range[0].endTime === '24:00:00') {
          allDayCheck = true
        }
        let date = moment(day.date, 'YYYY/MM/DD')

        return {
          date: date.format('YYYY-MM-DD'),
          allDay: allDayCheck,
          times: day.range.map((range: any) => {
            return {
              start: removeSeconds(range.startTime),
              end: allDayCheck || range.endTime === '24:00:00' ? '23:59' : removeSeconds(range.endTime)
            }
          })
        }
      })
      setSpecificSchedule({
        dateRanges: dates.sort((a, b) => a.date.localeCompare(b.date)),
        comment: schedules.comment,
        lastUpdatedBy: schedules.lastUpdatedBy
      })
    } catch (e) {
      console.log('empty array', e)
    }
  }

  function processSchedule (schedules: any, frequency: string) {
    switch (frequency) {
      case 'Daily':
        processDailySchedule(schedules)
        break
      case 'Weekly':
        processWeeklySchedule(schedules)
        break
      case 'Specific':
        processSpecificSchedule(schedules)
        break
    }
  }

  function getScheduleComponent (scheduleFrequency: string) {
    switch (scheduleFrequency) {
      case 'Daily':
        return <DailyScheduleForm scheduleId={props.match.params.id} schedule={dailySchedule} scheduleString={scheduleFrequency} onScheduleTypeChange={onScheduleTypeChange} />
      case 'Weekly':
        return <WeeklyScheduleForm scheduleId={props.match.params.id} schedule={weeklySchedule} scheduleString={scheduleFrequency} onScheduleTypeChange={onScheduleTypeChange} />
      case 'Specific':
        return (
          <SpecificScheduleForm scheduleId={props.match.params.id} schedule={specificSchedule} scheduleString={scheduleFrequency} onScheduleTypeChange={onScheduleTypeChange} />
        )

      default:
        return <Error context={ErrorContext.Component} message='Please select a another schedule, this type of schedule is not supported' type={ErrorType.FirstAction} />
    }
  }

  function onScheduleTypeChange (scheduleType: string) {
    setScheduleFrequency(scheduleType)
  }

  useEffect(() => {
    setIsLoading(true)
    scheduleController.getScheduleRanges(props.match.params.id).then(schedules => {
      let schedFreq = checkScheduleFrequency(schedules?.schedules)
      processSchedule(schedules?.schedules, schedFreq)
      setScheduleFrequency(schedFreq)
      setIsLoading(false)
    })
  }, [props.match.params.id])

  return (
    <Fragment>
      <div id={'scrollTo'}></div>
      <Text content='Update schedule' type={TextType.Heading4} icon='' padded={true} />
      <Spacer size='2' />
      <Alert icon='info-circle' text={<span>If you need to create, modify, or edit an access control or unlock schedule, please contact us at <a href="mailto:physicalsecurity@mit.edu">physicalsecurity@mit.edu</a>.</span>} />
      <Spacer size='2' />
      {isLoading ? <ActionList isLoading items={[]} /> : <Fragment>{getScheduleComponent(scheduleFrequency)}</Fragment>}
    </Fragment>
  )
}

export default ManageSchedule
