import React from 'react'
import { FilteredList, TextboxType, Error, ErrorContext, ErrorType, ListItemState } from '@mit/hui'
import { FilteredListLoader } from '../../common/FilteredListLoader'
import History from '../../common/History'
import ScheduleController from '../../api/ScheduleController'

interface ScheduleMasterProps {
  onclick: any
  clearanceId: string
}

interface ScheduleMasterState {
  schedules: any
  isLoading: boolean
  isError: boolean
  isEmpty: boolean
}

export default class ScheduleMaster extends React.Component<ScheduleMasterProps, ScheduleMasterState> {
  scheduleController: ScheduleController

  constructor (props: ScheduleMasterProps) {
    super(props)

    this.scheduleController = new ScheduleController()

    this.state = {
      schedules: [],
      isLoading: true,
      isError: false,
      isEmpty: false
    }
  }

  componentWillUnmount () {
    this.scheduleController.abortRequest()
  }

  async componentDidMount () {
    try {
      const scheduleData = await this.scheduleController.getSchedules()

      if (scheduleData.schedules && scheduleData.schedules.length > 0) {
        const scheduleList = scheduleData.schedules.map((item: { id: string; name: string }) => {
          const listObitem = {
            icon: 'id-card',
            text: item.name,
            id: item.id,
            state: ListItemState.None,
            onClick: () => this.onChange(item.id, item.name)
          }

          return listObitem
        })

        this.setState({
          schedules: scheduleList,
          isLoading: false
        })
      }

      // Empty response
      if (scheduleData.schedules && scheduleData.schedules.length === 0) {
        this.setState({
          schedules: [],
          isLoading: false
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  generateURL (itm: string) {
    const path = window.location.pathname

    if (path === '/' || path === '/schedules/schedule-information') {
      return '/schedules/schedule-information/' + itm
    } else if (path === '/schedules/access-management') {
      return '/schedules/access-management/' + itm
    } else if (path === '/schedules/gatekeepers') {
      return '/schedules/gatekeepers/' + itm
    } else if (path === '/schedules/departments') {
      return '/schedules/departments/' + itm
    } else if (path === '/schedules/manage') {
      return '/schedules/manage/' + itm
    } else {
      const pathParts = window.location.pathname.split('/')

      pathParts.pop()
      pathParts.push(itm)
      return pathParts.join('/')
    }
  }

  onChange = (id: string, name: string): void => {
    this.props.onclick(id, name)

    const newPath = this.generateURL(id)
    History.push(newPath)
  }

  emptyData = {
    startIcon: 'search',
    startIconAccessibilityText: 'search',
    placeholderText: 'Filter my Schedules',
    type: TextboxType.IconStart,
    name: 'scheduleFilter'
  }

  render () {
    if (this.state.isLoading) return <FilteredListLoader text='Loading your schedules' />

    if (this.state.isError) return <Error context={ErrorContext.Component} message='No data available for schedules' type={ErrorType.NoData} />

    if (this.state.schedules.length > 0) {
      return (
        <FilteredList
          name='filteredListSchedule'
          loadingText=''
          isLoading={false}
          selectable={true}
          height={60}
          compact={true}
          searchOptions={this.emptyData}
          items={this.state.schedules}
        />
      )
    } else {
      return <Error message={'No Schedules available'} context={ErrorContext.Component} type={ErrorType.NoData} />
    }
  }
}
