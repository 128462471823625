export const Departments = [
  'D_ALL - All Departments',
  'D_COLLEGE_COMPU - Stephen A.Schwarzman College of Computing',
  'D_CCSE - Center for Computational Science and Engineering',
  'D_CSAIL - Computer Sciences and Artificial Intelligence Lab',
  'D_DEANCOMP - Dean School of Computing',
  'D_IDSS - Institute for Data, Systems, and Society',
  'D_JCLINIC - JCCLINIC',
  'D_LIDS - Laboratory for Information & Decision Systems',
  'D_MITIBMAI - MIT IBM AI LABORATORY',
  'D_QUEST - MIT Quest for Intelligence',
  'D_SERC - Social & Ethical Responsibilities in Computing',
  'D_SSRC - Sociotechnical Systems Research Center',
  'D_TPP - Technology & Policy Program',
  "D_EXECVP_AREA - Executive Vice President's Area",
  'D_ALUM - Alumni Association',
  'D_ATLAS - ATLAS SERVICE CENTER',
  'D_AUDIT - Audit Division',
  'D_CAMPLAN - Campus Planning',
  'D_DOF_CORE - Facilities Building Core(hallways, stairs, etc.)',
  'D_DOF_RESERVE - Facilities Provost Reserved',
  'D_DOF_RESIDENCE - Facilities Residences',
  'D_EHSO - Environment, Health and Safety Office',
  'D_EMMGMT - Emergency Management',
  "D_EXECVP - Executive Vice President's Office",
  'D_FACILITIES_AR - Facilities Area',
  'D_FACILITIES - Department of Facilities',
  'D_MITCARD - MIT Card Office',
  'D_FINANCE_AREA - VP Finance Area',
  'D_ASOPS - Administrative Services & Operations',
  'D_BF & T - Budget, Finance & Treasury',
  'D_BFA - Budget & Financial Analysis',
  'D_CAS - Controller & Accounting Services',
  'D_CONTROLLER - Controller',
  'D_FINOPS - Financial Operations',
  'D_LFO - Lincoln Fiscal Office',
  'D_PROPERTY - Property Office',
  'D_RSO - Office of Recoring Secretary',
  'D_SOURCING_AREA - Sourcing Area',
  'D_PROCURE - Procurement Office',
  'D_SOURCING - Sourcing',
  'D_TRAVEL - Travel office',
  'D_SSC - Strategic Sourcing & Contracts',
  'D_VPFHQ - VPF Headquarters',
  'D_VPFINANCE - Vice President for Finance',
  'D_VPFINHR - VP Finance HR',
  'D_VPFINIR - VP Finance Institute Related',
  'D_VPFINSD - VP Finance Senior Director',
  'D_GRAY - Gray House',
  'D_HR_AREA - HR Area',
  'D_BA - Benefits Administration',
  'D_FAMILY_RESCTR - Family Resource Center',
  'D_HR - Human Resources Department',
  'D_INSURANCE - Office of Insurance',
  'D_IS & T - Information Services and Technology',
  'D_MEDICAL - MIT Medical Department',
  'D_MITIMCO - MIT Investment Management Company',
  'D_NEWCO - NewCo',
  'D_OBFP - Office of Budget & Financial Planning',
  'D_OGC - General Counsel and Risk Management',
  'D_OGCR - Office of Government and Community Relations',
  'D_OMA - Office of Major Agreements',
  'D_OPENSPACE - Open Space Programming',
  'D_PARKING - Parking and Transportation',
  'D_POLICE - MIT Police',
  'D_RESDEV - Vice President for Resource Development',
  'D_SUST - OFFICE OF SUSTAINABILITY',
  'D_THEENGINE - The Engine',
  "D_TREAS_AREA - Treasurer's Area",
  'D_OTP - Office of Treasury and Planning',
  'D_INST_REL_AREA - Miscellaneous Institute Related',
  'D_INST_RELATED - Institute Related',
  'D_LONGTERM_DIS - Long term disability',
  'D_OTHER_PAYMENT - Payroll - other payments',
  'D_LINCOLN_LAB - Lincoln Laboratory Area',
  'D_LINCOLN - Lincoln Laboratory',
  'D_OBSOLETE - Obsolete DLC codes',
  'D_CAVS - Center for Advanced Visual Studies',
  'D_DAPER - Department of Athletics',
  'D_PCLM - Picower Institute for Learning and Memory',
  'D_OTHER_ORG - Outside organizations affiliated with MIT',
  'D_COFHE - Consortium on Financing Higher Education',
  'D_CREDITUNION - Credit Union',
  'D_DIBNER - Dibner Institute',
  'D_DRAPER - Draper Laboratories',
  'D_FFHE - Forum for the Future of Higher Education',
  'D_MGHPCC - Mass Green High Performance Computing',
  'D_WHITEHEAD - Whitehead Institute',
  'D_OUTSIDE_INST - Other institutions outside of MIT',
  'D_CROSS_REG - Institutions where students are cross - registered',
  "D_PRES_AREA - President's area",
  'D_CAA - Chancellor Academic Advancement',
  'D_COPYTECH - Copy Technology Center',
  'D_CORP_CHAIR - Office of the Corporate Chairman',
  'D_OVPC - OFFICE OF VICE PRESIDENT FOR COMMUNICATIONS',
  'D_PRESIDENT - Office of President / Chairman',
  'D_PRES_EMERITUS - President Emeritus',
  'D_VP_SEC_CORP - VP and Secretary of the Corporation',
  'D_PROVOST_AREA - Provost Area',
  'D_ARTS_PROVOST - Associate Provost for the Arts',
  'D_ARTS - Office of The Arts',
  'D_LVA - List Visual Arts Center',
  'D_MUSEUM - MIT Museum',
  "D_CHANCEL_AREA - Chancellor's Area",
  "D_CHANCELLOR - Chancellor's Office",
  'D_CPSE - Council on Primary and Secondary Education',
  'D_DL_AREA - Digital Learning Area',
  'D_DL - Digital Learning',
  'D_EDX - EDX',
  'D_OCW - Open Courseware',
  'D_OEIT - Office of Education, Innovation and Technology',
  'D_DSL - Dean for Student Life',
  'D_ATHLETICS - Dept of Athletics, Phys Ed, & Recreation',
  'D_AVS - Audiovisual Services',
  'D_CAC - Campus Activities Complex',
  'D_CHAPLAINS - Chaplains',
  'D_D & CI - Diversity & Community Involvment',
  'D_DINING - Campus Dining',
  'D_DSL: HQ - Dean for Student Life - Headquarters',
  'D_ENDICOTT - Endicott House',
  'D_ENTSRVCS - Enterprise Services',
  'D_FSILG - FSILG',
  'D_HOHP - Head of House Programs',
  'D_HOUSING - Housing and Residential Services',
  'D_LEADDEV - Leadership Development',
  'D_RE - Residential Education',
  'D_RLP - Residential Life Programs',
  'D_SLMPLS - Student Leadership, Multicultural Programs & LBGTQ',
  'D_SLP - Student Life programs',
  'D_SR_DEAN_STDNT - Senior Associate Dean of Students',
  'D_SSWB - Student Support and Well - being',
  'D_STUDDEV - Student Development',
  'D_STUDORG - Student Organizations',
  'D_DUE - Dean for Undergraduate Education',
  'D_ACAD - Academic Services',
  'D_ADM - Admissions',
  'D_CONCOURSE - Concourse',
  'D_CSPA - Career Serv & Pre - Prof Advising',
  'D_DUE: HQ - DUE HQ',
  'D_ESG - Experimental Study Group',
  'D_GEO - Global Education Office',
  'D_OEL - Office of Experiential Learning',
  'D_OFS - Faculty & Curriculum Support',
  'D_OME - Office of Minority Education',
  'D_OSA - Office of Study Abroad',
  'D_REG - Registrar',
  'D_REVENUES - Tuition Fees and Related Revenues',
  'D_ROTC - ROTC area',
  'D_ROTC_AERO - ROTC Aerospace Studies',
  'D_ROTC_MIL - ROTC Military Science',
  'D_ROTC_NAVAL - ROTC Naval Science',
  'D_SFS - Student Financial Services',
  'D_SSIT - Student Services Information Technology',
  'D_SSS - Student Support Services',
  'D_STU_SPECIAL - Special Programs in Student Systems',
  'D_TERRASCOPE - Terrascope',
  'D_TLL - Teaching and Learning Lab',
  'D_UAAP - Office of First Year Advising & Programs',
  'D_LIB_TR_PRESS - Libraries, Tech Review, and MIT Press',
  'D_LIBRARIES - Libraries',
  'D_PRESS - MIT Press',
  'D_TECHREVIEW - Technology Review',
  'D_OFC_PROVOST - Office of the Provost Area',
  'D_CAES - Center for Advanced Educational Services',
  'D_CENTRAL_PROF - Central Professorships',
  'D_CMI - Cambridge - MIT Institute',
  'D_INST_PROF - Institute Professors',
  'D_MASDAR - Masdar Institute',
  'D_MCGOVERN - McGovern Institute for Brain Research',
  'D_MCSC - MIT Climate & Sustainability Consortium',
  'D_MITOI - MIT OFFICE OF INNOVATION',
  'D_MITX - MIT ON - LINE LEARNING',
  "D_PROVOST - Provost's Office",
  'D_SKOLKOVO - Skolkovo',
  'D_SOLVE - Solve',
  'D_SUTD - MIT - SUTD Collaboration',
  'D_OSATT_AREA - Office of Strategic Alliances & Tech Transfer Area',
  'D_ALLIANCE_MGMT - OSATT - Alliance Management',
  'D_CATALYSTS - OSATT - Catalysts',
  'D_ILP - OSATT - Corporate Relations',
  'D_OSATT - Ofc of Strategic Alliances & Tech Transfer',
  'D_STRATEG_TRANS - OSATT - Strategic Transactions',
  'D_TLO - OSATT - Techology Licensing Office',
  'D_OTHER_EXPENSE - Other budgeted expenses',
  "D_OVC_AREA - Office of the Vice Chancellor's Area",
  'D_D - LAB; - D - LAB',
  'D_EDGERTON - Edgerton Center',
  'D_ISO - International Students Office',
  'D_OFY - Office of the First Year',
  'D_OVC - Office of the Vice Chancellor',
  'D_OVCHQ - Office of the Vice Chancellor HQ',
  'D_OVCOEL - OVC Office of Experiential Learning',
  'D_PPSC - PKG Public Service Center',
  'D_REGALL - Registrar All',
  'D_UROP - Undergraduate Research Opportunities Pro 441420',
  'D_SMART - Singapore - MIT Alliance for Res & Tech',
  'D_STUDENT_FRESH - Freshman',
  'D_STUDENT_UND - UNDESIGNATED SOPHOMORE',
  'D_SCHOOL_ARCH - School of Architecture & Planning',
  'D_ACT - Program in Art Culture & Technology',
  'D_ARCH - Architecture',
  'D_ARCH_DEPTHEAD - Architecture Department Heads',
  'D_CAU - CENTER FOR ADVANCED URBANISM',
  'D_CRE - Center for Real Estate',
  'D_DEANARCH - Dean of Architecture',
  'D_DUSP - Urban Studies & Planning',
  'D_LCAU - LEVENTHAL CENTER FOR ADVANCED URBANISM',
  'D_MAS - Media Arts & Sciences Program',
  'D_MEDIA - Media Lab',
  'D_SCHOOL_ENG - School of Engineering',
  'D_AEROASTRO - Aeronautics and Astronautics',
  'D_ARCHAE - Archaeology',
  'D_ASO - Administrative Services Organization',
  'D_ASO: HQ - Administrative Services Org.Headquarters',
  'D_ASP - Advanced Study Program',
  'D_BIOENG - Biological Engineering',
  'D_BPEC - Biotechnology Process Engineering Center',
  'D_BPMIT - BP - MIT Program',
  'D_CBE - Center for Biomedical Engineering',
  'D_CBI - Center for Biomedical Innovation',
  'D_CDO - Computation for Design & Optimization',
  'D_CEE - Civil & Environmental Engineering',
  'D_CHEME - Chemical Engineering',
  'D_CIPD - Center for Innovation in Product Development',
  'D_CLAB - Communication Lab',
  'D_CTL - Center for Transportation & Logistics',
  'D_DCTI - Deshpande Center for Technological Innovation',
  'D_DMSE - Dept Material Science and Engineering',
  'D_DOE - Dean of Engineering',
  'D_ECSEL - ECSEL',
  'D_EECS - Electrical Engineering & Computer Science',
  'D_EIP - Engineering Internship Program',
  'D_GLP - Gordon Leadership Program',
  'D_HPMIT - HP - MIT Alliance',
  'D_ICAMPUS - Project I - Campus',
  'D_III - International Innovation Initiative',
  'D_IMES - Institute for Medical Engineering and Science',
  'D_IPC - Industrial Performance Center',
  'D_ISP - Integrated Studies Program',
  'D_LEES - Lab for Electromagnetic & Electrical Systems',
  'D_LEMELSON - Lemelson - MIT Project',
  'D_LGO - Leaders for Global Operations',
  'D_MECHE - Mechanical Engineering',
  'D_MITES - Minority Intro to Engineering & Science Program',
  'D_MITTAKEDA - MIT - Takeda Program',
  'D_MTL - Microsystems Technology Laboratory',
  'D_NUCENG - Nuclear Science and Engineering',
  'D_OCEAN - Ocean Engineering',
  'D_OEOP - Office of Engineering Outreach Program',
  'D_PEP - Professional Education Programs',
  'D_PIW - Dean of Engineering Program in Writing',
  'D_PORTUGAL - MIT - Portugal Program',
  'D_PPST - Program in Polymer Sciences & Technology',
  'D_SCM - Supply Chain Management Program',
  'D_SDM - Systems Design & Management',
  'D_SMA - Singapore / MIT Alliance',
  'D_TIE - Tech.Innovation and Entrepreneurship',
  'D_UPOP - Undergrad Practice Op Prog',
  'D_SCHOOL_HUM - School of Humanities & Social Science',
  'D_ANTHRO - Anthropology',
  'D_CIS - Center for International Studies',
  'D_CMS - Comparative Media Studies / Writing',
  'D_DHSS - Dean of Humanities & Social Sciences',
  'D_ECO - Economics',
  'D_FLL - Foreign Languages & Literature',
  'D_HISTORY - History',
  'D_HUM_DEPTHEAD - Humanities and Social Sciences Department Heads',
  'D_L & P - Linguistics & Philosophy',
  'D_LIT - Literature',
  'D_MTA - Music & Theater Arts',
  'D_POLSCI - Political Science',
  'D_STS - Program In Science, Technology & Society',
  'D_SCHOOL_SCI - School of Science',
  'D_B & CS - Brain & Cognitive Sciences',
  'D_BIOLOGY - Biology',
  'D_CGCS - Center for Global Change Science',
  'D_CHEM - Chemistry',
  'D_CSBI - Computational and Systems Biology',
  'D_CSR - Kavli Institute for Astrophysics & Space Research',
  'D_DEANSCI - Dean Of Science',
  'D_EAPS - Earth, Atmospheric & Planetary Sciences',
  'D_HHMI - Howard Hughes Medical Institute',
  'D_LNS_AND_BATES - Lab for Nuclear Science with Bates',
  'D_BATES - Bates Accelerator',
  'D_LNS - Laboratory for Nuclear Science',
  'D_MATHS - Mathematics',
  'D_PHYSICS - Physics',
  'D_PILM - Picower Institute for Learning & Memory',
  'D_SCI_DEPTHEAD - School of Science Department Heads',
  'D_SCSB - Simons Center for the Social Brain',
  'D_SPEC - Spectroscopy Laboratory',
  'D_WALLACE - Wallace Observatory',
  'D_SLOAN_SCHOOL - Sloan School Area',
  'D_SLOAN - Sloan School of Management',
  'D_UNDEF_DEFUNCT - Undefined or defunct',
  'D_DEFUNCT - Defunct units',
  'D_OLE - Open Learning Enterpirse',
  'D_UNDEF - Undefined - no DLC',
  'D_VPRES - VP Research & Dean of the Graduate School',
  'D_BROAD - The Broad Institute',
  'D_BROAD: CB - Broad Chemical Biology',
  'D_BROADAFF - Broad Affiliations',
  'D_CEHS - Center for Environmental Health Sciences',
  'D_CFELLOWS - Central Fellowships',
  'D_CLINRES - Clinical Research Center',
  'D_CMSE - Center for Materials Science & Engineering',
  'D_COMPMED - Division Of Comparative Medicine',
  'D_DEANGRAD - Dean Of The Grad School Office',
  'D_ESI - Earth Systems Initiative',
  'D_HAYSTACK - Haystack Observatory',
  'D_HST - Harvard - MIT Health Sciences & Technology',
  'D_IPCOUNSEL - Intellectual Property Counsel',
  'D_ISN - Institute for Soldier Nanotechnologies',
  'D_J - WAFS; - Abdul Latif Jameel Water and Food Systems Lab',
  'D_KI - Koch Institute for Integrative Cancer Research',
  'D_LDDA - Lab Directors & Department Admin',
  'D_LFEE - MIT Energy Initiative',
  'D_MAGLAB - Francis Bitter Magnet Laboratory',
  'D_MIT.NANO - MIT NANO',
  'D_MITEI - MIT Energy Initiative',
  'D_MITESI - MIT Environmental Solutions Initiative',
  'D_MPC - Materials Processing Center',
  'D_MRL - Materials Research Laboratory',
  'D_NRL - Nuclear Reactor Laboratory',
  'D_OSP - Office of Sponsored Programs',
  'D_PSFC - Plasma Sciences & Fusion Center',
  'D_RLE - Research Lab Of Electronics',
  'D_TDP - Technology & Development Program',
  'D_VPRESOFF - Vice President for Research',
  'D_VPRES_DEPTHD - VP for Research Department Heads',
  'D_VPRLL - VPR - LINCOLN LAB',
  'D_WHIT - Whitaker College'
]
