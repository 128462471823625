export const queryMatchesCSGold = (query: string): boolean =>
{
    let lastIndexOfStringPrepend = query.length < 3 ? query.length : 3;

    let firstThreeCharactersOrLess = query.substr(0,lastIndexOfStringPrepend);
    //Use Array instead of repeat for IE support
    let seriesOfThrees = Array(lastIndexOfStringPrepend + 1).join("3");

    return firstThreeCharactersOrLess === seriesOfThrees;
}

export const CSGoldSearch = (query: string, onChangeFunc: any,requestType: string,hasAuth: boolean): any =>
{
    if(query.length !== 9)
        return setIncompleteCSGoldItem();
    else if(hasAuth)
        return setCompleteCSGoldItem(query,onChangeFunc,requestType);
    else
        return setNoAuthCSGoldItem(query,requestType);
}

const setNoAuthCSGoldItem = (csGoldID: string,requestType: string) : any => {

    const completeSearchEntry = {
        icon: 'user',
        text: "CS Gold ID",
        secondaryText: csGoldID,
        tertiaryText: "You do not have authorization to " + requestType + " these cards",
        quaternaryText: csGoldID,
        onClick: () => void(0)
    };

    return {
        cardholders: [completeSearchEntry],
        isBusy: false
    };
}

const setCompleteCSGoldItem = (csGoldID: string, onChangeFunc: any, requestType: string) : any => {

    const completeSearchEntry = {
        icon: 'user',
        text: "CS Gold ID",
        secondaryText: csGoldID,
        tertiaryText: "Send " + requestType +" request for ID",
        quaternaryText: csGoldID,
        onClick: () => onChangeFunc(csGoldID)
    };

    return {
            cardholders: [completeSearchEntry],
            isBusy: false
        };
}

const setIncompleteCSGoldItem = () : any => {

    const incompleteSearchEntry = {
        icon: 'user',
        text: "Incomplete CS Gold ID",
        secondaryText: "",
        tertiaryText: "Please enter all 9 digits",
        quaternaryText: "",
        onClick: () => void(0)
    };

    return {
        cardholders: [incompleteSearchEntry],
        isBusy: false
    };
}
