import React, { Fragment } from 'react'
import { NavItemProps, NavItemState, NavBar, NavBarTypes, Icon } from '@mit/hui'
import { connect } from 'react-redux'
import History from '../../../common/History'
import { ClearanceModel } from '../../../api/models/ClearanceModel'
import AlarmController from '../../../api/AlarmController'

interface AlarmNavBarState {
  badge: any
}

interface AlarmNavBarProps {
  selectedAlarm: ClearanceModel
  match: any
  roles?: string[]
  contact?: number
}

class AlarmNavBar extends React.Component<AlarmNavBarProps, AlarmNavBarState> {
  alarmController: AlarmController

  constructor (props: any) {
    super(props)

    this.alarmController = new AlarmController()

    this.state = {
      badge: null
    }
  }

  componentDidUpdate (prevProps: AlarmNavBarProps) {
    if (this.props.selectedAlarm !== prevProps.selectedAlarm || this.props.contact !== prevProps.contact) {
      this.getBadge(this.props.selectedAlarm)
    }
  }

  getBadge (itm: ClearanceModel): any {
    this.setState({ badge: null })
    this.alarmController.abortRequest()
    this.alarmController.getContacts(itm.id).then(response => {
      //@ts-ignore
      if (response?.name === 'AbortError') return null

      if (!this.props.roles?.includes('PSEC RO SUPER USER')) {
        if (!response?.contact_1) return this.setState({ badge: { content: <Icon type='solid' icon='exclamation' />, type: 'warning' } })

        if (!response?.contact_2) return this.setState({ badge: { content: <Icon type='solid' icon='exclamation' />, type: 'danger' } })
      }

      return null
    })
  }

  render () {
    const isSuperUser = this.props.roles && (this.props.roles.includes('PSEC SUPER USER') || this.props.roles.includes('PSEC RO SUPER USER'))
    if (window.location.pathname.includes('departments') && this.props.roles && !isSuperUser) History.push('/alarms/area-information')

    let alarmId = ''
    if (this.props.match.params.id) alarmId = '/' + this.props.match.params.id

    const alarmTabData: NavItemProps[] = [
      {
        icon: '',
        iconOnly: false,
        text: 'Area Info',
        state: window.location.pathname.includes('area-information') ? NavItemState.Active : NavItemState.None,
        to: '/alarms/area-information' + alarmId
      },
      {
        icon: '',
        iconOnly: false,
        text: 'Manage Access',
        state: window.location.pathname.includes('access-management') ? NavItemState.Active : NavItemState.None,
        to: '/alarms/access-management' + alarmId
      },
      {
        icon: '',
        iconOnly: false,
        text: 'Gatekeepers',
        state: window.location.pathname.includes('gatekeepers') ? NavItemState.Active : NavItemState.None,
        to: '/alarms/gatekeepers' + alarmId
      },
      {
        icon: '',
        iconOnly: false,
        text: 'Contact Info',
        badge: this.state.badge,
        state: window.location.pathname.includes('contact-information') ? NavItemState.Active : NavItemState.None,
        to: '/alarms/contact-information' + alarmId
      }
    ]

    if (isSuperUser)
      alarmTabData.push({
        icon: '',
        iconOnly: false,
        text: 'Departments',
        state: window.location.pathname.includes('departments') ? NavItemState.Active : NavItemState.None,
        to: '/alarms/departments' + alarmId
      })

    return <Fragment>{alarmId ? <NavBar tabs={alarmTabData} type={NavBarTypes.Tabs} /> : <div></div>}</Fragment>
  }
}

const mapStateToProps = (state: any) => ({
  roles: state.app.roles,
  contact: state.notification.alarmContact
})

export default connect(mapStateToProps)(AlarmNavBar)
