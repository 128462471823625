import React, { Fragment } from 'react';
import ElevatorsEvents from './ElevatorsEvents';
import ElevatorsGatekeepers from './ElevatorsGatekeepers';
import { Error, ErrorContext, ErrorType } from '@mit/hui';
import { Route } from 'react-router-dom';

interface ElevatorsDetailProps
{
    buildingId: string;
    elevatorId: string;
}

export default class ElevatorsDetail extends React.Component<ElevatorsDetailProps>
{
    render()
    {
        if (!this.props.elevatorId)
            return <Error context={ErrorContext.Component} message="Please select an elevator" type={ErrorType.FirstAction} />

        return (<Fragment>           
            <Route path={"/elevators/events/:building?/:elevator?"} component={ElevatorsEvents} />
            <Route path={"/elevators/gatekeepers/:building?/:elevator?"} component={ElevatorsGatekeepers} />
        </Fragment>);
    }
}