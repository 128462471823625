import React, { Fragment, FC } from 'react';
import { BuildingModel } from '../../../api/models/BuildingModel';
import { BuildingDoorEntityView } from './BuildingDoorEntityView';
import { withComponent } from '../../../common/WithComponent';

interface BuildingsDoorsViewProps
{
    data: BuildingModel[];
    onClick: any;
    doorId: string;
    buildingId: string;
    keyword: string;
    superUser: boolean;
}

export const BuildingsDoorsView: FC<BuildingsDoorsViewProps> = (props) =>
{
    const BuildingDoorEntityViewData = withComponent(BuildingDoorEntityView);

    return <Fragment>
        {
            props.data.map((buildings: BuildingModel, index: number) =>
            {
                return <Fragment>
                    <BuildingDoorEntityViewData superUser={props.superUser} key={index} buildingId={props.buildingId} doorId={props.doorId} data={buildings} onClick={props.onClick} keyword={props.keyword} />
                </Fragment>
            })
        }
    </Fragment>
}