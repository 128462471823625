import React, { FC } from "react";
import { FilteredList, TextboxType } from "@mit/hui";

interface IFilteredListLoaderProps
{
    text?: string;
}

export const FilteredListLoader: FC<IFilteredListLoaderProps> = (props) =>
{
    const emptyData =
    {
        startIcon: 'search',
        startIconAccessibilityText: 'search',
        placeholderText: 'Filter my clearances',
        type: TextboxType.IconStart,
        name: "filterLoading"
    }

    return <FilteredList name="filteredList" loadingText={props.text} isLoading={true} height={0} items={[]} searchOptions={emptyData} />
}