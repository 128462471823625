class FilterProvider
{
    getObjectById = (theObject: any, id: string): any => {
        var result = null;
        if(theObject instanceof Array) {
            for(var i = 0; i < theObject.length; i++) {
                result = this.getObjectById(theObject[i], id);
                if (result) {
                    break;
                }   
            }
        }
        else
        {
            for(var prop in theObject) {
               
                if(prop === 'id') {
                    if(theObject[prop] === id) {
                        return theObject;
                    }
                }
                if(theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
                    result = this.getObjectById(theObject[prop], id);
                    if (result) {
                        break;
                    }
                } 
            }
        }
        return result;
    }
}

export default FilterProvider;