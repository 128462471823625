import React from 'react';
import { TemplateMasterDetail, NavItemProps, NavBar, NavBarTypes, ResponsiveTypes, MobileView } from '@mit/hui';
import { connect } from 'react-redux';
import ClearanceMaster from './ClearanceMaster';
import ClearanceDetail from './ClearanceDetail';
import { NavItemState } from '../../enums/NavItemState';
import { withComponent } from '../../common/WithComponent';
import History from "../../common/History";

interface ClearancesState
{
    view: number
}

interface ClearancesProps
{
    clearance?: string;
    match: any;
    roles?: string[];
}

class Clearances extends React.Component<ClearancesProps, ClearancesState>
{
    constructor(props: any)
    {
        super(props)
        this.state = {
            view: 1
        }
    }

    componentDidMount()
    {
        this.setState({ view: this.props.match.params.id ? MobileView.Detail : MobileView.Master })
    }

    onItemClick()
    {
        this.setState({ view: 2 })
    }

    render()
    {
        const isSuperUser = this.props.roles && (this.props.roles.includes("PSEC SUPER USER") || this.props.roles.includes("PSEC RO SUPER USER"))
        if (window.location.pathname.includes("departments") && this.props.roles && !isSuperUser)
            History.push("/clearances/clearance-information");

        let clearanceId = "";
        if (this.props.match.params.id)
            clearanceId = "/" + this.props.match.params.id;

        let clearanceTabData: NavItemProps[] = [
            {
                icon: '',
                iconOnly: false,
                text: 'Clearance Info',
                state: window.location.pathname.includes("clearance-information") ? NavItemState.Active : NavItemState.None,
                to: '/clearances/clearance-information' + clearanceId
            },
            {
                icon: '',
                iconOnly: false,
                text: 'Manage Access',
                state: window.location.pathname.includes("access-management") ? NavItemState.Active : NavItemState.None,
                to: '/clearances/access-management' + clearanceId
            },
            {
                icon: '',
                iconOnly: false,
                text: 'Gatekeepers',
                state: window.location.pathname.includes("gatekeepers") ? NavItemState.Active : NavItemState.None,
                to: '/clearances/gatekeepers' + clearanceId
            }
        ];

        if (isSuperUser)
            clearanceTabData.push({
                icon: '',
                iconOnly: false,
                text: 'Departments',
                state: window.location.pathname.includes("departments") ? NavItemState.Active : NavItemState.None,
                to: '/clearances/departments' + clearanceId
            });

        const NavBarData = withComponent(NavBar);

        return (
            <TemplateMasterDetail
                mobileView={this.state.view}
                startState={true}
                onBack={() => this.setState({ view: 1 })}
                master={<ClearanceMaster clearanceId={this.props.match.params.id} onclick={() => this.onItemClick()} />}
                masterTitle="My Clearances"
                navigation={clearanceId ? <NavBarData responsiveType={ResponsiveTypes.CollapseFit} type={NavBarTypes.Tabs} tabs={clearanceTabData} /> : <div></div>}
                detail={<ClearanceDetail clearanceId={this.props.match.params.id} />}
                detailTitle={this.props.match.params.id ? this.props.match.params.id.replace("gsc-clearance-", "") : ""}
            />
        )
    }
}

const mapStateToProps = (state: any) => ({
    roles: state.app.roles
});


export default connect(mapStateToProps)(Clearances);