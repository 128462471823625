import {
  Drawer,
  DrawerLayout,
  DrawerSize,
  DrawerType,
  NavItem,
  ReleaseNoteItemProps,
  Text,
  TextType,
  ReleaseNotes,
} from "@mit/hui";
import React, { Fragment } from "react";

interface IReleaseNotesState {
  showReleaseNotes: boolean;
}

export default class ReleaseNotesPopup extends React.Component<
  any,
  IReleaseNotesState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      showReleaseNotes: false,
    };
  }

  render() {
    const releaseNotes: ReleaseNoteItemProps[] = [
      {
        date: "12/21/2020",
        text:
          "The Genetec dashboard will include alarm functionality, including viewing all alarms that the gatekeeper can manage and the ability to manage cardholder access to those alarms, reset PINs for cardholders, update contact information for the alarms, and send service requests to CSSO.",
        items: [],
      },
      {
        date: "10/01/2020",
        text:
          "The Genetec dashboard is now widely available for gatekeepers whose clearances have been fully migrated from CCURE to Genetec. This includes all the previous clearance functionality, and now has additional doors and elevator functionality. Gatekeepers can search for a door and view its schedules as well as add/edit/remove those schedules. They can see all access allowed or denied for that door in the past week, and momentarily unlock that door from the dashboard. They can also see the other gatekeepers/space administrators that can manage that door. The gatekeeper can also view the list of elevators that fall under the clearances they manage and the other gatekeepers/space administrators that can manage that elevator. ",
        items: [],
      },
      {
        date: "09/01/2020",
        text:
          "The Genetec dashboard has been beta released to the nist-physec team. This dashboard allows gatekeepers to view the clearances they can manage and add or remove cardholders to those clearances either individually or in bulk. The dashboard also allows users to see the other gatekeepers and space administrators for each clearance.",
        items: [],
      },
    ];

    return (
      <Fragment>
        <NavItem
          icon="bell"
          text="What's new!"
          iconOnly={true}
          onClick={() => this.setState({ showReleaseNotes: true })}
        />
        <Drawer
          show={this.state.showReleaseNotes}
          onClose={() => this.setState({ showReleaseNotes: false })}
          type={DrawerType.Right}
          size={DrawerSize.Medium}
          layout={DrawerLayout.Hero}
          header={
            <Text content="What's New!" type={TextType.Heading4} icon="bell" />
          }
          contents={
            <Fragment>
              <ReleaseNotes items={releaseNotes} />
            </Fragment>
          }
          footer={[]}
        />
      </Fragment>
    );
  }
}
