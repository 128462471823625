import React, { Fragment } from 'react'
import { Error, ErrorContext, ErrorType } from '@mit/hui'
import ScheduleInformation from './ScheduleInformation'
import ScheduleGatekeeper from './ScheduleGatekeeper'
import { Route } from 'react-router-dom'
import ScheduleDepartments from './ScheduleDepartment'
import { connect } from 'react-redux'
import ManageSchedule from './ManageSchedule'

interface ScheduleDetailProps {
  scheduleId: string
  roles?: string[]
}

class ScheduleDetail extends React.Component<ScheduleDetailProps> {
  render () {
    if (!this.props.scheduleId) return <Error context={ErrorContext.Component} message='Please select a schedule' type={ErrorType.FirstAction} />

    return (
      <Fragment>
        <Route exact path={'/'} component={ScheduleInformation} />
        <Route path={'/schedules/schedule-information/:id?'} component={ScheduleInformation} />
        <Route path={'/schedules/manage/:id?'} component={ManageSchedule} />
        <Route path={'/schedules/gatekeepers/:id?'} component={ScheduleGatekeeper} />
        {this.props.roles && (this.props.roles.includes('PSEC SUPER USER') || this.props.roles.includes('PSEC RO SUPER USER')) && (
          <Route path={'/Schedules/departments/:id?'} component={ScheduleDepartments} />
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = (state: any) => ({
  roles: state.app.roles
})

export default connect(mapStateToProps)(ScheduleDetail)
