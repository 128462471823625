import React, { FC, Fragment, useEffect, useState } from 'react'
import {
  Alert,
  Button,
  ButtonType,
  CalendarInput,
  FormContainer,
  FormField,
  GridContainer,
  LayoutColumn,
  Spacer,
  Text,
  TextArea,
  TextType,
  Toggle
} from '@mit/hui'
import AlertTimer from '../../../common/AlertTimer'
import ScheduleController from '../../../api/ScheduleController'
import { getReverseToggleChoice, getToggleChoice, init2Time, initTime, MultiTimeRange, MultiTimeRanges, validateTimeRanges } from './MultiTimeRanges'
import moment from 'moment'
import ScheduleFrequency, { ScheduleFrequencyEnum} from "./ScheduleFrequency";

export interface SpecificScheduleObject {
  dateRanges: DateRanges[]
  comment: string
  lastUpdatedBy: string
}

interface SpecificScheduleFormProps {
  scheduleId: string
  schedule: SpecificScheduleObject
  scheduleString: string
  onScheduleTypeChange: any
}

export interface DateRanges {
  date: string
  times: MultiTimeRange[]
  allDay?: boolean
}

export const initDate: DateRanges = {
  date: moment().format('YYYY-MM-DD'),
  allDay: false,
  times: [initTime, init2Time]
}

export const initSpecificScheduleObject: SpecificScheduleObject = {
  dateRanges: [initDate],
  comment: '',
  lastUpdatedBy: ''
}

const SpecificScheduleForm: FC<SpecificScheduleFormProps> = props => {
  const MAXLENGTH = 50
  const scheduleController = new ScheduleController()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [fromProcessing, setFormProcessing] = useState<boolean>(false)
  const [comment] = useState<string>(props.schedule.comment)
  const [showLastUpdatedBy] = useState<Boolean>(props.schedule.lastUpdatedBy !== '')

  const [dateRanges, setDateRanges] = useState<DateRanges[]>(props.schedule.dateRanges ?? initDate)

  useEffect(() => {
    for (let i = 0; i < dateRanges.length; i++) {
      let element = document.getElementById(i + '-date') as HTMLInputElement
      const date = moment(dateRanges[i].date, 'YYYY-MM-DD')
      const formattedDate = date.format('MM/DD/YYYY')
      if (element.value !== formattedDate) {
        element.value = formattedDate
      }
    }
  }, [dateRanges])

  function submitValidation (valid: boolean, comment: string) {
    if (!valid) {
      setErrorMessage('Your submission contains errors. Refer to the error messages provided on correcting these issues before resubmitting.')
      return false
    }

    if (comment.length > MAXLENGTH) {
      setErrorMessage(`Please provide a comment that is no more than ${MAXLENGTH} characters long. It is currently ${comment.length} characters`)
      return false
    }

    // Rule not to have duplicates
    if (dateRanges.filter((item, index) => dateRanges.find((date, index2) => item.date === date.date && index2 !== index)).length > 0) {
      setErrorMessage('There are more then one of the same date')
      return false
    }

    // Rule not to submit more than 7 dates
    if (dateRanges.length > 7) {
      setErrorMessage('Can only select a maximum of 7 specific dates')
      return false
    }

    // Rule not to submit more than 2 time ranges
    if (dateRanges.find(day => day.times.length > 2)) {
      setErrorMessage('Only allowed to submit 2 time ranges on specific date')
      return false
    }

    if (dateRanges.find(day => !validateTimeRanges(day.times, setErrorMessageMulti))) {
      return false
    }

    return true
  }

  function onSubmit (data: any, valid: boolean) {
    setErrorMessage('')
    if (submitValidation(valid, data.comment)) {
      const unlockDaT = dateRanges.map(dateRange => {
        let times: any = []
        if (dateRange.allDay !== true) {
          times = [
            ...dateRange.times.map(time => {
              return {
                start_time: time.start.concat(':00'),
                end_time: time.end === '23:59' ? '24:00:00' : time.end.concat(':00')
              }
            })
          ]
        } else {
          // all day time
          times = [
            {
              start_time: '00:00:00',
              end_time: '24:00:00'
            }
          ]
        }
        return {
          day: null,
          date: dateRange.date,
          times: times
        }
      })

      setFormProcessing(true)
      const body = {
        schedule_id: props.scheduleId,
        frequency: 'specific',
        comment: data.comment,
        unlock_dates_and_times: unlockDaT
      }

      scheduleController
        .postSchedule(props.scheduleId, body)
        .then(async data => {
          const dataText = await data.text()
          if (data.status !== 200) {
            setErrorMessage('Update was unsuccessful. \n' + dataText)
            return
          }

          // Show success message
          setSuccessMessage('Update successful!')

          // Navigate back to ScheduleInformation route after a delay
          setTimeout(() => {
            setSuccessMessage('')
          }, 3000) // Delay for 3 seconds (adjust as needed)
        })
        .catch(error => {
          setErrorMessage('Something went wrong, your update was unsuccessful')
          console.error('Error Response', error)
        })
        .finally(() => {
          setFormProcessing(false)
          document.querySelector('[id="scrollTo"]')?.scrollIntoView({ behavior: 'smooth' })
        })
    } else {
      document.querySelector('[id="scrollTo"]')?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  function onAllDayChecked (index: number, checked: boolean) {
    setDateRanges(prevDateRanges => {
      const newRanges = [...prevDateRanges]
      newRanges[index].allDay = checked
      return newRanges
    })
  }

  function onDateRangeChange (index: number, dateValue: any) {
    setDateRanges(prevDateRanges => {
      const newArray = [...prevDateRanges]
      const date = moment(dateValue)
      newArray[index].date = date.format('YYYY-MM-DD')
      return newArray
    })
  }

  function onAddDateRange () {
    if(dateRanges.length >= 7) {
      setErrorMessage(`Can only have a max of 7 date ranges`)
      document.querySelector('[id="scrollTo"]')?.scrollIntoView({ behavior: 'smooth' })
      setTimeout(() => {
        setErrorMessage('')
      }, 3000)
      return
    }

    const lastRange = dateRanges[dateRanges.length - 1]
    let lastDate = moment()
    if (lastRange?.date !== undefined) {
      lastDate = moment(lastRange.date).add(1, 'days')
    }
    let newDateRange: DateRanges = {
      date: lastDate.format('YYYY-MM-DD'),
      times: [initTime, init2Time]
    }

    setDateRanges(prevDateRanges => [...prevDateRanges, newDateRange])
  }

  function onRemoveDateRange (dateRangeIndex: number) {
    setDateRanges(prevDateRanges => {
      const newArray = [...prevDateRanges]
      newArray.splice(dateRangeIndex, 1)
      return newArray
    })
  }

  function setTimeRanges (index: number, timeRanges: MultiTimeRange[]) {
    setDateRanges(prevDateRanges => {
      const newArray = [...prevDateRanges]
      newArray[index].times = timeRanges
      return newArray
    })
  }

  function setErrorMessageMulti (message: string) {
    if (message !== '') {
      setErrorMessage('There seems to be an issue with the time ranges. Kindly correct them before proceeding with the update.')
    } else {
      setErrorMessage('')
    }
  }

  function buildTimeEntryComponent (dateRanges: DateRanges[]) {
    return dateRanges.map((item, index) => {
      return (
        <Fragment key={`${index}`}>
          <div className='time-div'>
            <GridContainer>
              <LayoutColumn alignment={'left'} colSize={2}>
                <Spacer size='1' />
                <Text content='Date' type={TextType.Body} icon='' />
              </LayoutColumn>
              <LayoutColumn alignment={'left'} colSize={4}>
                <CalendarInput
                  onChange={(date: any) => onDateRangeChange(index, date)}
                  isRangeSelected={false}
                  isMultiSelect={false}
                  shouldHighlightWeekends={true}
                  value={item.date}
                  id={`${index}-date`}
                />
              </LayoutColumn>
              <LayoutColumn colSize={4} alignment='left'>
                <Spacer size='1' />
                <Button icon='trash' onClick={() => onRemoveDateRange(index)} text='Remove day' type={ButtonType.TextNaked} theme='default' />
              </LayoutColumn>
            </GridContainer>
          </div>
          <GridContainer>
            <LayoutColumn colSize={3}>
              <span className='custom-toggle-container'>
                <Toggle
                  name={`${index}-allDaySpecific`}
                  choice={getToggleChoice(dateRanges[index].allDay ?? false)}
                  inverse={false}
                  negativeLabel='All day'
                  positiveLabel='All day'
                  onClick={(choice: any) => onAllDayChecked(index, getReverseToggleChoice(choice))}
                />
              </span>
            </LayoutColumn>
          </GridContainer>
          <Spacer size='2' />
          <MultiTimeRanges
            key={`${index}-timeRange`}
            maxTimes={2}
            disabled={item.allDay}
            name={String(index)}
            times={item.times}
            setTimes={setTimeRanges}
            setErrorMessage={setErrorMessageMulti}
          />
          <hr />
        </Fragment>
      )
    })
  }

  return (
    <Fragment>
      <FormContainer action={onSubmit} actionDisabled={false} formValidationTrigger='all' actionIsBusy={fromProcessing} submitText={'Update'} id='SpecificForm'>
        <div className='card card-entity-container p-4 mb-4'>
          {successMessage && <Alert text={successMessage} type={'success'} />}
          <AlertTimer message={errorMessage} />
          <ScheduleFrequency onScheduleTypeChange={props.onScheduleTypeChange} scheduleFrequency={ScheduleFrequencyEnum.Specific} />
          <hr />
          {buildTimeEntryComponent(dateRanges)}
          <Spacer size='2' />
          <GridContainer>
            <LayoutColumn colSize={12}>
              <Button text='New schedule date' icon='plus' type={ButtonType.Primary} onClick={() => onAddDateRange()} />
            </LayoutColumn>
          </GridContainer>
          <Spacer size='2' />
          <hr />
          <GridContainer showGutters>
            <LayoutColumn colSize={9}>
              <FormField label='Comment' id='comment' properties={{ maxLength: MAXLENGTH }} value={comment ?? ''} editor='multilinetextbox' labelDisplay='nextto' required />
            </LayoutColumn>
          </GridContainer>
          {showLastUpdatedBy ? (
            <Fragment>
              <hr />
              <GridContainer>
                <LayoutColumn colSize={3}>
                  <Text content='Last Updated By' bold />
                </LayoutColumn>
                <LayoutColumn colSize={9}>
                  <Text content={props.schedule.lastUpdatedBy} bold />
                </LayoutColumn>
              </GridContainer>
            </Fragment>
          ) : null}
        </div>
      </FormContainer>
    </Fragment>
  )
}

export default SpecificScheduleForm
