import React, { Fragment } from 'react';
import { Error, ErrorContext, ErrorType } from '@mit/hui';
import { LoadingIndicator } from '../../../common/LoadingIndicator';
import { BuildingsView } from '../../clearances/components/BuildingsView';

interface IManageAlarmBuildingDetailProps
{
    data: any;
}

interface IManageAlarmBuildingDetailState
{
    buildingsList: any;
    isLoading: boolean;
}

export default class ManageAlarmBuildingDetail extends React.Component<IManageAlarmBuildingDetailProps, IManageAlarmBuildingDetailState>
{
    constructor(props: IManageAlarmBuildingDetailProps)
    {
        super(props)

        this.state = {
            buildingsList: [],
            isLoading: true
        }
    }

    async componentDidMount()
    {
        this.setState({
            buildingsList: this.props.data.buildings,
            isLoading: false
        })
    }

    render()
    {
        if (!this.props.data.buildings && this.props.data !== 404)
            return <Error context={ErrorContext.Component} message={"No data available Area details"} type={ErrorType.NoData} retry={true} />

        if (this.state.isLoading)
            return <LoadingIndicator />

        return <Fragment>
            {
                this.state.buildingsList.length === 0
                    ?
                    <Error context={ErrorContext.Component} message={"No buildings available."} type={ErrorType.NoData} retry={true} />
                    :
                    <Fragment>
                        <BuildingsView data={this.state.buildingsList} />
                    </Fragment>
            }
        </Fragment>
    }
}