import React, { Fragment } from 'react';
import { Text, TextType, Spacer } from '@mit/hui';
import ClearanceController from '../../../api/ClearanceController';
import SpecificAddition from './SpecificAddition';
import BulkAddition from './BulkAddition';
import { withComponent } from '../../../common/WithComponent';
//@ts-ignore
import MediaQuery from 'react-responsive';

interface AccessManagementPeopleAddProps
{
    clearanceId: string;
}

export default class AccessManagementPeopleAdd extends React.Component<AccessManagementPeopleAddProps>
{
    clearanceController: ClearanceController;

    constructor(props: AccessManagementPeopleAddProps)
    {
        super(props)

        this.clearanceController = new ClearanceController();
    }

    render()
    {
        const BulkAdditionData = withComponent(
            BulkAddition
        );

        const SpecificAdditionData = withComponent(SpecificAddition);

        return <Fragment>
            <Text content="Add people" padded={true} type={TextType.Heading4} icon='user-plus' />
            <Spacer size="2" />
            <SpecificAdditionData clearanceId={this.props.clearanceId} />
            <MediaQuery minWidth={577}>
                <Spacer size="3" />
                <BulkAdditionData clearanceId={this.props.clearanceId} />
            </MediaQuery>
            <Spacer size="2" />
        </Fragment>
    }
}