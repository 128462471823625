import React, { Fragment } from "react";
import { Text, AutoComplete, Error, Table, TemplateModalSize, TextboxType, TextType, ErrorContext, ErrorType, Icon } from "@mit/hui";
import PeopleController from "../../../api/PeopleController";
import { PersonModel } from "../../../api/models/PersonModel";
import { ResultModel } from "../../../api/models/CardholdersResponseModel";
import { withComponent } from "../../../common/WithComponent";
import Modal from "../../../common/Modal";
import StringProvider from "../../../services/StringProvider";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as notificationActionCreator from "../../../ts/redux/actions/notification";
import { CardholderListModel } from "../../../api/models/CardholderListModel";
import { TableRowProps } from "@mit/hui/build/components/Table/TableRow";
import AlarmController from "../../../api/AlarmController";
import { PostResultModel } from "../../../api/models/PostResultModel";

interface SpecificRemovalsProps {
	alarmId: string;
	actions?: any;
}

interface SpecificAdditionState {
	cardholders: any;
	isBusy: boolean;
	isPosting: boolean;
	items: PostResultModel;
	showSpecificRemovalsModal: boolean;
	showSpecificRemovalsFailedModal: boolean;
}

const WAIT_INTERVAL = 700;
let timerID: any;

class SpecificRemovals extends React.Component<SpecificRemovalsProps, SpecificAdditionState> {
	peopleController: PeopleController;
	alarmController: AlarmController;
	stringProvider: StringProvider;

	constructor(props: SpecificRemovalsProps) {
		super(props);

		this.peopleController = new PeopleController();
		this.alarmController = new AlarmController();
		this.stringProvider = new StringProvider();

		this.state = {
			items: {
				data: {
					kerberos_id: "",
					mit_id: "",
				},
				id: "",
				message: "",
				success: false,
			},
			isPosting: false,
			cardholders: [],
			isBusy: false,
			showSpecificRemovalsFailedModal: false,
			showSpecificRemovalsModal: false,
		};
	}

	async onChange(kerbId: string) {
		this.setState({ isPosting: true });

		await this.alarmController.deleteRemovals(this.props.alarmId, kerbId).then(async (response) => {
			let json = await response.json();

			this.setState({
				cardholders: [],
				items: json,
				isPosting: false,
				showSpecificRemovalsModal: true,
			});
		});
	}

	getText(cardholder: PersonModel) {
		if (cardholder.display_name) return cardholder.display_name;

		if (cardholder.kerberos_id) return cardholder.kerberos_id;

		if (cardholder.mit_id) return cardholder.mit_id;

		return "";
	}

	getId(cardholder: PersonModel | ResultModel) {
		if (cardholder.kerberos_id) return cardholder.kerberos_id;

		if (cardholder.mit_id) return cardholder.mit_id;

		return "";
	}

	handleSearch = (query: string, element: React.KeyboardEvent<HTMLInputElement>): void => {
		clearTimeout(timerID);

		if (query.length < 1) {
			this.setState({ cardholders: [], isBusy: false });
			return;
		}

		//Check if backspace or delete
		if (element.keyCode === 8 || element.keyCode === 46) return;

		this.setState({
			isBusy: true,
		});

		timerID = setTimeout(() => {
			this.peopleController.abortRequest();

			if (query.length < 1) {
				this.setState({ cardholders: [], isBusy: false });
				return;
			}

			this.peopleController.getCardholdersByAlarmSearch(this.props.alarmId, query).then((cardholders: CardholderListModel | any) => {
				//check if status not 200 (null is return)
				if (cardholders === null) {
					this.setState({ isBusy: false });
					return;
				}

				//Check if type error
				if (cardholders && cardholders.name === "TypeError") {
					this.setState({ isBusy: false });
					return;
				}

				//Check if request was aborted
				if (cardholders && cardholders.name !== "AbortError") {
					const newData = cardholders.list_members.map((itm: PersonModel) => {
						if (typeof itm === "string")
							return {
								icon: "user",
								text: itm,
								secondaryText: itm,
								onClick: () => this.onChange(itm),
							};

						return {
							icon: "user",
							text: this.getText(itm),
							secondaryText: this.getId(itm),
							tertiaryText: this.stringProvider.capitalizeFirstLetter(itm.department),
							quaternaryText: itm.mit_id,
							onClick: () => this.onChange(this.getId(itm)),
						};
					});

					this.setState({
						cardholders: newData,
						isBusy: false,
					});
				}
			});
		}, WAIT_INTERVAL);
	};

	render() {
		const emptyData = {
			startIcon: "search",
			startIconAccessibilityText: "search",
			placeholderText: "Search Name, Kerb or MIT ID",
			type: TextboxType.IconStart,
			name: "specificRemovals",
		};

		const tableItems: TableRowProps[] = [
			{
				items: [
					this.getId(this.state.items.data),
					this.state.items.success ? <Icon icon="check" color="green" type="solid" /> : <Icon icon="times" color="red" type="solid" />,
					this.state.items.message,
				],
				state: "",
			},
		];

		const PopupData = withComponent(Modal);

		return (
			<Fragment>
				<Text content="Specific removals" type={TextType.Heading5} icon="" />
				{this.state.isPosting ? (
					<AutoComplete isLoading items={[]} name="" searchOptions={emptyData} />
				) : (
					<AutoComplete
						name="specificRemovalsAC"
						limit={20}
						searchOptions={emptyData}
						items={this.state.cardholders}
						isBusy={this.state.isBusy}
						onSearch={(q: any, e: any) => {
							this.handleSearch(q, e);
						}}
					/>
				)}
				<PopupData
					show={this.state.showSpecificRemovalsModal}
					onClose={() => this.setState({ showSpecificRemovalsModal: false })}
					padded={false}
					size={TemplateModalSize.Large}
					containerless={false}
					body={<Table columns={3} header={["ID", "Status", "Description"]} rows={tableItems} type={""} flush={true} />}
					footer={""}
					header={<Text content="Cardholder Removed" type={TextType.Heading4} icon="" />}
					name={"specificRemovalsModal"}
				/>
				<PopupData
					show={this.state.showSpecificRemovalsFailedModal}
					onClose={() => this.setState({ showSpecificRemovalsFailedModal: false })}
					size={TemplateModalSize.Large}
					containerless={false}
					body={<Error context={ErrorContext.Component} message={"Something went wrong"} type={ErrorType.Generic} />}
					footer=""
					header={<Text content="Cardholder Removed" type={TextType.Heading4} icon="" />}
					name={"specificRemovalsModalFailed"}
				/>
			</Fragment>
		);
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	actions: bindActionCreators(notificationActionCreator, dispatch),
});

export default connect(null, mapDispatchToProps)(SpecificRemovals);
