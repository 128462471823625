import React, { Fragment } from 'react';
import { Text, TextType, Spacer } from '@mit/hui';
import ClearanceController from '../../../api/ClearanceController';
import SpecificRemovals from './SpecificRemovals';
import BulkRemovals from './BulkRemovals';
import PeopleController from '../../../api/PeopleController';
import { withComponent } from '../../../common/WithComponent';
//@ts-ignore
import MediaQuery from 'react-responsive';

interface AccessManagementPeopleRemoveProps
{
    clearanceId: string;
}

export default class AccessManagementPeopleRemove extends React.Component<AccessManagementPeopleRemoveProps>
{
    clearanceController: ClearanceController;
    peopleController: PeopleController;

    constructor(props: AccessManagementPeopleRemoveProps)
    {
        super(props)

        this.clearanceController = new ClearanceController();
        this.peopleController = new PeopleController();
    }

    render()
    {
        const BulkRemovalsData = withComponent(
            BulkRemovals
        );

        const SpecificRemovalsData = withComponent(SpecificRemovals);

        return <Fragment>
            <Text content="Remove people" padded={true} type={TextType.Heading4} icon='user-minus' />
            <Spacer size="2" />
            <SpecificRemovalsData clearanceId={this.props.clearanceId} />
            <MediaQuery minWidth={577}>
                <Spacer size="3" />
                <BulkRemovalsData clearanceId={this.props.clearanceId} />
            </MediaQuery>
        </Fragment>
    }
}