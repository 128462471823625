import React, { FC, useState } from 'react';
import { Spacer, Button, ButtonProps, ButtonType, ButtonState, ListItem, TemplateModal, TemplateModalAlignment, TemplateModalSize, Text, TextType } from '@mit/hui'
import { ClearanceModel } from '../../../api/models/ClearanceModel'

interface UserClearanceProps {
    data: ClearanceModel[];
    onClick: (e: any, v: any) => void;
    onDelete: (v: any) => void;
    readOnly: boolean;
}

export const UserClearance: FC<UserClearanceProps> = (props) => {
    const [confirm, setConfirm] = useState<boolean>(false)
    // eslint-disable-next-line
    const [isBusyDeleting, setIsBusyDeleting] = useState<boolean>(false)
    const [clearanceId, setClearanceId] = useState<string>('')
    const [notAllowed, setNotAllowed] = useState<boolean>(false)

    const buttonActions = (id: string, allowed: any) => {

        if (props.readOnly)
            return [];

        const buttonData: ButtonProps[] = [
            { 
                icon: 'trash', 
                text: '', 
                type: ButtonType.TextNaked, 
                onClick: () => searchClearance(id), 
                state: allowed ? ButtonState.Enabled : ButtonState.Disabled 
            }
        ];
        return buttonData;
    }

    const searchClearance = (id: string) => {
        setClearanceId(id); 
        setConfirm(true)
    }

    return (
        <>
            {
                props.data.map((clearance: ClearanceModel, index: number) => {
                    return (
                        <ListItem 
                            key={index} 
                            text={clearance.name} 
                            icon="id-card" 
                            onClick={(e: any, v: any) => clearance.allowed ? props.onClick(e, v) : setNotAllowed(true)} 
                            actions={buttonActions(clearance.id, clearance.allowed)} 
                        />
                    )
                })
            }

            <TemplateModal
                bodyAlignment={TemplateModalAlignment.Left}
                show={notAllowed}
                onClose={() => setNotAllowed(false)}
                padded={false}
                size={TemplateModalSize.Default}
                body={
                    <div className="text-left p-4">
                        <Text content={'You do not have access to manage this clearance.'} type={TextType.Body} icon="" />
					</div>
                }
                footer={<Button text="Ok" onClick={() => setNotAllowed(false)} type={ButtonType.Primary}/>}
                header={<Text content={'No Access'} type={TextType.Heading4} icon="" />}
                name={"notAllowed"}
            />

            <TemplateModal
                bodyAlignment={TemplateModalAlignment.Left}
                show={confirm}
                onClose={() => setConfirm(false)}
                padded={false}
                size={TemplateModalSize.Small}
                body={
                    <div className="text-center">
						<Spacer size="2" />
						<span>Are you sure?</span>
						<Spacer size="2" />
						<div className="btn-padded">
							<Button text="No" onClick={() => setConfirm(false)} type={ButtonType.Ghost | ButtonType.Secondary} />
							<Button text="Yes" isBusy={isBusyDeleting} state={isBusyDeleting ? ButtonState.Disabled : ButtonState.Enabled} onClick={() => props.onDelete(clearanceId)} type={ButtonType.Primary} />
						</div>
						<Spacer size="2" />
					</div>
                }
                footer={<div />}
                header={<Text content={'Confirmation'} type={TextType.Heading4} icon="" />}
                name={"deleteClearance"}
            />
        </>
    )
}