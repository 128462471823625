import React from 'react';
import { FilteredList, TextboxType, Error, ErrorContext, ErrorType, ListItemState } from '@mit/hui';
import ClearanceController from '../../api/ClearanceController';
import { ClearanceModel } from '../../api/models/ClearanceModel';
import { FilteredListLoader } from '../../common/FilteredListLoader';
import History from "../../common/History";

interface ClearanceMasterProps
{
    onclick: any
    clearanceId: string;
}

interface ClearanceMasterState
{
    clearances: any;
    isLoading: boolean;
    isError: boolean;
    isEmpty: boolean;
}

export default class ClearanceMaster extends React.Component<ClearanceMasterProps, ClearanceMasterState>
{
    clearanceController: ClearanceController;

    constructor(props: ClearanceMasterProps)
    {
        super(props)

        this.clearanceController = new ClearanceController();

        this.state = {
            clearances: [],
            isLoading: true,
            isError: false,
            isEmpty: false
        }
    }

    componentWillUnmount()
    {
        this.clearanceController.abortRequest();
    }

    async componentDidMount()
    {
        const clearanceData = await this.clearanceController.getClearances();

        if (clearanceData && clearanceData.clearances.length > 0)
        {
            let defaultListData: any = [];

            clearanceData.clearances.map((itm: ClearanceModel) =>
            {
                const componentObject: any = {
                    icon: 'id-card',
                    text: itm.name,
                    id: itm.id,
                    state: this.props.clearanceId === itm.id ? ListItemState.Active : ListItemState.None,
                    onClick: () => this.onChange(itm.id)
                }

                return defaultListData.push(componentObject);
            });

            this.setState({
                clearances: defaultListData,
                isLoading: false
            });
        }
        else if (clearanceData === null)
        {
            this.setState({
                isLoading: false,
                isError: true
            })
        }
        else if (clearanceData.clearances.length === 0)
        {
            this.setState({
                isLoading: false,
                isEmpty: true
            })
        }
    }

    generateURL(itm: string)
    {
        const path = window.location.pathname;

        if (path === "/" || path === "/clearances/clearance-information")
        {
            return "/clearances/clearance-information/" + itm;
        }
        else if (path === "/clearances/access-management")
        {
            return "/clearances/access-management/" + itm;
        }
        else if (path === "/clearances/gatekeepers")
        {
            return "/clearances/gatekeepers/" + itm;
        }
        else if (path === "/clearances/departments")
        {
            return "/clearances/departments/" + itm;
        }
        else
        {
            const pathParts = window.location.pathname.split("/");

            pathParts.pop();
            pathParts.push(itm);
            return (pathParts.join('/'));
        }
    }

    onChange = (e: string): void =>
    {
        this.props.onclick(true);

        const newPath = this.generateURL(e);
        History.push(newPath);
    }

    emptyData =
        {
            startIcon: 'search',
            startIconAccessibilityText: 'search',
            placeholderText: 'Filter my clearances',
            type: TextboxType.IconStart,
            name: "clearanceFilter"
        }

    render()
    {
        if (this.state.isLoading)
            return <FilteredListLoader text="Loading your clearances" />

        if (this.state.isError)
            return <Error context={ErrorContext.Component} message="No data available for Clearances" type={ErrorType.NoData} />

        if (this.state.clearances.length > 0)
        {
            return <FilteredList name="filteredListClearance" loadingText="" isLoading={false} selectable={true} height={60} compact={true} searchOptions={this.emptyData} items={this.state.clearances} />
        }
        else
        {
            return <FilteredList name="filteredListClearance" isEmpty isLoading={false} selectable={true} height={60} compact={true} searchOptions={this.emptyData} items={this.state.clearances} />
        }
    }
}
