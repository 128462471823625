import React, { Fragment } from 'react';
import { Text, ContactCard, TemplateTwoColumns, TextType, Table, Spacer } from '@mit/hui';
import { withData } from '../../common/WithData';
import PeopleController from '../../api/PeopleController';
import GatekeeperView from '../clearances/components/GatekeeperView';
import AdminsView from '../clearances/components/AdminsView';

export default class AlarmGatekeepers extends React.Component<any>
{
    peopleController: PeopleController;

    constructor(props: any)
    {
        super(props)

        this.peopleController = new PeopleController();
    }

    render()
    {
        const GatekeeperViewData = withData(
            GatekeeperView,
            "Gatekeepers",
            async () => await this.peopleController.getGatekeepersByAlarmId(this.props.match.params.id),
            <Table type={""} flush={true} columns={4} header={[]} rows={[]} isLoading={true} />
        );

        const AdminsViewData = withData(
            AdminsView,
            "Space Admins",
            async () => await this.peopleController.getAdminsByAlarmId(this.props.match.params.id),
            <TemplateTwoColumns
                leftAlignment="center"
                leftArea={<ContactCard isLoading={true} name={""} department={""} company="" address={""} telephone={""} email={""} />}
                rightAlignment="center"
                rightArea={<ContactCard isLoading={true} name={""} department={""} company="" address={""} telephone={""} email={""} />}
                padded={false}
                primarySize={6}
                showDivider={false}
                showGutters={false}
            />
        );

        return (<Fragment>
            <Text content="Gatekeepers" type={TextType.Heading4} padded={true} icon='' />
            <GatekeeperViewData />
            <Spacer size={"2"} />
            <Text content="Space Administrators" type={TextType.Heading4} icon='' padded={true} />
            <AdminsViewData />
        </Fragment>);
    }
}