import React, { Fragment } from 'react'
import { TemplateTwoColumns } from '@mit/hui'
import { ScheduleRanges } from './components/ScheduleRanges'
import ScheduleEntities from './components/ScheduleEntities'

export default class ScheduleInformation extends React.Component<any> {
  render () {
    return (
      <TemplateTwoColumns
        padded={true}
        showDivider={true}
        showGutters={false}
        leftArea={
          <Fragment>
            <ScheduleEntities scheduleId={this.props.match.params.id} />
          </Fragment>
        }
        rightArea={<ScheduleRanges scheduleId={this.props.match.params.id} />}
        primarySize={6}
        leftAlignment={'left'}
        rightAlignment={'left'}
      />
    )
  }
}
