import React from 'react';
import { ActionList } from '@mit/hui'
import ClearanceController from '../../api/ClearanceController';
import ManageBuildingDetail from './components/ManageBuildingDetail';
import { withData } from '../../common/WithData';

interface UserClearanceBuildingsProps {
    data: any;
}

export default class UserClearanceBuildings extends React.Component<UserClearanceBuildingsProps> {
    clearanceController: ClearanceController;

    constructor(props: UserClearanceBuildingsProps) {
        super(props)

        this.clearanceController = new ClearanceController();
    } 

    render() {
        const ManageBuildingDetailData = withData(
            ManageBuildingDetail,
            "Buildings Detail",
            async () => await this.clearanceController.getAreas(this.props.data),
            <ActionList isLoading={true} />
        );

        return <ManageBuildingDetailData />
    }   
}