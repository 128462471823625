import { ErrorContext, ErrorType, TemplateTwoColumns, Error } from '@mit/hui';
import React from 'react';
import { connect } from 'react-redux';
import AccessManagementFooter from './AccessManagementFooter';
import AccessManagementPeopleAdd from './AccessManagementPeopleAdd';
import AccessManagementPeopleRemove from './AccessManagementPeopleRemove';
import { MultipleGroupsWarning } from './MulitpleGroupsWarring';

interface IncomingData {
    can_manage: boolean;
    count: number;
    groups: string[];
    managedGroup: string;
    name: string;
    id: string;
}

interface ComponentProps {
    clearanceId: string;
    data: IncomingData;
    roles: string[];
}

class AccessManagementView extends React.Component<ComponentProps, any>
{
    constructor(props: any) {
        super(props)
    }

    buildComponent() {

        if (this.props.data.can_manage) {
            return (
                <>
                    <MultipleGroupsWarning managedCardholder={this.props.data.managedGroup} cardholderGroups={this.props.data.groups} />
                    {
                        this.props.roles.includes('PSEC RO SUPER USER') ?
                            <></>
                            :
                            <TemplateTwoColumns
                                padded={true}
                                showDivider={true}
                                showGutters={false}
                                leftArea={<AccessManagementPeopleAdd clearanceId={this.props.clearanceId} />}
                                rightArea={<AccessManagementPeopleRemove clearanceId={this.props.clearanceId} />}
                                primarySize={6}
                                leftAlignment={"left"}
                                rightAlignment={"left"}
                            />
                    }
                </>
            );
        } else {
            return (
                <Error
                    context={ErrorContext.Component}
                    message={"You can not manage cardholders on this clearance because it is externally managed"}
                    type={ErrorType.Generic}
                />
            );
        }


    }

    render() {

        return <>
            <AccessManagementFooter clearanceId={this.props.clearanceId} />
            {this.buildComponent()}
        </>
    }
}

const mapStateToProps = (state: any) => ({
	roles: state.app.roles
});

export default connect(mapStateToProps)(AccessManagementView);