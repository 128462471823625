import React from "react";

export function withComponent(WrappedComponent: any): any 
{
    return class WithComponent extends React.Component<any>
    {
        render(): React.ReactNode 
        {
            return <WrappedComponent {...this.props} />
        }
    };
}