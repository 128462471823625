import React, { Fragment } from 'react';
import { Text, TextType, ActionList } from '@mit/hui';
import PermanentSchedule from './PermanentSchedule';
import ClearanceController from '../../../api/ClearanceController';
import { withData } from '../components/WithData';

interface ManageScheduleProps
{
    clearanceId: string;
}

export default class ManageSchedule extends React.Component<ManageScheduleProps>
{
    clearanceController: ClearanceController;

    constructor(props: any)
    {
        super(props)

        this.clearanceController = new ClearanceController();
    }

    render()
    {
        const PermanentScheduleData = withData(
            PermanentSchedule,
            "Schedules",
            async () => await this.clearanceController.getSchedulesAndStatus(this.props.clearanceId),
            <ActionList isLoading={true} />,
            "clearance"
        )

        return <Fragment>
            <Text content="Permanent Schedules" padded={true} type={TextType.Heading4} icon='' />
            <PermanentScheduleData />
        </Fragment>
    }
}