import React, { Fragment } from 'react';
import { ActionList, Error, ErrorContext, ErrorType, ListItemProps, AccordionItem, AccordionItemState, ListItemState } from '@mit/hui';
import StringProvider from '../../../services/StringProvider';
import { SchedulesModel } from '../../../api/models/SchedulesModel';
import { ScheduledUnlockBody, UnlockDateTime } from '../../../api/models/ScheduledUnlockBody';

interface PermanentScheduleProps
{
    data: SchedulesModel;
}

export default class PermanentSchedule extends React.Component<PermanentScheduleProps>
{
    stringProvider: StringProvider;

    constructor(props: PermanentScheduleProps)
    {
        super(props)

        this.stringProvider = new StringProvider();
    }

    getUser(item: ScheduledUnlockBody)
    {
        if (item.updated_by)
            return item.updated_by

        if (item.created_by)
            return item.created_by

        return ""
    }

    render()
    {
        let permanentScheduleData: any = [];

        if (!this.props.data && this.props.data !== 404)
            return <Error context={ErrorContext.Component} message={"No data available for schedules"} type={ErrorType.NoData} retry={true} />

        if (this.props.data && this.props.data.schedules)
            // eslint-disable-next-line
            this.props.data.schedules.map((itm: ScheduledUnlockBody) =>
            {
                //Check weekly 
                if (itm.frequency === "weekly")
                {
                    if (itm.is_updatable)
                    {
                        const mappedData: ListItemProps = {
                            text: this.stringProvider.capitalizeFirstLetter(itm.frequency),
                            secondaryText: this.stringProvider.toTimeFormat(itm.unlock_dates_and_times[0].times ? itm.unlock_dates_and_times[0].times[0].start_time : "") + " - " + this.stringProvider.toTimeFormat(itm.unlock_dates_and_times[0].times ? itm.unlock_dates_and_times[0].times[0].end_time : ""),
                            tertiaryText: itm.comment ? itm.comment : "",
                            quaternaryText: this.getUser(itm)
                        }

                        return permanentScheduleData.push(mappedData);
                    }
                    else
                    {
                        const item: ListItemProps = {
                            icon: "door-open",
                            text: this.stringProvider.capitalizeFirstLetter(itm.frequency) + ` (${itm.unlock_dates_and_times.map((itm: any) => { return itm.day.substring(0, 3) }).join(",")})`,
                            secondaryText: this.stringProvider.toTimeFormat(itm.unlock_dates_and_times[0].times[0].start_time) + " - " + this.stringProvider.toTimeFormat(itm.unlock_dates_and_times[0].times[0].end_time),
                            tertiaryText: itm.comment,
                            quaternaryText: this.getUser(itm),
                            template: () =>
                            {
                                let accItems = itm.unlock_dates_and_times.map((dateTime: UnlockDateTime, unlockIndex: number) =>
                                {
                                    return { text: dateTime.day, secondaryText: this.stringProvider.toTimeFormat(dateTime.times[0].start_time) + " - " + this.stringProvider.toTimeFormat(dateTime.times[0].end_time), tertiaryText: itm.comment, quaternaryText: this.getUser(itm), icon: "door-open", onClick: () => null, state: ListItemState.None }
                                });

                                return <AccordionItem selectable={false} icon="door-open" state={AccordionItemState.Expanded} title={this.stringProvider.capitalizeFirstLetter(itm.frequency) + ` (${itm.unlock_dates_and_times.map((itm: any) => { return itm.day.substring(0, 3) }).join(",")})`} items={accItems} />
                            }
                        }

                        permanentScheduleData.push(item);
                    }
                }

                //Check daily 
                if (itm.frequency === "daily")
                {
                    const mappedData: ListItemProps = {
                        text: this.stringProvider.capitalizeFirstLetter(itm.frequency),
                        secondaryText: this.stringProvider.toTimeFormat(itm.unlock_dates_and_times[0].times && itm.unlock_dates_and_times[0].times.length > 0 ? itm.unlock_dates_and_times[0].times[0].start_time : "") + " - " + this.stringProvider.toTimeFormat(itm.unlock_dates_and_times[0].times && itm.unlock_dates_and_times[0].times.length > 0 ? itm.unlock_dates_and_times[0].times[0].end_time : ""),
                        tertiaryText: itm.comment ? itm.comment : "",
                        quaternaryText: this.getUser(itm)
                    }

                    return permanentScheduleData.push(mappedData);
                }
                // eslint-disable-next-line
                itm.unlock_dates_and_times.map((dateTimes: UnlockDateTime) =>
                {
                    //Check specific 
                    if (itm.frequency === "specific")
                    {
                        const mappedData: ListItemProps = {
                            text: this.stringProvider.toLongDate(dateTimes.date),
                            secondaryText: this.stringProvider.toTimeFormat(dateTimes.times ? dateTimes.times[0].start_time : "") + " - " + this.stringProvider.toTimeFormat(dateTimes.times ? dateTimes.times[0].end_time : ""),
                            tertiaryText: itm.comment ? itm.comment : "",
                            quaternaryText: this.getUser(itm)
                        }

                        return permanentScheduleData.push(mappedData);
                    }

                    //Check ordinal 
                    if (itm.frequency === "ordinal")
                    {
                        const mappedData: ListItemProps = {
                            text: dateTimes.date,
                            secondaryText: dateTimes.times ? dateTimes.times[0] : "",
                            tertiaryText: itm.comment ? itm.comment : "",
                            quaternaryText: this.getUser(itm)
                        }

                        return permanentScheduleData.push(mappedData);
                    }
                });
            })

        return <Fragment>
            {
                permanentScheduleData.length > 0
                    ?
                    <ActionList isLoading={false} compact={true} items={permanentScheduleData} type={1} selectable={false} />
                    :
                    <Error context={ErrorContext.Component} message="No Schedules data available" type={ErrorType.NoData} />
            }
        </Fragment>
    }
}