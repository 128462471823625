import React, { FC, useState } from "react";
import { Text, ListItem, Drawer, DrawerType, DrawerLayout, DrawerSize, TextType, ActionList, ErrorContext, ErrorType, Error } from "@mit/hui";
import { DepartmentsModel } from "../../../api/models/DepartmentsModel";
import DepartmentController from '../../../api/DepartmentController';

interface DepartmentViewReadOnlyProps {
    data: DepartmentsModel
}

interface DepartmentAlarmResponse {
    alarms: Alarm[]
}

interface Alarm {
    id: string;
    name: string;
    can_manage: boolean | null;
}

export const DepartmentViewReadOnly: FC<DepartmentViewReadOnlyProps> = (props) => {

    const [loadingDrawerContents, setLoadingDrawerContents] = useState<boolean>(false);
    const [departmentAlarms, setdepartmentAlarms] = useState<DepartmentAlarmResponse>();
    const [selectedDepartment, setSelectedDepartment] = useState<string>("");
    const [showDrawer, setShowDrawer] = useState<boolean>(false);


    function buildDepartmentList(data: string[]) {

        if (data.length > 0) {
            let listElement = data.map(item => {
                return <ListItem onClick={() => openDepartmentClearanceList(item)} text={item} />
            })
    
            return listElement;
        }
        
        return <Error context={ErrorContext.Component} message={"There are no departments assigned to this alarm"} type={ErrorType.NoData} />
    };

    async function openDepartmentClearanceList(departmentId: string) {

        setSelectedDepartment(departmentId);
        setLoadingDrawerContents(true);
        setShowDrawer(true);

        try {
            // Fetch clearances linked with provided department
            const departmentController = new DepartmentController();
            const departmentAlarms = await departmentController.getAlarms(departmentId);
            setdepartmentAlarms(departmentAlarms);

            // Disable loading
            setLoadingDrawerContents(false);
        } catch (error) {
            console.error(error);
        }
    };

    function getMappedClearanceItems(clearanceResponse: DepartmentAlarmResponse | undefined) {

        if (clearanceResponse === undefined) {
            return [];
        }

        if (!clearanceResponse.alarms) {
            return [];
        }

        const mappedResponse = clearanceResponse.alarms.map(item => {
            return {
                text: item.name,
                icon: 'id-card'
            }
        });

        return mappedResponse;
    }

    return (
        <>
            {buildDepartmentList(props.data.departments)}
            <Drawer
                contents={
                    <div>
                        <Text content={selectedDepartment} />
                        {
                            loadingDrawerContents ?
                                <ActionList isLoading items={[]} />
                                :
                                <ActionList
                                    selectable={false}
                                    items={getMappedClearanceItems(departmentAlarms)}
                                />
                        }

                    </div>
                }
                onClose={() => setShowDrawer(false)}
                show={showDrawer}
                type={DrawerType.Right}
                layout={DrawerLayout.Clean}
                size={DrawerSize.Small}
                footer={[]}
                header={
                    <Text content="Department Clearances" bold type={TextType.Heading3} />
                }
            />
        </>
    );
}

