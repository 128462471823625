import React from "react";
import { FilteredList, TextboxType, Error, ErrorContext, ErrorType, ListItemState } from "@mit/hui";
import { ClearanceModel } from "../../api/models/ClearanceModel";
import { FilteredListLoader } from "../../common/FilteredListLoader";
import AlarmController from "../../api/AlarmController";
import History from "../../common/History";

interface AlarmMasterProps {
	onclick: any;
	alarmId: string;
}

interface AlarmMasterState {
	alarms: any;
	isLoading: boolean;
	isError: boolean;
	isEmpty: boolean;
}

export class AlarmMaster extends React.Component<AlarmMasterProps, AlarmMasterState> {
	alarmController: AlarmController;

	constructor(props: AlarmMasterProps) {
		super(props);

		this.alarmController = new AlarmController();

		this.state = {
			alarms: [],
			isLoading: true,
			isError: false,
			isEmpty: false,
		};
	}

	componentWillUnmount() {
		this.alarmController.abortRequest();
	}

	async componentDidMount() {
		const alarmData = await this.alarmController.getAlarms();

		if (alarmData && alarmData.alarms.length > 0) {
			let defaultListData: any = [];

			if(this.props.alarmId)
			{
				const alarmInfo = alarmData.alarms.find((itm: any) => itm.id === this.props.alarmId)

				if(alarmInfo)
				{
					this.props.onclick(alarmInfo);
				}
			}

			alarmData.alarms.map((itm: ClearanceModel) => {
				const componentObject: any = {
					icon: "siren",
					text: itm.name,
					id: itm.id,
					state: this.props.alarmId === itm.id ? ListItemState.Active : ListItemState.None,
					onClick: () => this.onChange(itm),
				};

				return defaultListData.push(componentObject);
			});

			this.setState({
				alarms: defaultListData,
				isLoading: false,
			});
		} else if (alarmData === null) {
			this.setState({
				isLoading: false,
				isError: true,
			});
		} else if (alarmData.alarms.length === 0) {
			this.setState({
				isLoading: false,
				isEmpty: true,
			});
		}
	}

	generateURL(itm: string) {
		const path = window.location.pathname;

		if (path === "/" || path === "/alarms/area-information") {
			return "/alarms/area-information/" + itm;
		} else if (path === "/alarms/access-management") {
			return "/alarms/access-management/" + itm;
		} else if (path === "/alarms/gatekeepers") {
			return "/alarms/gatekeepers/" + itm;
		} else if (path === "/alarms/departments") {
			return "/alarms/departments/" + itm;
		} else {
			const pathParts = window.location.pathname.split("/");

			pathParts.pop();
			pathParts.push(itm);
			return pathParts.join("/");
		}
	}

	onChange = (e: ClearanceModel): void => {
		this.props.onclick(e);

		const newPath = this.generateURL(e.id);
		History.push(newPath);
	};

	emptyData = {
		startIcon: "search",
		startIconAccessibilityText: "search",
		placeholderText: "Filter my alarms",
		type: TextboxType.IconStart,
		name: "alarmFilter",
	};

	render() {
		if (this.state.isLoading) return <FilteredListLoader text="Loading your alarms" />;

		if (this.state.isError) return <Error context={ErrorContext.Component} message="No data avaialble for Alarms" type={ErrorType.NoData} />;

		if (this.state.alarms.length > 0) {
			return <FilteredList name="filteredListAlarms" isLoading={false} selectable={true} height={60} compact={true} searchOptions={this.emptyData} items={this.state.alarms} />;
		} else {
			return <Error message={"No Alarms available"} context={ErrorContext.Component} type={ErrorType.NoData} />;
		}
	}
}
