import React, { Fragment } from 'react';
import { Profile, DropdownItemProps, DropDownState } from '@mit/hui';
import { connect } from 'react-redux';
import AuthProvider from '../../services/AuthProvider';

class ProfileView extends React.Component<any>
{
    authProvider: AuthProvider;

    constructor(props : any)
    {
        super(props)

        this.authProvider = new AuthProvider();
    }

    render()
    {
        const subMenuData: DropdownItemProps[] = [
            {
                icon: 'sign-out', text: 'Logout', state: DropDownState.None, onClick: () =>
                {
                    this.authProvider.logout().then((data) => { });
                }
            },
        ];

        return <Fragment>
            <Profile        
            name={this.props.user.display_name ? " " + this.props.user.display_name : ""} 
            subtitle={this.props.user.department} imageUrl={this.props.picture ? this.props.picture : "/images/MIT-red.svg"} 
            submenu={subMenuData} />
        </Fragment>
    }
}

const mapStateToProps = (state: any) => ({
    user: state.app.user,
    picture: state.app.picture
});

export default connect(mapStateToProps)(ProfileView)