import { ListItem, ListItemState, ErrorContext, ErrorType, Error } from '@mit/hui';
import React, { Fragment } from 'react';

interface Clearance {
    id: string;
    name: string;
}

interface DoorClearanceData {
    clearances: Clearance[];
    door: string;
}

interface DoorClearancesProps {
    data: DoorClearanceData;
}

export default class DoorClearances extends React.Component<DoorClearancesProps, any> {

    logFunction() {
        console.log(this.props.data);
    }

    render() {
        this.logFunction()
        return (
            <Fragment>
                {
                    this.props.data.clearances.length > 0 ?
                    this.props.data.clearances.map(item => {
                        return (<ListItem icon="id-card" text={item.name} state={ListItemState.None} />)
                    })
                    :
                    <Error context={ErrorContext.Component} message={"No data available for clearances"} type={ErrorType.NoData} />
                }
            </Fragment>
        );
    }
}