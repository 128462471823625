import React from 'react'
import { ContactCard } from '@mit/hui'
import { PersonModel } from '../../../api/models/PersonModel'
import HomeController from '../../../api/HomeController'

interface AdminEntityViewProps {
  data: PersonModel
  person: boolean
}

interface AdminEntityViewState {
  image: string
}

export default class AdminEntityView extends React.Component<AdminEntityViewProps, AdminEntityViewState> {
  homeController: HomeController

  constructor (props: AdminEntityViewProps) {
    super(props)

    this.homeController = new HomeController()

    this.state = {
      image: ''
    }
  }

  async componentDidMount () {
    if (this.props.data.kerberos_id)
      this.homeController.getPictureByKerb(this.props.data.kerberos_id).then((data: string) => {
        if (data) this.setState({ image: data })
      })
  }

  render () {
    return (
      <ContactCard
        isPerson={this.props.person}
        profilePicUrl={this.state.image ? this.state.image : ''}
        name={this.props.data.display_name}
        department={this.props.data.department}
        company=''
        address={this.props.data.office_location ? this.props.data.office_location : ' - '}
        telephone={this.props.data.phone_number ? this.props.data.phone_number : ' - '}
        email={this.props.data.email ? this.props.data.email : ' - '}
      />
    )
  }
}
