import { store } from "../ts/redux/store/store";
import BaseController from "./BaseController";
export const globalStore = store;

class DepartmentController extends BaseController
{
    //GET
    getClearances = async (departmentId: string): Promise<any> =>
    {
        return await this.fetchData(this.baseUrl, `/departments/${departmentId}/clearances`);
    }

    getAlarms = async (departmentId: string): Promise<any> =>
    {
        return await this.fetchData(this.baseUrl, `/departments/${departmentId}/alarms`);
    }

    getSchedules = async (departmentId: string): Promise<any> =>{
        return await this.fetchData(this.baseUrl, `/departments/${departmentId}/schedules`);
    }

    updateClearanceSpecialHierachyTag = async (clearanceId: string, specialAccessRule: boolean): Promise<any> => 
    {
        let response = await this.postData(this.baseUrl, `/clearances/${clearanceId}/basic_info`, {specialAccessRule: specialAccessRule});
        if(response !== null) {
            return response;
        } else {
            throw new Error("Unkown error occured");
        }   
    }

    updateSCheduleSpecialHierarchyTag = async (scheduledId: string, specialSchedule: boolean): Promise<any> => 
    {
        let response = await this.postData(this.baseUrl, `/schedules/${scheduledId}/basic-info`, {specialSchedule: specialSchedule});
        if(response !== null) {
            return response;
        } else {
            throw new Error("Unkown error occured");
        }   
    }

    updateAlarmSpecialHierachyTag = async (alarmId: string, specialAlarm: boolean): Promise<any> => 
    {
        let response = await this.postData(this.baseUrl, `/alarms/${alarmId}/basic-info`, {specialAlarm: specialAlarm});
        if(response !== null) {
            return response;
        } else {
            throw new Error("Unkown error occured");
        } 
    }
}

export default DepartmentController;