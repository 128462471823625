import { store } from "../ts/redux/store/store";
import { BuildingsModel } from "./models/BuildingsModel";
import BaseController from "./BaseController";
import { DoorsEntityModel } from "./models/DoorsEntityModel";
import { ScheduledUnlockBody } from "./models/ScheduledUnlockBody";
import { DoorSummaryModel } from "./models/DoorSummaryModel";
export const globalStore = store;

class EntitiesController extends BaseController
{
    //GET
    getDoors = async (): Promise<DoorsEntityModel> =>
    {
        return await this.fetchData(this.baseUrl, "/entities/doors");
    }

    getElevators = async (): Promise<DoorsEntityModel> =>
    {
        return await this.fetchData(this.baseUrl, "/entities/elevators");
    }

    getDoorRecentActivity = async (doorId: string): Promise<DoorSummaryModel> =>
    {
        return await this.fetchData(this.baseUrl, "/entities/doors/" + doorId + "/recent_activity")
    }

    getDoorSchedules = async (doorId: string): Promise<DoorSummaryModel> =>
    {
        return await this.fetchData(this.baseUrl, "/entities/doors/" + doorId + "/schedules")
    }

    getDoorClearances = async (doorId: string): Promise<any> =>
    {
        return await this.fetchData(this.baseUrl, "/entities/doors/" + doorId + "/clearances")
    }

    getElevatorInfo = async (elevatorId: string): Promise<DoorsEntityModel> =>
    {
        return await this.fetchData(this.baseUrl, "/entities/elevators/" + elevatorId)
    }

    getDoorsByKerb = async (): Promise<BuildingsModel> =>
    {
        return await this.fetchData(this.baseUrl, "/entities/doors", { kerberos_id: globalStore.getState().app.user.kerberos })
    }

    getElevatorsByKerb = async (): Promise<BuildingsModel> =>
    {
        return await this.fetchData(this.baseUrl, "/entities/elevators", { kerberos_id: globalStore.getState().app.user.kerberos })
    }

    //POST
    momentarilyUnlock = async (doorId: string): Promise<any> =>
    {
        return await this.postData(this.baseUrl, "/entities/doors/" + doorId + "/momentarily_unlock")
    }

    addupdate_scheduledUnlock = async (doorId: string, body: ScheduledUnlockBody): Promise<any> =>
    {
        return await this.postData(this.baseUrl, "/entities/doors/" + doorId + "/scheduled_unlock", body)
    }

    //DELETE
    delete_scheduledUnlock = async (doorId: string, schedule_id: string ): Promise<any> =>
    {
        let body = {
            "schedule_id": schedule_id
        }

        return await this.deleteData(this.baseUrl, "/entities/doors/" + doorId + "/scheduled_unlock", body )
    }
}

export default EntitiesController;