const appConfig = {
  "version": "1.4.0-develop+codebuild.79",
  "stage": {
    "name": "develop",
    "awsAccountId": "089727117237",
    "awsProfileName": "epi-api-gateway-dev",
    "branchName": "develop",
    "branchSafeName": "develop",
    "branchWebUrl": "https://github.mit.edu/ist-org/physical-security-web/tree/develop",
    "isCoreBranch": true
  },
  "amplify": {
    "userPoolId": "us-east-1_14cFcpuS8",
    "userPoolWebClientId": "4nmbp6sir3r63cch0eksnc4ooa",
    "oauth": {
      "domain": "atlas-auth-dev.mit.edu",
      "scope": [
        "openid",
        "profile",
        "physical-security/gatekeeper",
        "digital-id/user",
        "digital-id/search",
        "feedback/user",
        "notification/user",
        "user/all"
      ],
      "redirectSignIn": "https://physicalsecurity-dev.mit.edu",
      "redirectSignOut": "https://physicalsecurity-dev.mit.edu/logout",
      "responseType": "token",
      "options": {
        "AdvancedSecurityDataCollectionFlag": false
      }
    }
  },
  "signInProvider": "Touchstone",
  "websocketUrl": "wss://wss-api-dev.mit.edu/notification-v1",
  "atlasFeatureAppId": "physical.security#schedules",
  "api": {
    "hostname": "https://api-dev.mit.edu",
    "paths": {
      "physical-security": "physical-security-v1",
      "digital-id": "digital-id-v1",
      "atlas-ui": "atlas-ui-v1"
    }
  }
};
export default appConfig;