import React from "react";
import { TemplateMasterDetail, MobileView } from "@mit/hui";
import { AlarmMaster } from "./AlarmMaster";
import AlarmDetail from "./AlarmDetail";
import { ClearanceModel } from "../../api/models/ClearanceModel";
import AlarmController from "../../api/AlarmController";
import AlarmNavBar from "./components/AlarmNavBar";

interface AlarmsState {
	view: number;
	selectedAlarm: ClearanceModel;
	badge: any;
}

interface AlarmsProps {
	alarm?: string;
	match: any;
	roles?: string[];
}

class Alarms extends React.Component<AlarmsProps, AlarmsState> {
	alarmController: AlarmController;

	constructor(props: any) {
		super(props);

		this.alarmController = new AlarmController();

		this.state = {
			view: 1,
			badge: null,
			selectedAlarm: { isOnline: true, id: "", name: "" },
		};
	}

	componentDidMount() {
		this.setState({
			view: this.props.match.params.id ? MobileView.Detail : MobileView.Master,
		});
	}

	onItemClick(itm: ClearanceModel) {
		this.setState({ view: 2, selectedAlarm: itm, badge: null });
	}

	render() {
		return (
			<TemplateMasterDetail
				mobileView={this.state.view}
				startState={true}
				onBack={() => this.setState({ view: 1 })}
				master={<AlarmMaster alarmId={this.props.match.params.id} onclick={(alarm: ClearanceModel) => this.onItemClick(alarm)} />}
				masterTitle="My Alarms"
				navigation={<AlarmNavBar match={this.props.match} selectedAlarm={this.state.selectedAlarm}/>}
				detail={<AlarmDetail alarmId={this.props.match.params.id} />}
				detailTitle={this.props.match.params.id ? this.props.match.params.id.replace("gsc-alarm-", "").replace("_group", "") : ""}
			/>
		);
	}
}

export default Alarms;
