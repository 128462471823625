import React from 'react'
import { ActionList, ContactCard } from '@mit/hui'
import ClearanceController from '../../api/ClearanceController'
import PeopleController from '../../api/PeopleController'
import HomeController from '../../api/HomeController'
import { withData } from '../../common/WithData'
import UserClearanceDetail from './components/UserClearanceDetail'
import { ProfileModel } from '../../api/models/ProfileModel'
import UserProfile from './components/UserProfile'

interface PeopleClearanceState {
  user: ProfileModel
  profilePicture: any
  isLoading: boolean
  clearanceName: string
  clearanceId: string
  clearances: any
  show: boolean
}

export default class PeopleClearance extends React.Component<any, PeopleClearanceState> {
  clearanceController: ClearanceController
  peopleController: PeopleController
  homeController: HomeController

  constructor (props: any) {
    super(props)

    this.clearanceController = new ClearanceController()
    this.peopleController = new PeopleController()
    this.homeController = new HomeController()

    this.state = {
      user: {
        kerberos: '',
        first_name: '',
        last_name: '',
        display_name: ''
      },
      profilePicture: '',
      isLoading: true,
      clearanceName: '',
      clearanceId: '',
      clearances: [],
      show: false
    }
  }

  componentDidMount () {
    this.getUserProfile()
  }

  componentDidUpdate (prevProps: any, prevState: any) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.getUserProfile()
    }
  }

  async getUserProfile () {
    this.setState({ isLoading: true })

    if (this.props.match.params.id && this.props.match.params.id.substring(0, 3) === '333') {
      const [profile, picture] = await Promise.all([
        this.homeController.getCsGoldProfile(this.props.match.params.id),
        this.homeController.geCSGoldPicture(this.props.match.params.id)
      ])
      setTimeout(() => {
        this.setState({
          user: profile.data,
          profilePicture: picture,
          isLoading: false
        })
      }, 1000)
    } else {
      try {
        const [profile, picture] = await Promise.all([this.homeController.getProfile(this.props.match.params.id), this.homeController.getPictureByKerb(this.props.match.params.id)])
        setTimeout(() => {
          this.setState({
            user: profile,
            profilePicture: picture,
            isLoading: false
          })
        }, 1000)
      } catch (err) {
        console.error(err)
      }
    }
  }

  getClearanceInfo (e: any, value: any) {
    const clearanceId = `gsc-clearance-${value.text}`
    this.setState({
      clearanceName: value.text,
      clearanceId: clearanceId
    })
  }

  render () {
    const UserClearanceDetailData = withData(
      UserClearanceDetail,
      'Clearance Information',
      async () => await this.peopleController.getClearanceByUserId(this.props.match.params.id),
      <ActionList isLoading={true} />
    )

    return (
      <>
        {/* <UserProfileData /> */}
        {this.state.isLoading ? (
          <ContactCard name={`loading`} isPerson={false} profilePicIcon={'url'} affiliation={'N/A'} department={'N/A'} telephone={'N/A'} email={'N/A'} isLoading={true} />
        ) : (
          <UserProfile data={{ user: this.state.user, profilePicture: this.state.profilePicture }} />
        )}

        {/* <Spacer /> */}
        <UserClearanceDetailData user={this.state.user} />
      </>
    )
  }
}
