import React, { Fragment } from 'react';
import { Text, Spacer, TextType, ContactCard } from '@mit/hui';
import { withData } from '../../common/WithData';
import AlarmController from '../../api/AlarmController';
import ContactInfoView from './components/ContactInfoView';


 class AlarmContactInfo extends React.Component<any>
{
    alarmController: AlarmController;

    constructor(props: any)
    {
        super(props)

        this.alarmController = new AlarmController();
     }

    render()
    {
        const ContactInfoData = withData(
            ContactInfoView,
            "Contact Info",
            async () => await this.alarmController.getContacts(this.props.match.params.id),
            <Fragment>
                <ContactCard isLoading name="" address="" company="" affiliation="" department="" email="" telephone="" />
                <Spacer/>
                <ContactCard isLoading name="" address="" company="" affiliation="" department="" email="" telephone="" />
            </Fragment>
        );

        return <Fragment>
            <Text content="Contact Info" type={TextType.Heading4} padded={true} icon='' />
            <ContactInfoData alarmId={this.props.match.params.id} />
        </Fragment>
    }
}

export default AlarmContactInfo;