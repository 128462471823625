import React from 'react';
import { TemplateModalSize, Text, TextType, NavBar, NavItemState, NavBarTypes, ResponsiveTypes } from '@mit/hui';
import { CardHoldersModel } from '../../../api/models/CardHoldersModel';
import PeopleController from '../../../api/PeopleController';
import FullListView from './FullListView';
import { withComponent } from '../../../common/WithComponent';
import Modal from '../../../common/Modal';
import PersonExcelFileExport from '../../../common/PersonExcelFileExport';
import SubListView from './SubListView';
//@ts-ignore
import MediaQuery from 'react-responsive';
import { connect } from 'react-redux';

interface IAccessManagementHeaderProps
{
    alarmId: string;
    roles: string[];
}

interface IAccessManagementHeaderState
{
    cardholders: CardHoldersModel;
    isLoading: boolean;
    isLoadingCardholder: boolean;
    isLoadingSublist: boolean;
    showBaseList: boolean;
    showSublist: boolean;
}

class AccessManagementHeader extends React.Component<IAccessManagementHeaderProps, IAccessManagementHeaderState>
{
    peopleController: PeopleController;

    constructor(props: IAccessManagementHeaderProps)
    {
        super(props)

        this.peopleController = new PeopleController();

        this.state = {
            cardholders: { list_members: [], clearance_id: "", sub_lists: [] },
            isLoading: false,
            isLoadingCardholder: false,
            isLoadingSublist: false,
            showBaseList: false,
            showSublist: false
        }
    }

    render()
    {
        let defaultTabData = [
            {
                icon: 'users',
                iconOnly: false,
                text: this.state.isLoadingCardholder ? "Retrieving Cardholders" : 'View Cardholders List',
                state: this.state.isLoading ? NavItemState.Disabled : NavItemState.None,
                onClick: () =>
                {
                    this.setState({
                        isLoadingCardholder: true,
                        isLoading: true
                    })

                    this.peopleController.getCardholdersByAlarmId(this.props.alarmId).then((data) =>
                    {
                        this.setState({
                            cardholders: data,
                            isLoadingCardholder: false,
                            isLoading: false,
                            showBaseList: true
                        })
                    })
                }
            },
            {
                icon: 'bars',
                iconOnly: false,
                text: this.state.isLoadingSublist ? "Retrieving Sub Lists" : 'View Sub Lists',
                state: this.state.isLoading ? NavItemState.Disabled : NavItemState.None,
                onClick: () =>
                {
                    this.setState({
                        isLoadingSublist: true,
                        isLoading: true
                    })

                    if (this.state.cardholders && this.state.cardholders.sub_lists && this.state.cardholders.sub_lists.length > 0)
                    {
                        this.setState({
                            isLoadingSublist: false,
                            isLoading: false,
                            showSublist: true
                        })

                        return;
                    }

                    this.peopleController.getCardholdersByAlarmId(this.props.alarmId).then((data) =>
                    {
                        this.setState({
                            cardholders: data,
                            isLoadingSublist: false,
                            isLoading: false,
                            showSublist: true
                        })
                    })
                }
            }
        ]

        if (!this.props.roles.includes('PSEC RO SUPER USER')) {
            defaultTabData.push({
                icon: 'file-excel',
                iconOnly: false,
                text: "Bulk template",
                state: NavItemState.None,
                onClick: () =>
                {
                    window.location.href = '/templates/Bulk_Upload_Template.xlsx';
                    return null;
                }
            })
        }

        let defaultTabDataMobile = [
            {
                icon: 'users',
                iconOnly: false,
                text: this.state.isLoadingCardholder ? "Retrieving Cardholders" : 'View Cardholders List',
                state: this.state.isLoading ? NavItemState.Disabled : NavItemState.None,
                onClick: () =>
                {
                    this.setState({
                        isLoadingCardholder: true,
                        isLoading: true
                    })

                    this.peopleController.getCardholdersByAlarmId(this.props.alarmId).then((data) =>
                    {
                        this.setState({
                            cardholders: data,
                            isLoadingCardholder: false,
                            isLoading: false,
                            showBaseList: true
                        })
                    })
                }
            },
            {
                icon: 'bars',
                iconOnly: false,
                text: this.state.isLoadingSublist ? "Retrieving Sub Lists" : 'View Sub Lists',
                state: this.state.isLoading ? NavItemState.Disabled : NavItemState.None,
                onClick: () =>
                {
                    this.setState({
                        isLoadingSublist: true,
                        isLoading: true
                    })

                    if (this.state.cardholders && this.state.cardholders.sub_lists && this.state.cardholders.sub_lists.length > 0)
                    {
                        this.setState({
                            isLoadingSublist: false,
                            isLoading: false,
                            showSublist: true
                        })

                        return;
                    }

                    this.peopleController.getCardholdersByAlarmId(this.props.alarmId).then((data) =>
                    {
                        this.setState({
                            cardholders: data,
                            isLoadingSublist: false,
                            isLoading: false,
                            showSublist: true
                        })
                    })
                }
            }
        ]

        const PopupData = withComponent(Modal);

        return (
            <React.Fragment>
                <MediaQuery minWidth={577}>
                    <NavBar tabs={defaultTabData} type={NavBarTypes.IconText} responsiveType={ResponsiveTypes.None} />
                    <hr />
                </MediaQuery>
                <MediaQuery maxWidth={576}>
                    <NavBar tabs={defaultTabDataMobile} type={NavBarTypes.IconText} responsiveType={ResponsiveTypes.None} />
                    <hr />
                </MediaQuery >
                <PopupData
                    show={this.state.showBaseList}
                    onClose={() => this.setState({ showBaseList: false })}
                    containerless={true} size={TemplateModalSize.ExtraLarge} body={<FullListView data={this.state.cardholders} />} footer={<PersonExcelFileExport data={this.state.cardholders} fileName={this.state.cardholders ? this.props.alarmId : "Cardholder List"} sheetName={"cardholders"} />} header={<Text content="Base Cardholder(s)" type={TextType.Heading4} icon="" />} name={"baseListPopup"} />
                <PopupData
                    show={this.state.showSublist}
                    onClose={() => this.setState({ showSublist: false })}
                    containerless={false} size={TemplateModalSize.Large} body={<SubListView data={this.state.cardholders} />} footer={""} header={<Text content="Sub List(s)" type={TextType.Heading4} icon="" />} name={"subListPopup"} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: any) => ({
	roles: state.app.roles
});

export default connect(mapStateToProps)(AccessManagementHeader);