import React from 'react'
import { Error, ErrorContext, ErrorType } from '@mit/hui'
import { LoadingIndicator } from '../../../common/LoadingIndicator'
import { ResponseModal } from '../../../api/models/ResponseModel'

export function withData (WrappedComponent: any, title: string, selectData: any, LoadingChild?: any, entity: string = 'clearance'): any {
  interface WithDataState {
    data: any
    status: number
  }

  return class WithData extends React.Component<any, WithDataState> {
    async componentDidMount (): Promise<void> {
      const data: ResponseModal = await selectData(this.props)
      this.setState({ data: data.data, status: data.status })
    }

    getComponent (): React.ReactNode {
      let status = this.state.status

      let returnComponent = <Error context={ErrorContext.Component} message={'No data available for ' + title} type={ErrorType.NoData} />

      if (!this.state.data && this.state.status === 200) {
        return returnComponent
      }

      switch (status) {
        case 200:
          returnComponent = <WrappedComponent data={this.state.data} {...this.props} />
          break
        case 500:
          returnComponent = <Error context={ErrorContext.Component} message='Unkown error occured' type={ErrorType.Generic} />
          break
        case 403:
          returnComponent = <Error context={ErrorContext.Component} message={`You do not have access for this ${entity}`} type={ErrorType.AccessDenied} />
          break
      }

      return returnComponent
    }

    render (): React.ReactNode {
      return this.state ? this.getComponent() : LoadingChild ? LoadingChild : <LoadingIndicator />
    }
  }
}
