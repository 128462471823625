import React, { Fragment, FC, useState } from "react";
import { BuildingModel } from "../../../api/models/BuildingModel";
import { AccordionItem, ListItemProps, AccordionItemState, ListItemState } from "@mit/hui";
import { BasicModel } from "../../../api/models/BasicModel";
import { ElevatorsModel } from "../../../api/models/ElevatorsModel";
import { FloorModel } from "../../../api/models/FloorModel";

interface BuildingDoorEntityViewProps {
	data: BuildingModel;
}

export const BuildingEntityView: FC<BuildingDoorEntityViewProps> = (props) => {
	const onClick = () => {
		let items: ListItemProps[] = [];

		if (props.data.areas && props.data.areas.length > 0) {
			// eslint-disable-next-line
			props.data.areas.map((itm: BuildingModel) => {
				const subItem = () => {
					return <BuildingEntityView data={itm} />;
				};

				let buildingItem: ListItemProps = {
					text: props.data.name,
					icon: "building",
					template: subItem,
				};

				items.push(buildingItem);
			});
		}

		if (props.data.doors && props.data.doors.length > 0) {
			// eslint-disable-next-line
			props.data.doors.map((door: BasicModel) => {
				let buildingItem: ListItemProps = {
					text: door.name,
					icon: "door-closed",
					secondaryText: !door.is_online && "Not available",
					disabledTitle: !door.is_online ? "This door is not available yet" : "",
					state: !door.is_online ? ListItemState.Disabled : ListItemState.None,
				};

				items.push(buildingItem);
			});
		}

		const floorItem = (data: any) => {
			const items: ListItemProps[] = [];

			data.floors.map((itm: FloorModel) => {
				const floor: ListItemProps = {
					text: itm.name,
					icon: "door-closed",
					secondaryText: !data.is_online && "Not available",
					disabledTitle: !data.is_online ? "This elevator is not available yet" : "",
					state: !data.is_online ? ListItemState.Disabled : ListItemState.None,
				};

				items.push(floor);

				return null;
			});

			return <AccordionItem title={data.text} icon="sort-circle" items={items} selectable={false} />;
		};

		if (props.data.elevators && props.data.elevators.length > 0) {
			// eslint-disable-next-line
			props.data.elevators.map((elevator: ElevatorsModel) => {
				let buildingItem: any = {
					text: elevator.name,
					template: floorItem,
					floors: elevator.floors,
					is_online: elevator.is_online,
					icon: "sort-circle",
				};

				items.push(buildingItem);
			});
		}

		if (props.data.alarms && props.data.alarms.length > 0) {
			// eslint-disable-next-line
			props.data.alarms.map((alarm: BasicModel) => {
				let buildingItem: any = {
					text: alarm.name,
					icon: "siren",
				};

				items.push(buildingItem);
			});
		}

		return items;
	};

	const [data] = useState<any[]>(onClick());

	let accState = AccordionItemState.Contracted;

	return (
		<Fragment>
			{data && data.length > 0 ? (
				<AccordionItem icon="building" selectable={false} items={data} title={props.data.name} state={accState} onClick={() => onClick()} />
			) : (
				<AccordionItem icon="building" items={[{ text: "No data available", icon: "", state: ListItemState.Disabled }]} title={props.data.name} state={accState} onClick={() => onClick()} />
			)}
		</Fragment>
	);
};
