import React from 'react';
import { TemplateTwoColumns, Spacer } from '@mit/hui';
import AlarmPeopleAdd from './components/AlarmPeopleAdd';
import AlarmPeopleRemove from './components/AlarmPeopleRemove';
import AccessManagementHeader from './components/AccessManagementHeader';
import { withComponent } from '../../common/WithComponent';
import { connect } from 'react-redux';

class AlarmAccessManagement extends React.Component<any>
{
    render()
    {
        const AccessManagementFooterData = withComponent(AccessManagementHeader);

        return (
            <React.Fragment>
                <AccessManagementFooterData alarmId={this.props.match.params.id} />
                {
                    this.props.roles.includes('PSEC RO SUPER USER') ?
                        <></>
                        :
                        <TemplateTwoColumns
                            padded={true}
                            showDivider={true}
                            showGutters={false}
                            leftArea={<AlarmPeopleAdd alarmId={this.props.match.params.id} />}
                            rightArea={<AlarmPeopleRemove alarmId={this.props.match.params.id} />}
                            primarySize={6}
                            leftAlignment={"left"}
                            rightAlignment={"left"}
                        />
                }
                <Spacer size="1" />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: any) => ({
	roles: state.app.roles
});

export default connect(mapStateToProps)(AlarmAccessManagement);