import React, { Fragment } from "react";
import {  Button, ButtonType, FormContainer, FormField, GridContainer, LayoutColumn, TemplateModal, TemplateModalAlignment, TemplateModalSize, Form, Text, TextType, ValidationUtility } from "@mit/hui";
import { connect } from "react-redux";
import HomeController from "../../../api/HomeController";

interface IAlarmFeedbackState {
	showReportIssue: boolean;
	isBusy: boolean;
	done: boolean;
}

interface IAlarmFeedbackProps {
	alarmId: string;
	user?: any;
}

class AlarmFeedback extends React.Component<IAlarmFeedbackProps, IAlarmFeedbackState> {
	homeController: HomeController;

	constructor(props: any) {
		super(props);

		this.homeController = new HomeController();

		this.state = {
			showReportIssue: false,
			isBusy: false,
			done: false,
		};
	}

	componentDidMount() {
		this.setState({ done: false });
	}

	render() {
		const sendIssue = (data: any, valid: boolean) => {
			if (valid) {
				this.setState({ isBusy: true });
				let body = {
					template: "physical-security/issue",
					variables: {
						subject: `Alarm issue - Location ${this.props.alarmId}`,
						issue_location: data.location,
						issue_description: data.issue,
						issuer_name: data.name,
						issuer_department: data.department ?? "N/A",
						issuer_email: data.email,
						issuer_phone: data.phone,
					},
				};

				this.homeController.feedback(body).then(() => {
					this.setState({ isBusy: false, done: true });
				});
			}
		};

		return (
			<Fragment>
				<Button text="Report issue" onClick={() => this.setState({ showReportIssue: true })} type={ButtonType.Secondary | ButtonType.Ghost} />
				<TemplateModal
					size={TemplateModalSize.Default}
					show={this.state.showReportIssue}
					onClose={() => this.setState({ showReportIssue: false, done: false })}
					bodyAlignment={TemplateModalAlignment.Center}
					footer={<div></div>}
					header={<Text content="Report alarms issue" type={TextType.Heading4} />}
					name="reportIssue"
					body={
						<div>
							{this.state.done && (
								<GridContainer showGutters>
									<LayoutColumn colSize={12} alignment={"center"} padded={true}>
										<Text content={"Your Issue has been submitted! Thank you!"} bold type={TextType.Heading3} />
									</LayoutColumn>
								</GridContainer>
							)}
							{!this.state.done && (
								<div>
									<Form>
										{(methods) => {
											return (
												<>
													<FormContainer
														methods={methods}
														id="alarmIssue"
														formValidationTrigger={"onSubmit"}
														submitText="Submit"
														action={sendIssue}
														secondaryActionText="Cancel"
														actionIsBusy={this.state.isBusy}
														secondaryAction={() => {
															methods.reset();
															this.setState({ showReportIssue: false });
														}}>
														<GridContainer>
															<LayoutColumn colSize={11}>
																<FormField
																	labelDisplay="ontop"
																	placeholder="Please enter a name"
																	label="Name"
																	editor="textbox"
																	value={this.props.user.display_name}
																	id="name"
																	required
																/>
																<FormField
																	labelDisplay="ontop"
																	placeholder="Please enter an email address"
																	label="Email"
																	editor="textbox"
																	properties={{ pattern: { "value": ValidationUtility.emailPattern, "message": "Email format" }}}
																	value={this.props.user.email}
																	id="email"
																	required
																/>
																<FormField
																	labelDisplay="ontop"
																	placeholder="Please enter a phone number"
																	label="Phone number"
																	properties={{ pattern: { value: ValidationUtility.phonePattern, message: "Phone format" } }}
																	editor="textbox"
																	value={this.props.user.phone_number ?? ""}
																	id="phone"
																	required
																/>
																<FormField
																	labelDisplay="ontop"
																	value={this.props.alarmId.replace("_group","")}
																	placeholder="Please enter alarm location"
																	label="Location"
																	editor="textbox"
																	id="location"
																	required
																/>
																<FormField
																	labelDisplay="ontop"
																	placeholder="Please enter the alarm issue"
																	label="Issue"
																	editor="multilinetextbox"
																	id="issue"
																	required
																/>
															</LayoutColumn>
														</GridContainer>
													</FormContainer>
												</>
											);
										}}
									</Form>
								</div>
							)}
						</div>
					}
				/>
			</Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	user: state.app.user,
});

export default connect(mapStateToProps)(AlarmFeedback);
