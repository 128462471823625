import BaseController from "./BaseController";
import { NotificationPollModel } from "./models/NotificationPollModel";

class NotificationController extends BaseController
{
    //GET
    getPoll = async (job_id: string): Promise<NotificationPollModel> =>
    {
        let body = {
            "job_ids": [job_id]
        }

        return await this.postData(this.baseUrl, "/jobs/poll", body);
    }
}

export default NotificationController;