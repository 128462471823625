import * as types from "../constants/ActionTypes";

const initialState =
{
    door: ""
};

export default function app(state = initialState, action: any) 
{
    switch (action.type) 
    {
        case types.SELECT_DOOR:
            {
                return { ...state, door: action.payload };
            }
        default:
            return state;
    }
}