import React, { FC, useState, Fragment, useEffect } from 'react'
import ScheduleController from '../../../api/ScheduleController'
import { init2Time, initTime, MultiTimeRange, MultiTimeRanges, validateTimeRanges } from './MultiTimeRanges'
import { Alert, FormContainer, FormField, GridContainer, LayoutColumn, TemplateTwoColumns, Text, Toggle } from '@mit/hui'
import AlertTimer from '../../../common/AlertTimer'
import ScheduleFrequency, { ScheduleFrequencyEnum} from "./ScheduleFrequency";

export interface WeeklyScheduleObject {
  weekDays: IWeekDay[]
  comment: string
  lastUpdatedBy: string
}

export interface IWeeklyScheduleFormProps {
  scheduleId: string
  schedule: WeeklyScheduleObject
  scheduleString: string
  onScheduleTypeChange: any
}

export interface IWeekDay {
  weekDayType: string
  weekDayChecked: boolean
  allDay?: boolean
  times: MultiTimeRange[]
}

export const DaysOfWeek = Object.freeze({
  SUNDAY: 'Sunday',
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday'
})

const initWeek: IWeekDay[] = [
  { weekDayType: DaysOfWeek.MONDAY, weekDayChecked: true, allDay: false, times: [initTime, init2Time] },
  { weekDayType: DaysOfWeek.TUESDAY, weekDayChecked: true, allDay: false, times: [initTime, init2Time] },
  { weekDayType: DaysOfWeek.WEDNESDAY, weekDayChecked: true, allDay: false, times: [initTime, init2Time] },
  { weekDayType: DaysOfWeek.THURSDAY, weekDayChecked: true, allDay: false, times: [initTime, init2Time] },
  { weekDayType: DaysOfWeek.FRIDAY, weekDayChecked: true, allDay: false, times: [initTime, init2Time] },
  { weekDayType: DaysOfWeek.SATURDAY, weekDayChecked: true, allDay: false, times: [initTime, init2Time] },
  { weekDayType: DaysOfWeek.SUNDAY, weekDayChecked: true, allDay: false, times: [initTime, init2Time] }
]

export const initWeeklyScheduleObject: WeeklyScheduleObject = {
  weekDays: initWeek,
  comment: '',
  lastUpdatedBy: ''
}

const WeeklyScheduleForm: FC<IWeeklyScheduleFormProps> = props => {
  const MAXLENGTH = 50
  const scheduleController = new ScheduleController()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [fromProcessing, setFormProcessing] = useState<boolean>(false)
  const [comment] = useState<string>(props.schedule.comment)
  const [showLastUpdatedBy, setShowLastUpdatedBy] = useState<Boolean>(false)
  const [weekDays, setWeekDays] = useState<IWeekDay[]>(props.schedule.weekDays.length > 0 ? props.schedule.weekDays : initWeek)

  useEffect(() => {
    if (props.schedule.lastUpdatedBy !== '') {
      setShowLastUpdatedBy(true)
    }
  }, [props.schedule.lastUpdatedBy])

  function submitValidation (valid: boolean) {
    if (!valid) {
      setErrorMessage('Your submission contains errors. Refer to the error messages provided on correcting these issues before resubmitting.')
      return false
    }

    if (comment.length > MAXLENGTH) {
      setErrorMessage(`Please provide a comment that is no more than ${MAXLENGTH} characters long. It is currently ${comment.length} characters`)
      return false
    }

    // Business rule not submit more than 3 time ranges
    if (weekDays.find(day => day.times.length > 3)) {
      setErrorMessage('Not allowed to submit more them 3 time ranges per day')
      return false
    }

    if (weekDays.find(day => !validateTimeRanges(day.times, setErrorMessageMulti))) {
      return false
    }

    return true
  }

  function onSubmit (data: any, valid: boolean) {
    setErrorMessage('')
    if (submitValidation(valid)) {
      const unlockDaT = weekDays.map(weekRange => {
        if (!weekRange.weekDayChecked) {
          return undefined
        }
        let times: any = []
        if (weekRange.allDay !== true) {
          times = [
            ...weekRange.times.map(time => {
              return {
                start_time: time.start.concat(':00'),
                end_time: time.end === '23:59' ? '24:00:00' : time.end.concat(':00')
              }
            })
          ]
        } else {
          // all day time
          times = [
            {
              start_time: '00:00:00',
              end_time: '24:00:00'
            }
          ]
        }
        return {
          day: weekRange.weekDayType,
          date: null,
          times: times
        }
      })

      setFormProcessing(true)
      const body = {
        schedule_id: props.scheduleId,
        frequency: 'weekly',
        comment: data.comment,
        unlock_dates_and_times: unlockDaT
      }

      scheduleController
        .postSchedule(props.scheduleId, body)
        .then(async data => {
          setErrorMessage('')
          const dataJson = await data.text()
          if (data.status !== 200) {
            setErrorMessage('Update was unsuccessful. \n' + dataJson)
            return
          }
          // Show success message
          setSuccessMessage('Update successful!')
          setTimeout(() => {
            setSuccessMessage('')
          }, 3000) // Delay for 3 seconds (adjust as needed)
        })
        .catch(error => {
          setErrorMessage('Something went wrong, your update was unsuccessful')
          console.log('Error', error)
        })
        .finally(() => {
          setFormProcessing(false)
          document.querySelector('[id="scrollTo"]')?.scrollIntoView({ behavior: 'smooth' })
        })
    } else {
      document.querySelector('[id="scrollTo"]')?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  function getToggleChoice (state: boolean) {
    if (state === false) {
      return 'Negative'
    } else if (state === true) {
      return 'Positive'
    } else {
      return 'Negative'
    }
  }

  function getReverseToggleChoice (state: any) {
    let choice = state?.SWITCH_STATE?.choice !== undefined ? state?.SWITCH_STATE?.choice : state
    return choice === 'Positive'
  }

  function onWeekDayChecked (index: number, checked: boolean) {
    setWeekDays(prevDateRanges => {
      const newRanges = [...prevDateRanges]
      newRanges[index].weekDayChecked = checked
      return newRanges
    })
  }

  function onAllDayChecked (index: number, checked: boolean) {
    setWeekDays(prevDateRanges => {
      const newRanges = [...prevDateRanges]
      newRanges[index].allDay = checked
      return newRanges
    })
  }

  function setTimeRanges (index: number, timeRanges: MultiTimeRange[]) {
    setWeekDays(prevDateRanges => {
      const newArray = [...prevDateRanges]
      newArray[index].times = timeRanges
      return newArray
    })
  }

  function setErrorMessageMulti (message: string) {
    if (message !== '') {
      setErrorMessage('There seems to be an issue with the time ranges. Kindly correct them before proceeding with the update.')
    } else {
      setErrorMessage('')
    }
  }

  function buildTimeEntryComponent (weekDays: IWeekDay[]) {
    return weekDays.map((item, index) => {
      return (
        <Fragment key={`${index}-weekly`}>
          <TemplateTwoColumns
            padded={false}
            showDivider={false}
            showGutters={true}
            leftArea={
              <Fragment>
                <span className='custom-toggle-container'>
                  <Toggle
                    name={`${index}-week-day`}
                    choice={getToggleChoice(weekDays[index].weekDayChecked ?? false)}
                    inverse={false}
                    negativeLabel={item.weekDayType}
                    positiveLabel={item.weekDayType}
                    onClick={(choice: any) => onWeekDayChecked(index, getReverseToggleChoice(choice))}
                  />
                </span>
                <span className='custom-toggle-container'>
                  <Toggle
                    name={`${index}-allDayWeekly`}
                    choice={getToggleChoice(weekDays[index].allDay ?? false)}
                    inverse={false}
                    disabled={!weekDays[index].weekDayChecked}
                    negativeLabel='All day'
                    positiveLabel='All day'
                    onClick={(choice: any) => onAllDayChecked(index, getReverseToggleChoice(choice))}
                  />
                </span>
              </Fragment>
            }
            rightArea={
              <MultiTimeRanges
                key={`${index}-timeRange`}
                maxTimes={3}
                disabled={item.allDay || !item.weekDayChecked}
                name={String(index)}
                times={item.times || []}
                setTimes={setTimeRanges}
                setErrorMessage={setErrorMessageMulti}
              />
            }
            primarySize={3}
            leftAlignment={'left'}
            rightAlignment={'left'}
          />
          <hr />
        </Fragment>
      )
    })
  }

  return (
    <Fragment>
      <FormContainer
        action={(e: any, valid) => onSubmit(e, valid)}
        actionDisabled={false}
        formValidationTrigger='onChange'
        actionIsBusy={fromProcessing}
        submitText={'Update'}
        id='WeeklyForm'>
        <div className='card card-entity-container p-4 mb-4'>
          {successMessage && <Alert text={successMessage} type={'success'} />}
          <AlertTimer message={errorMessage} />
          <ScheduleFrequency onScheduleTypeChange={props.onScheduleTypeChange} scheduleFrequency={ScheduleFrequencyEnum.Weekly} />
          <hr />
          {buildTimeEntryComponent(weekDays)}
          <FormField
            id='comment'
            properties={{ maxLength: MAXLENGTH, properties: { disabled: false } }}
            value={comment ? comment : ''}
            editor='multilinetextbox'
            label='Comment'
            labelDisplay='nextto'
            required
          />
          {showLastUpdatedBy ? (
            <Fragment>
              <hr />
              <GridContainer>
                <LayoutColumn colSize={3}>
                  <Text content='Last Updated By' bold />
                </LayoutColumn>
                <LayoutColumn colSize={6}>
                  <Text content={props.schedule.lastUpdatedBy} bold />
                </LayoutColumn>
              </GridContainer>
            </Fragment>
          ) : null}
        </div>
      </FormContainer>
    </Fragment>
  )
}
export default WeeklyScheduleForm
