import * as types from "../constants/ActionTypes";
import { NotificationModel } from "../reducers/notification";

export function uuidv4()
{
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c)
    {
        // eslint-disable-next-line
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

//Dispatch

export function dispatch_addNotification(response: any) 
{
    return {
        type: types.ADD_NOTIFICATION,
        payload: response
    };
}

export function dispatch_addNotificationCount() 
{
    return {
        type: types.ADD_NOTIFICATION_COUNT,
    };
}

export function dispatch_clearNotificationCount() 
{
    return {
        type: types.CLEAR_NOTIFICATION_COUNT,
    };
}

export function dispatch_onContactSave() 
{
    return {
        type: types.ON_CONTACT_SAVE,
    };
}

export function dispatch_addCompletedNotification(response: any) 
{
    return {
        type: types.ADD_COMPLETED_NOTIFICATION,
        payload: response
    };
}

export function dispatch_closeNotification(response: any) 
{
    return {
        type: types.CLOSE_NOTIFICATION,
        payload: response
    };
}

export function dispatch_closeCompletedNotification(response: any) 
{
    return {
        type: types.CLOSE_COMPLETED_NOTIFICATION,
        payload: response
    };
}

//Actions

export function addNotification(jobId: string, title: string, message: string, data: any, clearanceId: string, operation: string) 
{
    return (dispatch: any) =>
    {
        const notification: NotificationModel = {
            show: true,
            title: title,
            message: message,
            subtext: "",
            id: jobId,
            data: data,
            clearanceId: clearanceId,
            operation: operation
        }

        dispatch(dispatch_addNotification(notification));
    };
}

export function addCompletedNotification(jobId: string, title: string, message: string, data: any, clearanceId: string, operation: string) 
{
    return (dispatch: any) =>
    {
        const notification: NotificationModel = {
            show: true,
            title: title,
            message: message,
            subtext: "",
            id: jobId,
            data: data,
            clearanceId: clearanceId,
            operation: operation
        }

        dispatch(dispatch_addCompletedNotification(notification));
    };
}

export function closeNotification(id: string) 
{
    return (dispatch: any) =>
    {
        dispatch(dispatch_closeNotification(id));
    };
}

export function closeCompletedNotification(id: string) 
{
    return (dispatch: any) =>
    {
        dispatch(dispatch_closeCompletedNotification(id));
    };
}

export function addNotificationCount() 
{
    return (dispatch: any) =>
    {
        dispatch(dispatch_addNotificationCount());
    };
}

export function clearNotificationCount() 
{
    return (dispatch: any) =>
    {
        dispatch(dispatch_clearNotificationCount());
    };
}

export function onContactSave() 
{
    return (dispatch: any) =>
    {
        dispatch(dispatch_onContactSave());
    };
}