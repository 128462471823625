import React, { Fragment } from 'react';
import { ListItem, ListItemProps } from '@mit/hui';
import StringProvider from '../../../services/StringProvider';
import { UnlockDateTime } from '../../../api/models/ScheduledUnlockBody';

interface ManageScheduleProps
{
    data: any;
}

/**
 * Commented as lint says it is never used.  Don't want to entirely delete
 * as I'm not sure if this is part of a work in progress.
 */
// interface ManageScheduleState
// {
//     // buildingsList: any;
//     isLoading: boolean;
// }

export default class ManageSchedule extends React.Component<ManageScheduleProps>
{
    stringProvider: StringProvider;

    constructor(props: any)
    {
        super(props)

        this.stringProvider = new StringProvider();
    }

    render()
    {
        return <Fragment>
            {
                this.props.data.schedules.map((schedule: any, index: number) => {
                    if (schedule.frequency === 'weekly') {
                        let item: ListItemProps = {
                            icon: "clock",
                            text: this.stringProvider.capitalizeFirstLetter(schedule.frequency),
                            secondaryText: this.stringProvider.toTimeFormat(schedule.unlock_dates_and_times[0].times[0].start_time) + " - " + this.stringProvider.toTimeFormat(schedule.unlock_dates_and_times[0].times[0].end_time)
                        }
                        return <ListItem key={index} {...item}/>
                    }
                    if (schedule.frequency === 'daily') {
                        let item: ListItemProps = {
                            icon: "clock",
                            text: this.stringProvider.capitalizeFirstLetter(schedule.frequency),
                            secondaryText: this.stringProvider.toTimeFormat(schedule.unlock_dates_and_times[0].times[0].start_time) + " - " + this.stringProvider.toTimeFormat(schedule.unlock_dates_and_times[0].times[0].end_time)
                        }
                        return <ListItem key={index} {...item}/>
                    }
                    // eslint-disable-next-line
                    return schedule.unlock_dates_and_times.map((dateTime: UnlockDateTime, unlockIndex: number) => {
                        if (schedule.frequency === 'specific') {
                            let item: ListItemProps = {
                                icon: "clock",
                                text: this.stringProvider.toLongDate(dateTime.date),
                                secondaryText: this.stringProvider.toTimeFormat(dateTime.times[0].start_time) + " - " + this.stringProvider.toTimeFormat(dateTime.times[0].end_time),
                            }
                            return <ListItem key={index} {...item}/>
                        }
                        if (schedule.frequency === 'ordinal') {
                            let item: ListItemProps = {
                                icon: "clock",
                                text: dateTime.day,
                                tertiaryText: dateTime.times.map((time: any) => { return time }).join(", ")
                            }
                            return <ListItem key={index} {...item}/>
                        }
                    })
                })
            }
        </Fragment>
    }
}