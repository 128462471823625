import React, { FC, useState, Fragment, useEffect } from 'react'
import { TemplateModalSize, Text, TextType } from '@mit/hui'
import ClearanceController from '../../../api/ClearanceController'
import { withComponent } from '../../../common/WithComponent'
import Modal from '../../../common/Modal'
import Alert from '../../clearances/components/AlertComponent'
import { MultipleCardholderModal } from '../../clearances/components/MultipleCardholderModal'

interface CardholderGroups {
  groups: string[]
  managedGroup: string | null
}

interface MultipleCardholderGroupWarningProps {
  clearanceId: string
}

export const MultipleCardholderGroupWarning: FC<MultipleCardholderGroupWarningProps> = props => {
  const [displayWarning, setDisplayWarning] = useState<boolean>(false)
  const [cardholderGroups, setCardholderGroups] = useState<CardholderGroups>({ groups: [], managedGroup: null })
  const [showModal, setShowModal] = useState<boolean>(false)
  const PopupData = withComponent(Modal)

  let clearanceController = new ClearanceController()

  function onLinkClick () {
    setShowModal(true)
  }

  useEffect(
    () => {
      //Display message should not show on load or when props.clearanceId changes
      setDisplayWarning(false)

      //Call api when prop changes
      clearanceController.getClearanceCardholderGroups(props.clearanceId).then(data => {
        //if not null
        if (data) {
          let response = data

          let multipleGroups = response.groups.length > 0 ? true : false

          if (multipleGroups) {
            setCardholderGroups(data)
            setDisplayWarning(true) //show warining message
          }
        }
      })
    },
    [props.clearanceId] /* Run useEffect on mount and on props.clearanceId change */
  )

  return (
    <Fragment>
      {cardholderGroups.managedGroup && displayWarning && cardholderGroups?.groups.length > 1 ? (
        <Fragment>
          <Alert
            text={
              <p>
                This clearance manages the cardholder group: <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>{cardholderGroups.managedGroup} </span>. There are other
                cardholder groups that is linked to this clearance. To see those groups, click
                <a onClick={onLinkClick} style={{ cursor: 'pointer' }}>
                  {' '}
                  here
                </a>
              </p>
            }
            icon='exclamation-triangle'
          />
          <PopupData
            show={showModal}
            onClose={() => {
              setShowModal(false)
            }}
            containerless={false}
            size={TemplateModalSize.Large}
            body={<MultipleCardholderModal groups={cardholderGroups.groups} managedGroup={cardholderGroups.managedGroup} />}
            footer={''}
            header={<Text content='Cardholder groups' type={TextType.Heading4} icon='' />}
            name={'subListPopup'}
          />
        </Fragment>
      ) : (
        <></>
      )}
    </Fragment>
  )
}
