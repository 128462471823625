import BaseController from "./BaseController";
import StringProvider from "../services/StringProvider";

class UploadController extends BaseController
{
    async getS3Url(clearanceId: string, fileName: any, operation: string): Promise<any>
    {
        let clearance = new StringProvider().toUrl(clearanceId);

        let type = "";

        if (fileName.split('.')[1] === "xls")
            type = "application/vnd.ms-excel";

        if (fileName.split('.')[1] === "xlsx")
            type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

        if (fileName.split('.')[1] === "csv")
            type = "text/csv";

        let response = await this.useFetch('GET', 'get/upload_url', `${this.baseUrl}/clearances/${clearance}/cardholders/bulk?file_name=${fileName}&file_type=${type}&operation=${operation}`);

        if (response.status === 200)
        {
            let data = response.json();
            return data;
        } else
        {
            let text = await response.text();
            return Promise.reject(text);
        }
    }

    async upload(url: string, file: any, fileName: any): Promise<any>
    {
        let response = await this.useFetch('PUT', 'put/upload', url, file, true, fileName, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

        if (response.status === 200)
        {
            return true;
        } else
        {
            let text = await response.text();
            return Promise.reject(text);
        }
    }
}

export default UploadController;