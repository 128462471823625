import React from "react";
import { TemplateMasterDetail, NavItemProps, NavBarTypes, NavBar, ResponsiveTypes, MobileView } from "@mit/hui";
import DoorDetail from "./DoorDetail";
import DoorMaster from "./DoorMaster";
import { NavItemState } from '../../enums/NavItemState';
import StringProvider from "../../services/StringProvider";
import { connect } from "react-redux";

interface DoorsState
{
    view: number;
    isOnline: boolean;
    door: any;
}

class Doors extends React.Component<any, DoorsState>
{
    stringProvider: StringProvider;

    constructor(props: any)
    {
        super(props)

        this.stringProvider = new StringProvider();

        this.state = {
            view: MobileView.Master,
            isOnline: false,
            door: null
        }
    }

    onItemClick(door: any)
    {
        this.setState({ view: MobileView.Detail, door: door })
    }

    setIsOnline(isOnline: boolean)
    {
        this.setState({ isOnline: isOnline })
    }

    componentDidMount()
    {
        this.setState({ view: this.props.match.params.door ? MobileView.Detail : MobileView.Master })
    }

    render()
    {
        let doorId = this.stringProvider.fromUrl(this.props.match.params.door);
        let buildingId = this.stringProvider.fromUrl(this.props.match.params.building);

        let url = "";
        if (this.props.match.params.building && this.props.match.params.door)
            url = "/" + this.props.match.params.building + "/" + this.props.match.params.door;

        let doorTabData: NavItemProps[] = [
            {
                icon: '',
                iconOnly: false,
                text: 'Events',
                state: window.location.pathname.includes("events") ? NavItemState.Active : NavItemState.None,
                to: '/doors/events' + url
            },
            {
                icon: '',
                iconOnly: false,
                text: 'Gatekeepers',
                state: window.location.pathname.includes("gatekeepers") ? NavItemState.Active : NavItemState.None,
                to: '/doors/gatekeepers' + url
            }
        ];

        let isSuperUser = false;

        if (this.props.roles && (this.props.roles.includes("PSEC SUPER USER") || this.props.roles.includes("PSEC RO SUPER USER")))
            isSuperUser = true

        return (
            <TemplateMasterDetail
                startState={true}
                mobileView={this.state.view}
                onBack={() => this.setState({ view: MobileView.Master })}
                masterTitle={"My Doors"}
                master={<DoorMaster onSelect={(isOnline: boolean) => this.setIsOnline(isOnline)} superUser={isSuperUser} buildingId={buildingId} doorId={doorId} onClick={(door: any) => this.onItemClick(door)} />}
                navigation={doorId ? <NavBar responsiveType={ResponsiveTypes.CollapseFit} type={NavBarTypes.Tabs} tabs={doorTabData} /> : <div></div>}
                detailTitle={this.state.door ? this.state.door.name : ""}
                detail={<DoorDetail isOnline={this.state.isOnline} buildingId={buildingId} doorId={doorId} />} />
        );
    }
}

const mapStateToProps = (state: any) => ({
    roles: state.app.roles
});

export default connect(mapStateToProps)(Doors);


