import React, { Fragment } from 'react'
import appconfig from '../app-config'
import History from '../common/History'
import { Switch, Route, Router } from 'react-router-dom'
import SubNavBar from './nav/SubNavBar'
import { AtlasPage, Loader, Error, ErrorType, ErrorContext, NavItemProps } from '@mit/hui'
import Clearances from './clearances/Clearances'
import Doors from './doors/Doors'
import Elevators from './elevators/Elevators'
import HomeController from '../api/HomeController'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as appActionCreator from '../ts/redux/actions/app'
import ProfileView from './nav/ProfileView'
import ErrorBoundary from '../common/ErrorBoundry'
import AuthProvider from '../services/AuthProvider'
import Alarms from './alarms/Alarms'
import People from './people/People'
import Notification from './Notification'
import FeedbackPopup from '../../src/ts/Feedback'
import ReleaseNotesPopup from './ReleaseNotes'
import SessionNotifications from './SessionNotifications'
import Schedules from './schedules/Schedules'

interface HomeState {
  isLoading: boolean
  isReady: boolean
  isUnauth: boolean
  formSubmit: boolean
  showFeedbackResponse: boolean
  showFeedbackResponseFailed: boolean
  showFeedbackModal: boolean
}

class Home extends React.Component<any, HomeState> {
  homeController: HomeController
  authProvider: AuthProvider

  constructor (props: any) {
    super(props)

    this.state = {
      isLoading: true,
      isReady: false,
      isUnauth: false,
      formSubmit: false,
      showFeedbackResponse: false,
      showFeedbackResponseFailed: false,
      showFeedbackModal: false
    }

    this.homeController = new HomeController()
    this.authProvider = new AuthProvider()
  }

  async componentDidMount () {
    let auth = await this.homeController.initialize()

    if (auth && auth.user && auth.user.roles) this.props.actions.changeRoles(auth.user.roles)

    if (auth === 403) {
      this.setState({ isUnauth: true })
    } else {
      const feature = await this.homeController.getFeatures()
      if (feature?.featureFlags) this.props.actions.changeFeatures(feature.featureFlags)

      this.homeController.getProfile().then(data => {
        if (data) {
          this.props.actions.changeUser(data)
        }
      })

      this.homeController.getPicture().then(data => {
        if (data) this.props.actions.changePicture(data)
      })

      setTimeout(() => {
        this.setState({ isLoading: false })
      }, 500)
      setTimeout(() => {
        this.setState({ isReady: true })
      }, 1000)
    }
  }

  getEnv () {
    if (appconfig.stage?.name === 'develop') return 'dev'

    if (appconfig.stage?.name === 'release') return 'staging'

    if (appconfig.stage?.name === 'master') return 'production'

    return 'production'
  }

  render () {
    const utilNavItems: NavItemProps[] = [
      {
        icon: 'question-circle',
        text: 'Help',
        iconOnly: false,
        onClick: () => {
          window.open('https://kb.mit.edu/confluence/pages/viewpage.action?pageId=163220419', '_blank')
        }
      },
      {
        icon: 'comments',
        text: 'Feedback',
        iconOnly: false,
        template: () => {
          return <FeedbackPopup />
        }
      },
      {
        icon: 'bell',
        text: "What's new!",
        iconOnly: false,
        template: () => {
          return <ReleaseNotesPopup />
        }
      }
    ]

    if (!this.props.roles.includes('PSEC RO SUPER USER')) {
      utilNavItems.unshift({
        icon: 'envelope-open-text',
        iconOnly: false,
        text: 'Bulk Upload Notifications',
        template: () => <SessionNotifications />
      })
    }

    if (this.state.isUnauth) return <Loader contactEmail='nist-physec@mit.edu' hasAccess={false} exit={!this.state.isLoading} name={'Access Control Dashboard'} />

    if (this.state.isReady)
      return (
        <Fragment>
          <ErrorBoundary errorComponent={<Error message={'Something went wrong.'} type={ErrorType.Generic} context={ErrorContext.Page} />}>
            <Router history={History}>
              <Notification />
              <AtlasPage
                utilityNavigation={utilNavItems}
                gaId='G-E4K6TL4V0J'
                environment={this.getEnv()}
                name={'Access Control Dashboard'}
                navigation={<SubNavBar />}
                profile={<ProfileView />}
                content={
                  <Fragment>
                    <Switch>
                      <Route exact path={'/'} component={Clearances} />
                      <Route path={'/clearances/:tab?/:id?'} component={Clearances} />
                      <Route path={'/doors/:tab?/:building?/:door?'} component={Doors} />
                      <Route path={'/elevators/:tab?/:building?/:elevator?'} component={Elevators} />
                      <Route path={'/alarms/:tab?/:id?'} component={Alarms} />
                      <Route path={'/people/:tab?/:id?'} component={People} />
                      { this.props.features.includes('schedules') ? <Route path={'/schedules/:tab?/:id?'} component={Schedules} /> : null }
                    </Switch>
                  </Fragment>
                }
              />
            </Router>
          </ErrorBoundary>
        </Fragment>
      )

    return <Loader contactEmail='' exit={!this.state.isLoading} name={'Access Control Dashboard'} />
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(appActionCreator, dispatch)
})

const mapStateToProps = (state: any) => ({
  user: state.app.user,
  notifications: state.notification.completedNotifications,
  roles: state.app.roles,
  features: state.app.features
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
