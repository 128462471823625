import React, { Fragment } from "react";
import {
  Table,
  Icon,
  Button,
  ButtonType,
  Error,
  ErrorContext,
  ErrorType,
} from "@mit/hui";
import { GatekeepersModel } from "../../../api/models/GatekeepersModel";
import { PersonModel } from "../../../api/models/PersonModel";
import StringProvider from "../../../services/StringProvider";
import PeopleController from "../../../api/PeopleController";

interface GatekeeperViewState {
  gatekeepers: [];
  isLoading: boolean;
}

interface GatekeeperViewProps {
  data: GatekeepersModel;
}

export default class GatekeeperView extends React.Component<
  GatekeeperViewProps,
  GatekeeperViewState
> {
  peopleController: PeopleController;
  stringProvider: StringProvider;

  constructor(props: any) {
    super(props);

    this.peopleController = new PeopleController();
    this.stringProvider = new StringProvider();

    this.state = {
      gatekeepers: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    let mappedData: any = [];

    this.peopleController
      .postPeopleProfile(this.props.data.user_ids)
      .then((response) => {
        if (response) {
          response.user_profiles.map((itm: PersonModel) => {
            const data = {
              state: "",
              items: [
                <Fragment>
                  <Icon type={"regular"} icon="user-tie" /> {itm.display_name}
                </Fragment>,
                itm.department && itm.department,
                <Fragment>
                  {itm.phone_number && (
                    <Button
                      padded={false}
                      icon="phone"
                      type={32}
                      text={this.stringProvider.formatPhoneNumber(
                        itm.phone_number
                      )}
                      url={"tel:" + itm.phone_number}
                    />
                  )}
                </Fragment>,
                <Button
                  type={ButtonType.IconNaked}
                  text={itm.email}
                  icon="envelope"
                  padded={false}
                  url={"mailto:" + itm.email}
                />,
              ],
            };

            mappedData.push(data);

            return null;
          });

          this.setState({
            gatekeepers: mappedData,
            isLoading: false,
          });
        }
        else{
            this.setState({
                isLoading: false
              }); 
        }
      });
  }

  render() {
    if (this.state.isLoading)
      return (
        <Table
          type={""}
          flush={true}
          columns={4}
          header={[]}
          rows={[]}
          isLoading={true}
        />
      );

    return (
      <Fragment>
        {/*
                this.props.data.gatekeeper_list_id
                &&
                <Text icon="list" content={this.props.data.gatekeeper_list_id} type={TextType.Heading5} />
            */}
        {this.state.gatekeepers.length > 0 ? (
          <Table
            type={""}
            flush={true}
            columns={4}
            header={[]}
            rows={this.state.gatekeepers}
          />
        ) : (
          <Error
            context={ErrorContext.Component}
            message={"No Gatekeepers data available"}
            type={ErrorType.NoData}
          />
        )}
      </Fragment>
    );
  }
}
