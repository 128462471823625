import React from 'react'
import { TemplateMasterDetail, NavItemProps, NavBar, NavBarTypes, MobileView, ResponsiveTypes } from '@mit/hui'
import { connect } from 'react-redux'
import { withComponent } from '../../common/WithComponent'
import { NavItemState } from '../../enums/NavItemState'
import PeopleMaster from './PeopleMaster'
import PeopleDetail from './PeopleDetail'

interface PeopleState {
  view: number
}

interface PeopleProps {
  people?: string
  match: any
  roles?: string[]
}

class People extends React.Component<PeopleProps, PeopleState> {
  constructor (props: any) {
    super(props)

    this.state = {
      view: 1
    }
  }

  componentDidMount () {
    this.setState({
      view: this.props.match.params.id ? MobileView.Detail : MobileView.Master
    })
  }

  onItemClick () {
    this.setState({ view: 2 })
  }

  render () {
    let userId = ''
    if (this.props.match.params.id) userId = '/' + this.props.match.params.id

    let peopleTabData: NavItemProps[] = [
      {
        icon: '',
        iconOnly: false,
        text: 'Clearances',
        state: window.location.pathname.includes('clearance') ? NavItemState.Active : NavItemState.None,
        to: '/people/clearance-information'
      }
      // {
      //     icon: '',
      //     iconOnly: false,
      //     text: 'Alarms',
      //     state: window.location.pathname.includes("alarms") ? NavItemState.Active : NavItemState.None,
      //     to: '/people/alarms' + userId
      // },
      // {
      //     icon: '',
      //     iconOnly: false,
      //     text: 'General',
      //     state: window.location.pathname.includes("general") ? NavItemState.Active : NavItemState.None,
      //     to: '/people/general' + userId
      // }
    ]

    const NavBarData = withComponent(NavBar)

    return (
      <TemplateMasterDetail
        mobileView={this.state.view}
        startState={true}
        onBack={() => this.setState({ view: 1 })}
        master={
          <PeopleMaster
            userId={this.props.match.params.id}
            onclick={() => this.onItemClick()}
            canMaintainSpecialId={this.props.roles?.includes('CAN MAINTAIN SPECIAL ID CARDS') ? true : false}
          />
        }
        masterTitle='People'
        navigation={userId ? <NavBarData responsiveType={ResponsiveTypes.CollapseFit} type={NavBarTypes.Tabs} tabs={peopleTabData} /> : <div />}
        detail={<PeopleDetail userId={this.props.match.params.id} />}
        detailTitle={this.props.match.params.id ? this.props.match.params.id.replace('gsc-people-', '') : ''}
      />
    )
  }
}

const mapStateToProps = (state: any) => ({
  roles: state.app.roles
})

export default connect(mapStateToProps)(People)
