import {
  MetricGroup,
  TextType,
  Text,
  MetricCardProps,
  Error,
  ErrorType,
  ErrorContext,
  ButtonBar,
  ButtonBarType,
  ButtonProps,
  ButtonState,
  ButtonType,
  Table,
  TemplateModalSize,
  Spacer
} from '@mit/hui'
import React, { Fragment, useEffect, useState } from 'react'
import Modal from '../../../common/Modal'
import EntitiesController from '../../../api/EntitiesController'
import StringProvider from '../../../services/StringProvider'

export interface IDoorSummaryProps {
  doorId: string
  isOnline: boolean
}

const DoorSummary: React.FC<IDoorSummaryProps> = (props: IDoorSummaryProps) => {
  const entitiesController = new EntitiesController()
  const stringProvider = new StringProvider()

  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const [isUnlocking, setIsUnlocking] = useState<boolean>(false)
  const [showUnlockedModal, setShowUnlockedModal] = useState<boolean>(false)
  const [showEventsModal, setshowEventsModal] = useState<boolean>(false)
  const [activities, setActivities] = useState<any[]>([])
  const [metrics, setMetrics] = useState<MetricCardProps[]>([
    {
      label: 'Access Granted',
      value: 0
    },
    {
      label: 'Access Denied',
      value: 0
    }
  ])
  const [buttonItems, setButtonItems] = useState<ButtonProps[]>([
    {
      icon: '',
      onClick: () => {
        onUnlock()
      },
      text: 'Momentarily Unlock',
      state: getMomentarilyUnlockBtnState(props.isOnline, isUnlocking),
      type: ButtonType.Primary
    }
  ])

  useEffect(() => {
    setLoading(true)
    entitiesController.getDoorRecentActivity(props.doorId).then((response: any) => {
      try {
        // If the call failed the return value would be null.
        if (response) {
          if (response.summary !== null) {
            setMetrics([
              {
                label: 'Access Granted',
                value: response.summary.access_granted
              },
              {
                label: 'Access Denied',
                value: response.summary.access_denied
              }
            ])
          }

          if (response.activities && response.activities.length > 0) {
            let mappedActivities = response.activities.map((itm: any) => {
              return {
                state: '',
                items: [stringProvider.toLongDateWithTime(itm.time_stamp), itm.event_type]
              }
            })

            setActivities([...mappedActivities])
          }
        } else {
          setError(true)
        }

        setLoading(false)
      } catch (e) {
        console.log('Error: ', e)
        setError(true)
      }
    })
  }, [props.doorId])

  useEffect(() => {
    if (error) {
      setButtonItems([
        {
          icon: '',
          onClick: () => {
            onUnlock()
          },
          text: 'Momentarily Unlock',
          state: getMomentarilyUnlockBtnState(props.isOnline, isUnlocking),
          type: ButtonType.Primary
        }
      ])
    } else {
      setButtonItems([
        {
          icon: '',
          onClick: () => {
            onUnlock()
          },
          text: 'Momentarily Unlock',
          state: getMomentarilyUnlockBtnState(props.isOnline, isUnlocking),
          type: ButtonType.Primary
        },
        {
          icon: '',
          onClick: () => {
            setshowEventsModal(true)
          },
          text: 'View All Actions',
          state: ButtonState.Enabled,
          type: ButtonType.Ghost | ButtonType.Secondary
        }
      ])
    }
  }, [isUnlocking, error, props.doorId])

  function getMomentarilyUnlockBtnState (isOnline: boolean, isUnlocking: boolean) {
    if (!isOnline) return ButtonState.Disabled
    if (isUnlocking) return ButtonState.Disabled
    return ButtonState.Enabled
  }

  function onUnlock () {
    setIsUnlocking(true)

    entitiesController.momentarilyUnlock(props.doorId).then((response: any) => {
      if (response) {
        setIsUnlocking(false)
        setShowUnlockedModal(true)
      }
    })
  }

  if (loading) return <DoorSummaryLoading />

  return (
    <Fragment>
      <Text content='Summary' type={TextType.Heading4} icon='' />
      <Text content={'Summarized overview of past week'} padded={true} type={TextType.Body} />
      {error ? <Error context={ErrorContext.Component} message={'Failed to retrieve door activity'} type={ErrorType.Generic} /> : <MetricGroup items={metrics} />}
      <Spacer size='2' />
      <ButtonBar buttons={buttonItems} alignment={'left'} type={ButtonBarType.Padded} />
      <Modal
        show={showUnlockedModal}
        onClose={() => setShowUnlockedModal(false)}
        size={TemplateModalSize.Default}
        body={<Text content='Unlocked Successfully' bold type={TextType.Body} />}
        footer=''
        header={<Text content='Momentarily Unlocked' type={TextType.Heading4} icon='' />}
        name={'unlockedModal'}
        containerless={false}
      />
      <Modal
        show={showEventsModal}
        onClose={() => setshowEventsModal(false)}
        padded={false}
        size={TemplateModalSize.Large}
        body={
          activities.length > 0 ? (
            <Table flush type={''} rows={activities} columns={2} header={['Time', 'Status']} />
          ) : (
            <Error context={ErrorContext.Component} type={ErrorType.NoData} message={'No Activities Available'} />
          )
        }
        footer=''
        header={<Text content='Door Actions' type={TextType.Heading4} icon='' />}
        name={'viewEventsModal'}
        containerless={false}
      />
    </Fragment>
  )
}

// Loading component
const DoorSummaryLoading: React.FC<any> = () => {
  return (
    <div>
      <Text content='Summary' type={TextType.Heading4} icon='' />
      <Text content={'Summarized overview of past week'} padded={true} type={TextType.Body} />
      {
        <MetricGroup
          items={[
            { label: 'Access Granted', value: 0, isLoading: true },
            { label: 'Access Denied', value: 0, isLoading: true }
          ]}
        />
      }
    </div>
  )
}

export default DoorSummary
