import React, { Fragment } from "react";
import { Text, BulkUpload, Error, TextType, HelpIconProps, TemplateModalSize, ErrorContext, ErrorType, BulkUploadField, DrawerSize } from "@mit/hui";
import ClearanceController from "../../../api/ClearanceController";
import { CardholdersResponseModel } from "../../../api/models/CardholdersResponseModel";
import { withComponent } from "../../../common/WithComponent";
import Modal from "../../../common/Modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as notificationActionCreator from "../../../ts/redux/actions/notification";
import NotificationController from "../../../api/NotificationController";
import UploadController from "../../../api/UploadController";
import { uuidv4 } from "../../../ts/redux/actions/notification";
import AuthProvider from "../../../services/AuthProvider";

interface BulkAdditionProps {
	clearanceId: string;
	actions?: any;
}

interface BulkAdditionState {
	items: CardholdersResponseModel;
	isLoading: boolean;
	errorText: string;
	showPopup: boolean;
	showFailedPoppup: boolean;
}

class BulkAddition extends React.Component<BulkAdditionProps, BulkAdditionState> {
	clearanceController: ClearanceController;
	notificationController: NotificationController;
	uploadController: UploadController;
	authProvider: AuthProvider;

	constructor(props: BulkAdditionProps) {
		super(props);

		this.clearanceController = new ClearanceController();
		this.notificationController = new NotificationController();
		this.uploadController = new UploadController();
		this.authProvider = new AuthProvider();

		this.state = {
			items: { clearance_id: "", operation: "", job_id: "", results: [] },
			isLoading: false,
			errorText: "",
			showPopup: false,
			showFailedPoppup: false,
		};
	}

	isResultSucceeded(str: any) {
		if (str && str.clearance_id) return true;

		return false;
	}

	postBulkData = async (data: any) => {
		this.props.actions.addNotification(uuidv4(), "Bulk Additions", "Your bulk upload is being processed", data, this.props.clearanceId, "add-clearance");	
	}

	render() {
		const PopupData = withComponent(Modal);

		const helpProps: HelpIconProps = {
			description: "Upload a .xslx, .xsl or .csv file using the template provided",
			title: "Bulk Additions",
			embedded: true,
		};

		const fieldData: Record<string, BulkUploadField> = {
			"Kerberos ID": { required: false },
			"MIT ID": { required: false },
		};

		return (
			<Fragment>
				<Text content="Bulk additions" type={TextType.Heading5} icon="" help={helpProps} />
				<BulkUpload showTemplate={false} drawerSize={DrawerSize.Small} formFields={fieldData} onImport={this.postBulkData} />
				{/*<FileUpload isLoading={this.state.isLoading} acceptedMimeTypes={['.csv', '.xls', '.xlsx']} onConfirm={(file) => this.postBulkData(file)} />*/}
				<PopupData
					show={this.state.showPopup}
					onClose={() => this.setState({ showPopup: false })}
					exportList={this.state.items.results}
					size={TemplateModalSize.Default}
					body={<Text content={"Processing list. We will notify you when this is completed"} />}
					header={<Text content="Cardholder(s) added" type={TextType.Heading4} icon="" />}
					name={"popup"}
				/>
				<PopupData
					show={this.state.showFailedPoppup}
					onClose={() => this.setState({ showFailedPoppup: false })}
					size={TemplateModalSize.Default}
					body={<Error context={ErrorContext.Component} message={this.state.errorText ? this.state.errorText : "Something went wrong"} type={ErrorType.Generic} />}
					footer=""
					header={<Text content="Cardholder(s) added" type={TextType.Heading4} icon="" />}
					name={"popupFailed"}
				/>
			</Fragment>
		);
	}
}

const mapDispatchToProps = (dispatch: any) => ({
	actions: bindActionCreators(notificationActionCreator, dispatch),
});

export default connect(null, mapDispatchToProps)(BulkAddition);
