import React from 'react';
import History from "../../common/History";
import { FilteredList, ErrorContext, ErrorType, Error } from '@mit/hui';
import EntitiesController from '../../api/EntitiesController';
import StringProvider from '../../services/StringProvider';
import { BuildingModel } from '../../api/models/BuildingModel';
import { BuildingsElevatorsView } from './components/BuildingsElevatorsViewtsx';
import { withComponent } from '../../common/WithComponent';
import FilterProvider from '../../services/FilterProvider';

interface ElevatorsMasterProps
{
    buildingId: string;
    elevatorId: string;
    onClick: any;
    superUser: boolean;
}

interface ElevatorsMasterState
{
    buildings: any;
    buildingsData: any;
    isLoading: boolean;
    isError: boolean;
    isEmpty: boolean;
    filteredData: any;
    keyword: string;
}

const WAIT_INTERVAL = 200
let timerID: any

export default class EvelatorsMaster extends React.Component<ElevatorsMasterProps, ElevatorsMasterState>
{
    entitiesController: EntitiesController;
    stringProvider: StringProvider;
    filterProvider: FilterProvider;

    constructor(props: any)
    {
        super(props)

        this.entitiesController = new EntitiesController();
        this.stringProvider = new StringProvider();
        this.filterProvider = new FilterProvider();

        this.state = {
            buildings: [],
            buildingsData: null,
            isLoading: true,
            isError: false,
            isEmpty: false,
            filteredData: null,
            keyword: ""
        }
    }

    componentWillUnmount()
    {
        this.entitiesController.abortRequest();
    }

    mapBuildingsAndElevators = (data: BuildingModel[]) =>
    {
        if(this.props.elevatorId)
        {
            let elevator = this.filterProvider.getObjectById(data, this.props.elevatorId);
            this.props.onClick(elevator);
        }

        let defaultListData: any = [];
        const BuildingsElevatorsViewData = withComponent(BuildingsElevatorsView);

        if (data && data.length === 0) return defaultListData;

        const componentObject = {
            template: () => <BuildingsElevatorsViewData superUser={this.props.superUser} data={data} onClick={(itmBuilding: any, itm: any) => this.onChange(itmBuilding, itm)} elevatorId={this.props.elevatorId} buildingId={this.props.buildingId} keyword={this.state.keyword} />,
        }

        defaultListData.push(componentObject);

        return defaultListData;
    }

    filterArea(area: BuildingModel, filterBy: string)
    {
        let filteredArea = Object.assign({}, area);

        // child areas
        let filteredChildren = [];

        if (area.areas && area.areas.length > 0)
            for (let childArea of area.areas)
            {
                // "recursive" traversal call for child areas
                let filteredChild = this.filterArea(childArea, filterBy.toLowerCase());

                if (filteredChild)
                {
                    filteredChildren.push(filteredChild);
                }
            }

        filteredArea.areas = filteredChildren;

        // filter check on current area name
        if (filteredArea.name.toLowerCase().includes(filterBy.toLowerCase()))
        {
            filteredArea.doors = area.doors;
            filteredArea.elevators = area.elevators;
            return filteredArea;
        }

        // area doors
        let filteredDoors = [];
        if (area.doors && area.doors.length > 0)
            for (let door of area.doors)
            {
                if (door.name.toLowerCase().includes(filterBy.toLowerCase()))
                {
                    filteredDoors.push(door);
                }
            }
        filteredArea.doors = filteredDoors;

        // area elevators
        let filteredElevators = [];
        if (area.elevators && area.elevators.length > 0)
            for (let elevator of area.elevators)
            {
                if (elevator.name.toLowerCase().includes(filterBy.toLowerCase()))
                {
                    filteredElevators.push(elevator);
                }
            }
        filteredArea.elevators = filteredElevators;

        // filter check on children, doors and elevators
        if (filteredChildren.length !== 0 || filteredDoors.length !== 0 || filteredElevators.length !== 0)
        {
            return filteredArea;
        }

        // no filter matched so return nothing
        return null;
    }

    filterBuildingsAndDoors = (keyword: string) =>
    {
        clearTimeout(timerID)
        this.setState({ keyword: keyword })

        if (keyword.length < 1)
        {
            this.setState({ filteredData: null, isLoading: false });
            return;
        }

        this.setState({ isLoading: true })

        timerID = setTimeout(() =>
        {
            if (keyword.length < 1)
            {
                this.setState({ filteredData: null, isLoading: false });
                return;
            }

            let allData = this.state.buildingsData;

            let filteredData = this.filterArea(allData.buildings[0], keyword)

            if (filteredData)
            {
                let mapped = this.mapBuildingsAndElevators([filteredData])
                this.setState({ filteredData: mapped, isLoading: false })
            }
            else
            {
                this.setState({ filteredData: [], isLoading: false })
            }

        }, WAIT_INTERVAL)
    }

    async componentDidMount()
    {
        const buildingsData = await this.entitiesController.getElevators();

        if (buildingsData)
        {
            let mapped = this.mapBuildingsAndElevators(buildingsData.buildings);

            this.setState({
                buildings: mapped,
                buildingsData: buildingsData,
                isLoading: false
            });
        }
        else
        {
            this.setState({
                isError: true,
                isLoading: false
            });
        }
    }

    onChange = (building: any, elevator: any): void =>
    {
        this.props.onClick(elevator);

        const newPath = this.generateURL(building.id, elevator.id);
        History.push(newPath);
    }

    checkEmpty()
    {
        if (this.state.filteredData && this.state.filteredData.length === 0)
            return true;

        if (this.state.buildings && this.state.buildings.length === 0)
            return true;

        return false;
    }

    generateURL(building: string, elevator: string)
    {
        const path = window.location.pathname;

        building = this.stringProvider.toUrl(building);
        elevator = this.stringProvider.toUrl(elevator);

        if (path === "/elevators/events")
        {
            return "/elevators/events/" + building + "/" + elevator;
        }
        else if (path === "/elevators/gatekeepers")
        {
            return "/elevators/gatekeepers/" + building + "/" + elevator;
        }
        else
        {
            const pathParts = window.location.pathname.split("/");

            pathParts.pop();
            pathParts.pop();
            pathParts.push(building + "/" + elevator);
            return (pathParts.join('/'));
        }
    }

    emptyData =
        {
            startIcon: 'search',
            placeholderText: 'Filter my buildings or elevators',
            state: 'icon-start',
            onActionClick: this.onChange,
            inputType: 'text',
            name: "elevatorsMaster"
        }

    render()
    {
        if (this.state.isError)
            return <Error context={ErrorContext.Component} message="No data available for Elevators" type={ErrorType.NoData} />

        return <FilteredList name="filteredListElevator" loadingText="Loading your buildings and elevators" isLoading={this.state.isLoading} isEmpty={this.state.isLoading ? false : this.checkEmpty()} selectable={true} height={60} compact={true} searchOptions={this.emptyData} items={this.state.filteredData ? this.state.filteredData : this.state.buildings} onSearch={this.filterBuildingsAndDoors} />
    }
}