import React from 'react'
import { AutoComplete, TextboxType, TextboxProps, Error, ErrorContext, ErrorType } from '@mit/hui'
import { FilteredListLoader } from '../../common/FilteredListLoader'
import HomeController from '../../api/HomeController'
import { PersonModel } from '../../api/models/PersonModel'
import { ResultModel } from '../../api/models/CardholdersResponseModel'
import StringProvider from '../../services/StringProvider'
import History from '../../common/History'
import { connect } from 'react-redux'

interface PeopleMasterProps {
  onclick: any
  userId: string
  canMaintainSpecialId: boolean
}

interface PeopleMasterState {
  people: any
  isBusy: boolean
  isLoading: boolean
  isError: boolean
  isEmpty: boolean
}

const WAIT_INTERVAL = 700
let timerID: any

export default class PeopleMaster extends React.Component<PeopleMasterProps, PeopleMasterState> {
  homeController: HomeController
  stringProvider: StringProvider

  constructor (props: any) {
    super(props)

    this.homeController = new HomeController()
    this.stringProvider = new StringProvider()
    this.state = {
      people: [],
      isBusy: false,
      isLoading: true,
      isError: false,
      isEmpty: false
    }
  }

  async componentDidMount () {
    this.setState({
      isLoading: false,
      isEmpty: true
    })
  }

  emptyData: TextboxProps = {
    startIcon: 'search',
    startIconAccessibilityText: 'search',
    placeholderText: 'Search for User',
    type: TextboxType.IconStart,
    name: 'peopleSearch'
  }

  getText (people: PersonModel) {
    if (people.display_name) return people.display_name

    if (people.kerberos_id) return people.kerberos_id

    if (people.mit_id) return people.mit_id

    return ''
  }

  getId (people: PersonModel | ResultModel) {
    if (people.kerberos_id) return people.kerberos_id

    if (people.mit_id) return people.mit_id

    return ''
  }

  checkAllPropNull (object: any) {
    let allPropsNull = true
    for (const key in object) {
      if (key !== null) allPropsNull = false
    }

    return allPropsNull
  }

  handleSearch = (query: string, element: React.KeyboardEvent<HTMLInputElement>): void => {
    clearTimeout(timerID)

    if (query.length < 1) {
      this.setState({ people: [], isBusy: false })
      return
    }

    //Check if backspace or delete
    if (element.keyCode === 8 || element.keyCode === 46) return

    this.setState({ isBusy: true })

    timerID = setTimeout(() => {
      this.homeController.abortRequest()
      let incompleteCsGoldId = query.length < 9 && query.substring(0, 3) === '333'
      let completeCsGoldId = query.length === 9 && query.substring(0, 3) === '333'

      // Check if user has correct roles
      if (this.props.canMaintainSpecialId && (incompleteCsGoldId || completeCsGoldId)) {
        if (incompleteCsGoldId) {
          this.setState({
            people: [
              {
                icon: 'user',
                text: 'Incomplete CS Gold ID',
                secondaryText: '',
                tertiaryText: 'Please enter all 9 digits',
                quaternaryText: '',
                onClick: () => void 0
              }
            ],
            isBusy: false
          })
          return
        }

        if (completeCsGoldId) {
          this.homeController.getCsGoldProfile(query).then(data => {
            // Object props will all be null if profile in csgold could not be found
            let emptyResponse = this.checkAllPropNull(data.data)

            if (emptyResponse) {
              this.setState({
                people: [],
                isBusy: false
              })
              return
            }

            this.setState({
              people: [
                {
                  icon: 'user',
                  text: data.data.first_name,
                  secondaryText: '',
                  tertiaryText: data.data.mit_id,
                  quaternaryText: '',
                  onClick: () => {
                    this.onChange(this.getId(data.data))
                  }
                }
              ],
              isBusy: false
            })
            return
          })
        }
      } else {
        if (query.length < 1) {
          this.setState({ people: [], isBusy: false })
          return
        }

        this.homeController.getSearch(query).then((people: any) => {
          //check if status not 200 (null is return)
          if (people === null) {
            this.setState({ isBusy: false })
            return
          }

          //Check if type error
          if (people && people.name === 'TypeError') {
            this.setState({ isBusy: false })
            return
          }

          //Check if request was aborted
          if (people && people.name !== 'AbortError') {
            const newData = people.map((itm: PersonModel) => {
              return {
                icon: 'user',
                text: this.getText(itm),
                secondaryText: this.getId(itm),
                tertiaryText: this.stringProvider.capitalizeFirstLetter(itm.department),
                quaternaryText: itm.mit_id,
                onClick: () => this.onChange(this.getId(itm))
              }
            })

            this.setState({
              people: newData,
              isBusy: false
            })
          }
        })
      }
    }, WAIT_INTERVAL)
  }

  generateURL (itm: string) {
    const path = window.location.pathname

    if (path === '/' || path === '/people/clearance-information') return '/people/clearance-information/' + itm
    else if (path === '/people/alarms') return '/people/alarms/' + itm
    else if (path === '/people/general') return '/people/general/' + itm
    else {
      const pathParts = window.location.pathname.split('/')

      pathParts.pop()
      pathParts.push(itm)
      return pathParts.join('/')
    }
  }

  onChange = async (user: any) => {
    this.props.onclick(true)

    const newPath = this.generateURL(user)
    History.push(newPath)
  }

  render () {
    if (this.state.isLoading) return <FilteredListLoader text='Loading Users' />

    if (this.state.isError) return <Error context={ErrorContext.Component} message='No data available for Users' type={ErrorType.NoData} />

    if (this.state.people.length > 0) {
      return (
        <>
          <div className='px-4 pt-4'>
            <AutoComplete
              name={'autocomplete'}
              searchOptions={this.emptyData}
              items={this.state.people}
              isBusy={this.state.isBusy}
              onClear={() => History.push('/people/clearance-information')}
              onSearch={(q: any, e: any) => {
                this.handleSearch(q, e)
              }}
              onChange={(e: any) => console.log(e)}
            />
            {/* <Spacer size="2" />
                        <ActionList items={this.state.people} isEmpty={this.state.isEmpty} /> */}
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className='px-4 pt-4'>
            <AutoComplete
              name={'autocomplete'}
              searchOptions={this.emptyData}
              items={this.state.people}
              isBusy={this.state.isBusy}
              onClear={() => History.push('/people/clearance-information')}
              onSearch={(q: any, e: any) => {
                this.handleSearch(q, e)
              }}
              onChange={(e: any) => this.onChange(e)}
            />
            {/* <Spacer size="2" />
                        <ActionList items={this.state.people} isEmpty={this.state.isEmpty} /> */}
          </div>
        </>
      )
    }
  }
}
